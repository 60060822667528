<div *ngIf="!camActive">
  <ul
    class="nav nav-tabs justify-content-center"
    id="settingsTab"
    role="tablist"
    style="
      border-bottom: none;
      position: relative;
      top: 15px;
      border-radius: 0px;
      background-color: transparent;
    "
  >
    <li class="nav-item">
      <a
        class="nav-link active"
        id="video-tab"
        data-bs-toggle="tab"
        href="#video"
        role="tab"
        aria-controls="video"
        aria-selected="true"
        ><i class="material-icons md-30 pb-2">settings_phone</i></a
      >
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        id="sounds-tab"
        data-bs-toggle="tab"
        href="#sounds"
        role="tab"
        aria-controls="sounds"
        aria-selected="false"
        ><i class="material-icons mt-1">notifications_active</i></a
      >
    </li>
  </ul>
  <div
    class="tab-content"
    id="settingsTab"
    style="position: relative; top: 30px"
  >
    <div
      class="tab-pane fade show active"
      id="video"
      role="tabpanel"
      aria-labelledby="video-tab"
    >
      <div>
        <div class="heading">
          <p>Video and Audio Device Settings</p>
        </div>
        <button
          (click)="toggleVA()"
          type="button"
          class="collapsed btn-outline-contact btn-lg btn-block contact-whitespace-less"
          data-bs-toggle="collapse"
          data-bs-parent="#accordion"
          data-bs-target="#collapseDeviceSetting"
          aria-expanded="false"
          data-bs-placement="top"
          [title]="tr.get('media-selector', 'vd.settingst')"
          [ngClass]="{ 'btn-primary active': showva, 'btn-default': !showva }"
        >
          Select Video and Audio devices
        </button>
        <div
          id="collapseDeviceSetting"
          class="panel-collapse collapse media-selection-collapse"
          aria-expanded="false"
          style="width: 100%"
        >
          <div
            class="modal-dialog modal-dialog-centered eqmodal"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-body ta-left">
                <div>
                  <div
                    class="row"
                    style="padding-top: 10px; margin-bottom: 0px"
                  >
                    <div class="col-sm">
                      <div class="d-flex flex-row-reverse">
                        <div
                          class="p-2 panel-collapse collapse"
                          id="collapseVideoImage"
                          aria-expanded="false"
                          style="height: 0px"
                        >
                          <video
                            autoplay
                            playsinline
                            muted
                            id="mediaSelectorVideo"
                            width="100"
                          ></video>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    style="padding-top: 10px; margin-bottom: 0px"
                  >
                    <div class="col-sm">
                      <div class="row no-gutters">
                        <div class="col-auto">
                          <button
                            type="button"
                            class="btn-send btn-outline-primary btn-lg media-selector-button"
                            data-bs-placement="top"
                          >
                            <i class="fa fa-video" style="color: #37cfdc"></i>
                          </button>
                        </div>
                        <div class="col">
                          <select
                            (change)="videoInChange($event.target.value)"
                            class="form-control input-sm media-selector-select"
                            aria-describedby="camera-addon"
                          >
                            <option
                              *ngFor="let vin of videoInputs"
                              [value]="vin.id"
                            >
                              {{ vin.text }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    style="padding-top: 10px; margin-bottom: 0px"
                  >
                    <div class="col-sm">
                      <div class="row no-gutters">
                        <div class="col-auto">
                          <button
                            type="button"
                            class="btn-send btn-outline-primary btn-lg media-selector-button"
                            data-bs-placement="top"
                            [title]="tr.get('media-selector', 'audio.ms.title')"
                          >
                            <i
                              class="fa fa-microphone"
                              style="color: #37cfdc; width: 18px"
                            ></i>
                          </button>
                        </div>
                        <div class="col">
                          <select
                            (change)="audioInChange($event.target.value)"
                            class="form-control input-sm"
                            aria-describedby="ain-addon"
                          >
                            <option
                              *ngFor="let ain of audioInputs"
                              [value]="ain.id"
                            >
                              {{ ain.text }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    style="padding-top: 10px; margin-bottom: 0px"
                  >
                    <div class="col-sm">
                      <div class="row no-gutters">
                        <div class="col-auto">
                          <button
                            type="button"
                            class="btn-send btn-outline-primary btn-lg media-selector-button"
                            data-bs-placement="top"
                            [title]="tr.get('media-selector', 'audio.so.title')"
                          >
                            <i
                              class="fa fa-volume-up"
                              style="color: #37cfdc"
                            ></i>
                          </button>
                        </div>
                        <div class="col">
                          <select
                            (change)="audioOutChange($event.target.value)"
                            class="form-control input-sm"
                            aria-describedby="aout-addon"
                          >
                            <option
                              *ngFor="let aout of audioOutputs"
                              [value]="aout.id"
                            >
                              {{ aout.text }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row no-gutters"
                    style="padding-top: 10px; margin-bottom: 0px"
                  >
                    <div class="col-sm">
                      <div class="d-flex flex-row">
                        <div class="p-2" style="margin-left: -10px">
                          <button
                            class="btn-warning btn-primary btn-lg btn-send btn-outline-primary"
                            data-bs-toggle="collapse"
                            data-bs-parent="#accordion"
                            data-bs-target="#collapseVideoImage"
                            aria-expanded="false"
                            data-bs-placement="top"
                            [title]="tr.get('media-selector', 'vd.startt')"
                            (click)="toggleVideo()"
                            style="border-color: #37cfdc"
                          >
                            {{ buttonText }}
                          </button>
                        </div>
                        <div class="p-2">
                          <button
                            class="btn-warning btn-primary btn-lg btn-send btn-outline-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#tdModal"
                            id="td"
                            data-bs-placement="top"
                            style="border-color: #37cfdc"
                          >
                            Test
                          </button>
                        </div>
                        <div
                          class="d-flex justify-content-end"
                          style="position: absolute; right: -7px; top: 20px"
                        >
                          <div class="p-2 pl-1">
                            <a
                              href=""
                              data-bs-toggle="collapse"
                              data-bs-parent="#accordion"
                              data-bs-target="#collapseAdvancedSetting"
                              aria-expanded="false"
                              data-bs-placement="top"
                              >Advanced</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="collapseAdvancedSetting"
                    class="panel-collapse collapse media-selection-collapse"
                    aria-expanded="false"
                  >
                    <hr />
                    <p>
                      Allow Microphone to Broadcast Raw Uncontsrained WebRTC
                      Audio?
                    </p>
                    <input
                      type="checkbox"
                      [(ngModel)]="useHQAudio"
                      (ngModelChange)="useHQAudioSettings()"
                      class="checkbox-template mr-2"
                      data-bs-placement="top"
                      [title]="tr.get('media-selector', 'vd.pureHD.title')"
                    />
                    {{ tr.get('media-selector', 'vd.pureHD') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          (click)="toggleCM()"
          type="button"
          class="collapsed btn-outline-contact btn-lg btn-block contact-whitespace-less"
          data-bs-toggle="collapse"
          data-bs-parent="#accordion"
          data-bs-target="#collapseBroadcastSetting"
          aria-expanded="false"
          data-bs-placement="top"
          [title]="tr.get('media-selector', 'vd.settingst')"
          [ngClass]="{ 'btn-primary active': showcm, 'btn-default': !showcm }"
        >
          Camera Options
        </button>
        <div
          id="collapseBroadcastSetting"
          class="panel-collapse collapse media-selection-collapse"
          aria-expanded="false"
          style="width: 100%"
        >
          <div
            class="modal-dialog modal-dialog-centered eqmodal"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-body ta-left">
                <div class="row" style="padding-top: 10px; margin-bottom: 0px">
                  <div class="col-sm">
                    <p>Broadcast Resolution</p>
                    <select
                      (change)="videoRes($event.target.value)"
                      class="form-control input-sm media-selector-select"
                      aria-describedby="camera-addon"
                    >
                      <option
                        *ngFor="let res of resolutions"
                        [value]="res.idx"
                        [selected]="res.height == height"
                      >
                        {{ res.desc }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm">
                    <hr />
                    <p>Allow Camera's Broadcast to be cropped on the fly?</p>
                    <input
                      type="checkbox"
                      [(ngModel)]="useCanvas"
                      (ngModelChange)="useCanvasSettings()"
                      class="checkbox-template mr-2"
                      data-bs-placement="top"
                      [title]="tr.get('media-selector', 'vd.crop.title')"
                    />
                    {{ tr.get('media-selector', 'vd.crop') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        (click)="togglePasM()"
        type="button"
        class="collapsed btn-outline-contact btn-lg btn-block contact-whitespace-less"
        data-bs-toggle="collapse"
        data-bs-parent="#accordion"
        data-bs-target="#collapsePhoneSetting"
        aria-expanded="false"
        data-bs-placement="top"
        [title]="tr.get('media-selector', 'vd.settingst')"
        [ngClass]="{ 'btn-primary active': showpasm, 'btn-default': !showpasm }"
      >
        Connect a Phone as Mic | Speaker
      </button>
      <div
        id="collapsePhoneSetting"
        class="panel-collapse collapse media-selection-collapse"
        aria-expanded="false"
        style="width: 100%"
      >
        <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
          <div class="modal-content">
            <div class="modal-body ta-left">
              <div>
                <div class="row" style="padding-top: 10px; margin-bottom: 0px">
                  <div class="col-sm">
                    <div class="row">
                      <div class="col-sm" style="padding-bottom: 10px">
                        <button
                          type="button"
                          class="collapsed btn-lg btn-primary btn-block"
                          data-bs-toggle="collapse"
                          data-bs-parent="#accordion"
                          data-bs-target="#CallMe"
                          aria-expanded="false"
                          data-bs-placement="top"
                          [title]="tr.get('media-selector', 'call.pt')"
                        >
                          {{ tr.get('media-selector', 'call.p') }}
                        </button>
                        <div
                          id="CallMe"
                          class="panel-collapse collapse"
                          aria-expanded="false"
                          style="padding-top: 10px"
                        >
                          <app-dialer [dialerStyle]="'simple'"></app-dialer>
                          <hr />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm">
                        <button
                          type="button"
                          class="collapsed btn-lg btn-primary btn-block"
                          data-bs-toggle="collapse"
                          data-bs-parent="#accordion"
                          data-bs-target="#DialIn"
                          aria-expanded="false"
                          data-bs-placement="top"
                          [title]="tr.get('media-selector', 'show.dt')"
                          style="background: transparent; color: #fff"
                        >
                          {{ tr.get('media-selector', 'show.d') }}
                        </button>
                        <div
                          id="DialIn"
                          class="panel-collapse collapse"
                          aria-expanded="false"
                          style="padding-top: 10px"
                        >
                          <div
                            class="block"
                            style="
                              overflow-y: scroll;
                              max-height: 62vh;
                              border: 1px solid #7f8c8d;
                            "
                          >
                            <div class="table-responsive">
                              <table class="table table-striped table-hover">
                                <thead
                                  style="
                                    background-color: #2c3e50;
                                    color: #bdc3c7;
                                  "
                                >
                                  <tr>
                                    <th>
                                      {{ tr.get('media-selector', 'avail.n') }}
                                    </th>
                                    <th>
                                      {{ tr.get('media-selector', 'loc') }}
                                    </th>
                                    <th>
                                      {{ tr.get('media-selector', 'pin') }}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody style="text-align: left">
                                  <tr>
                                    <td>+61 (0)411 903 456</td>
                                    <td>Australia, Sydney</td>
                                    <td>1234</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      id="sounds"
      role="tabpanel"
      aria-labelledby="sounds-tab"
    >
      <div class="heading">
        <p>Notification and Sound Settings</p>
      </div>
      <button
        (click)="toggleRT()"
        type="button"
        class="collapsed btn-outline-contact btn-lg btn-block contact-whitespace-less"
        data-bs-toggle="collapse"
        data-bs-parent="#accordion"
        data-bs-target="#collapseRingtoneSetting"
        aria-expanded="false"
        data-bs-placement="top"
        [title]="tr.get('media-selector', 'vd.settingst')"
        [ngClass]="{ 'btn-primary active': showrt, 'btn-default': !showrt }"
      >
        Select Ringing Tone
      </button>
      <div
        id="collapseRingtoneSetting"
        class="panel-collapse collapse media-selection-collapse"
        aria-expanded="false"
        style="width: 100%"
      >
        <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
          <div class="modal-content">
            <div class="modal-body ta-left">
              <div>
                <div class="row" style="padding-top: 10px; margin-bottom: 0px">
                  <div class="col-sm">
                    <div class="row no-gutters">
                      <div class="col-auto">
                        <button
                          type="button"
                          class="btn-send btn-outline-primary btn-lg media-selector-button"
                          data-bs-placement="top"
                          [title]="tr.get('media-selector', 'ringtone')"
                        >
                          <i
                            class="fa fa-volume-control-phone"
                            style="color: #37cfdc"
                          ></i>
                        </button>
                      </div>
                      <div class="col">
                        <select
                          (change)="changeRingTone($event.target.value)"
                          class="form-control input-sm media-selector-select"
                        >
                          <option
                            *ngFor="let rt of ringTones"
                            [value]="rt.index"
                            [selected]="rt.index === ringTone.index"
                          >
                            {{ rt.desc }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        (click)="toggleVAI()"
        type="button"
        class="collapsed btn-outline-contact btn-lg btn-block contact-whitespace-less"
        data-bs-toggle="collapse"
        data-bs-parent="#accordion"
        data-bs-target="#collapseVoiceSetting"
        aria-expanded="false"
        data-bs-placement="top"
        [title]="tr.get('media-selector', 'vd.settingst')"
        [ngClass]="{ 'btn-primary active': showvai, 'btn-default': !showvai }"
      >
        Select Voice AI
      </button>
      <div
        id="collapseVoiceSetting"
        class="panel-collapse collapse media-selection-collapse"
        aria-expanded="false"
        style="width: 100%"
      >
        <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
          <div class="modal-content">
            <div class="modal-body ta-left">
              <div>
                <div class="row" style="padding-top: 10px; margin-bottom: 0px">
                  <div class="col-sm">
                    <div class="row no-gutters">
                      <div class="col-auto">
                        <button
                          type="button"
                          class="btn-send btn-outline-primary btn-lg media-selector-button"
                          data-bs-placement="top"
                          [title]="tr.get('media-selector', 'audio.sv.title')"
                        >
                          <i class="fa fa-comments" style="color: #37cfdc"></i>
                        </button>
                      </div>
                      <div class="col">
                        <select
                          (change)="setVoice($event.target.value)"
                          class="form-control input-sm media-selector-select"
                          aria-describedby="voice-addon"
                        >
                          <option
                            *ngFor="let voice of voices"
                            [value]="voice.voiceURI"
                            [selected]="
                              voice.voiceURI === selectedVoice.voiceURI
                            "
                          >
                            {{ voice.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        (click)="toggleMSN()"
        type="button"
        class="collapsed btn-outline-contact btn-lg btn-block contact-whitespace-less"
        data-bs-toggle="collapse"
        data-bs-parent="#accordion"
        data-bs-target="#collapseNotificationSetting"
        aria-expanded="false"
        data-bs-placement="top"
        [title]="tr.get('media-selector', 'vd.settingst')"
        [ngClass]="{ 'btn-primary active': showmsn, 'btn-default': !showmsn }"
      >
        Mute Sound Notifications
      </button>
      <div
        id="collapseNotificationSetting"
        class="panel-collapse collapse media-selection-collapse"
        aria-expanded="false"
        style="width: 100%"
      >
        <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
          <div class="modal-content">
            <div class="modal-body ta-left">
              <p class="mt-1 ta-center">Audio Notifications</p>
              <hr />
              <div class="row">
                <div class="col-auto">
                  <input
                    type="checkbox"
                    [(ngModel)]="mtpriv"
                    (ngModelChange)="mutePrivate()"
                    class="checkbox-template"
                    data-bs-placement="top"
                    [title]="tr.get('media-selector', 'mute.chatt')"
                  />
                </div>
                <div class="col">
                  {{ tr.get('media-selector', 'mute.chat') }}
                </div>
              </div>
              <div class="row">
                <div class="col-auto">
                  <input
                    type="checkbox"
                    [(ngModel)]="mtgrp"
                    (ngModelChange)="muteGroup()"
                    class="checkbox-template"
                    data-bs-placement="top"
                    [title]="tr.get('media-selector', 'mute.gchatt')"
                  />
                </div>
                <div class="col">
                  {{ tr.get('media-selector', 'mute.gchat') }}
                </div>
              </div>
              <div class="row">
                <div class="col-auto">
                  <input
                    type="checkbox"
                    [(ngModel)]="mtspeech"
                    (ngModelChange)="muteSpeech()"
                    class="checkbox-template"
                    data-bs-placement="top"
                    [title]="tr.get('media-selector', 'mute.ttst')"
                  />
                </div>
                <div class="col">
                  {{ tr.get('media-selector', 'mute.tts') }}
                </div>
              </div>
              <hr />
              <p class="mt-1 ta-center">Turn off All Sounds When in Calls</p>
              <hr />
              <button
                type="button"
                class="collapsed btn btn-danger btn-lg btn-block mt-2"
                data-bs-toggle="modal"
                data-bs-target="#dndModal"
                id="logout"
                data-bs-placement="top"
                [title]="tr.get('account-profile', 'status-dndt')"
              >
                Do Not Disturb
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="camActive">
  <div class="row" style="padding-top: 10px; margin-bottom: 0px">
    <div class="col-sm">
      <p>Selected Camera</p>
      <div class="row no-gutters">
        <div class="col-auto">
          <button
            type="button"
            class="btn-send btn-outline-primary btn-lg media-selector-button"
            data-bs-placement="top"
          >
            <i class="fa fa-video" style="color: #37cfdc"></i>
          </button>
        </div>
        <div class="col">
          <select
            (change)="videoInChange($event.target.value)"
            class="form-control input-sm media-selector-select"
            aria-describedby="camera-addon"
          >
            <option *ngFor="let vin of videoInputs" [value]="vin.id">
              {{ vin.text }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="padding-top: 10px; margin-bottom: 0px">
    <div class="col-sm">
      <p>Broadcast Resolution</p>
      <select
        (change)="videoRes($event.target.value)"
        class="form-control input-sm media-selector-select"
        aria-describedby="camera-addon"
      >
        <option
          *ngFor="let res of resolutions"
          [value]="res.idx"
          [selected]="res.height == height"
        >
          {{ res.desc }}
        </option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-sm">
      <hr />
      <p>Allow Camera's Broadcast to be cropped on the fly?</p>
      <input
        type="checkbox"
        [(ngModel)]="useCanvas"
        (ngModelChange)="useCanvasSettings()"
        class="checkbox-template mr-2"
        data-bs-placement="top"
        [title]="tr.get('media-selector', 'vd.crop.title')"
      />
      {{ tr.get('media-selector', 'vd.crop') }}
    </div>
  </div>
</div>
