import {
  AudioAnalyser,
  AudioAnalyserCtlr,
  AudioAnalyserImpl,
  CropSettings,
  EqualizerValues,
  NoAudioAnalyserImpl,
  StreamChangeEventListener,
  StreamhandlerService,
  Visualizer,
  VisualizerImpl,
  VuMeter
} from './streamhandler.service';
import { ConfigService } from './config.service';
import {
  AudioPresets,
  connect,
  ConnectionQuality,
  DataPacket_Kind,
  LocalParticipant,
  LocalTrackPublication,
  Participant,
  ParticipantEvent,
  RemoteParticipant,
  RemoteTrack,
  RemoteTrackPublication,
  Room,
  RoomEvent,
  RoomState,
  setLogLevel,
  Track,
  TrackEvent,
  TrackPublication,
  VideoPreset,
  VideoPresets
} from 'livekit-client';
import { Contact, ContactsService } from './contacts.service';
import { EqcallapiService } from './eqcallapi.service';
import { Injectable, NgZone } from '@angular/core';
import { MessageObserver, SystemBusService } from './system-bus.service';
import {
  NetworkService,
  Socket,
  SocketListener,
  SocketListenerCallback
} from './network.service';
import { RtcChatMessage, RtcConnection, RtcListener } from './rtc.service';
import { RtcproxyService } from './rtcproxy.service';
import { VideoQuality } from 'livekit-client/dist/proto/livekit_models';

export class RtcSocketListenerCallback implements SocketListenerCallback {
  constructor(
    private service: LivekitService,
    private contactSvc: ContactsService
  ) {}

  /**
   * Called when we are being called. To accept, something has to pass this socket to connectSocket
   */
  socketListenCallback(socket: Socket) {
    let autoConnect = false;
    let header: any;
    try {
      header = JSON.parse(socket.getHeader());
      autoConnect = header.autoConnect;
    } catch (err) {
      console.error(
        'RtcService: socketListerCallback: header parse error ',
        err
      );
      console.error(
        'RtcService: socketListerCallback:  header parse error ',
        socket
      );
      return;
    }

    console.log('Recieved RTC connection: AutoConnect ' + autoConnect);
    if (autoConnect) {
      let contact = this.contactSvc.getContactByAddress(
        socket.getDestination()
      );
      // TODO should have a secret that we check before connecting
      console.log(' TODO should have a secret that we check before connecting');
      let videoEnabled = !this.service.streamHandler.masterVideoMute;
      this.service.connectSocket(socket, contact, false, videoEnabled);
    } else {
      this.service.systemBus.emit(socket, 'rtc/connection/request');
    }
  }
}
export class RtcLivekitConnectionImpl
  implements RtcConnection, SocketListener, AudioAnalyserCtlr, RtcListener
{
  localAudioMuted: boolean;
  localVideoMuted: boolean;
  audioContext: AudioContext;
  onHold: boolean;
  destinationID: string;
  disposed = false;
  public remoteNickName = '';
  public bandwidth = 2097152;
  private analyser: AudioAnalyser;
  private participant: RemoteParticipant;
  private listeners: RtcListener[] = [];
  private remoteControlAnswerFunction: any;
  private listDevicesAnswerFunction: any;
  public isRemoteControlled = false;
  public areRemoteControlling = false;
  private closed: boolean;
  private visualizer: VisualizerImpl;
  private isRemoteVideoCropping: CropSettings;
  public outputStream: MediaStream = new MediaStream();
  private connectionTimeoutTimer: NodeJS.Timeout;
  isHoldByRemote: boolean;
  isBeingCalled = false;
  vu: VuMeter;
  constructor(
    public service: LivekitService,
    private socket: Socket,
    private contact: Contact
  ) {
    this.audioContext = service.audioContext;
    this.onHold = service.getMasterHold();
    if (socket) {
      socket.addListener(this);
      this.destinationID = socket.getDestination();
    }
    if (contact) {
      this.remoteNickName = contact.nickname;
      if (!this.getDestinationID) {
        this.destinationID = contact.destAddress;
      }
    }
    console.warn('RtcLivekitConnectionImpl constructor');
    this.connectionTimeoutTimer = setTimeout(() => {
      console.error('TimeOut ');
      this.close();
    }, 90000); // one and a half minutes
  }

  rtcMessage(message: string, _connection: RtcConnection): void {
    this.listeners.forEach((listener) => {
      listener.rtcMessage(message, this);
    });
  }

  rtcConnectionRequest(address: string, connection: RtcConnection): void {
    console.error('Method not implemented.');
  }

  rtcClosed(connection: RtcConnection): void {
    console.error('Method not implemented.');
  }

  rtcStateChange(state: string): void {
    this.listeners.forEach((listener) => {
      try {
        listener.rtcStateChange(state);
      } catch (error) {
        console.error(error);
      }
    });
  }
  rtcSoundLevelChange(level: number): void {
    console.error('Method not implemented.');
  }
  rtcTalking(talking: boolean): void {
    console.error('Method not implemented.');
  }
  socketOnRecieve(data: string, socket: Socket): void {
    console.error('Method not implemented.');
  }
  socketRemotelyClosed(): void {
    this.close();
  }
  socketClosed(): void {
    this.close();
  }

  public setSoundLevel(level: number) {
    if (this.listeners) {
      this.listeners.forEach((listener) => {
        listener.rtcSoundLevelChange(level);
      });
    }
  }

  setParticipant(participant: RemoteParticipant) {
    console.log('LiveKit setParticipant', participant);
    if (this.participant) {
      console.error('Participant already set');
      return;
    }
    this.participant = participant;
    if (!this.destinationID) {
      this.destinationID = participant.identity;
    }
    participant
      .on(
        ParticipantEvent.TrackSubscribed,
        (track: RemoteTrack, pub: RemoteTrackPublication) => {
          console.log(
            'subscribed to track',
            pub.trackSid,
            participant.identity
          );
          this.handleTrackSubscribed(track, pub, participant);
          // this.renderScreenShare();
        }
      )
      .on(
        ParticipantEvent.TrackUnsubscribed,
        (track: RemoteTrack, pub: RemoteTrackPublication) => {
          console.log('unsubscribed from track', pub.trackSid);
          this.handleTrackUnsubscribed(track, pub, participant);
          // this.renderScreenShare();
        }
      )
      .on(ParticipantEvent.TrackMuted, (pub: TrackPublication) => {
        console.log('track was muted', pub.trackSid, participant.identity);
        if (this.isHoldByRemote || this.onHold || this.disposed) {
          return;
        }
        this.notifyListeners('track:' + pub.kind + ':ended');
        if (pub.kind === 'audio') {
          this.vu.setIncall(false);
        } else {
          //  this.outputStream.removeTrack(track);
        }
      })
      .on(ParticipantEvent.TrackUnmuted, (pub: TrackPublication) => {
        console.log('track was unmuted', pub.trackSid, participant.identity);

        if (this.isHoldByRemote || this.onHold || this.disposed) {
          return;
        }
        if (pub.kind === 'audio') {
          this.vu.setIncall(true);
          this.service.systemBus.emit(
            this.service.streamHandler.getDeviceIDs().audioOutDeviceID,
            'rtc/audioDevice/Out/changed'
          );
        } else {
          // this.outputStream.addTrack(pub.);
        }
        this.notifyListeners('track:' + pub.kind + ':added');
      })
      .on(ParticipantEvent.IsSpeakingChanged, () => {
        console.log('Speakingchanged ' + this.remoteNickName);
      })
      .on(ParticipantEvent.ConnectionQualityChanged, () => {
        console.error('Quality changed ' + this.remoteNickName);
      });
    try {
      clearTimeout(this.connectionTimeoutTimer);
    } catch (error) {
      console.error('Rtc: error  ' + error);
    }
    this.rtcStateChange('connected');
    participant.getTracks().forEach((pub: RemoteTrackPublication) => {
      console.log('trackPublication', pub);
      this.handleTrackSubscribed(<RemoteTrack>pub.track, pub, participant);
    });

    if (this.remoteNickName.length === 0) {
      this.remoteNickName = participant.name;
    }
  }

  private handleTrackSubscribed(
    track: RemoteTrack,
    publication: RemoteTrackPublication,
    participant: RemoteParticipant
  ) {
    console.log('LiveKit track Subscribed ', track);
    if (track.kind === Track.Kind.Video || track.kind === Track.Kind.Audio) {
      publication.setVideoQuality(VideoQuality.HIGH);
      this.outputStream.addTrack(track.mediaStreamTrack);
      let out: AudioNode;
      if (track.kind === 'audio') {
        let clone = [];
        let trackclone = track.mediaStreamTrack;
        clone.push(trackclone);
        let stream = new MediaStream(clone);
        let node = this.audioContext.createMediaStreamSource(stream);
        console.log('node', node);
        this.vu = new VuMeter(this.audioContext);
        this.vu.addSoundLevelListener(this);
        out = this.vu.getOutputAudioNode(node);
        this.service.streamHandler.addToSpeaker(out);
        this.vu.setIncall(true);
        track.on(
          TrackEvent.Ended,
          (track: { mediaStreamTrack: MediaStreamTrack; kind: string }) => {
            console.log('Track ended', track);
            this.outputStream.removeTrack(track.mediaStreamTrack);
            this.rtcStateChange('track:' + track.kind + ':ended');

            try {
              this.service.streamHandler.removeFromSpeaker(out);
              out.disconnect();
              this.vu.destroy();
              out = null;
              this.vu = null;
            } catch (err) {
              console.error(err);
            }
          }
        );
      }
    }

    // if (this.iOS) {
    this.notifyListeners('track:' + track.kind + ':added');
    //  }
  }

  private handleTrackUnsubscribed(
    track: RemoteTrack,
    publication: RemoteTrackPublication,
    participant: RemoteParticipant
  ) {
    console.log('LiveKit track UnSunbcribed ', track);
    // if (track.kind === Track.Kind.Video || track.kind === Track.Kind.Audio) {
    //   this.outputStream.removeTrack(track.mediaStreamTrack);
    //   let out: AudioNode;
    //   let vu: VuMeter;
    //   this.notifyListeners('track:' + track.kind + ':ended');
    //   if (track.kind === 'audio') {
    //     try {
    //       this.service.streamHandler.removeFromSpeaker(out);
    //       out.disconnect();
    //       vu.destroy();
    //       out = null;
    //       vu = null;
    //     } catch (err) {
    //       console.error(err);
    //     }
    //   }

    if (this.outputStream) {
      this.outputStream.removeTrack(track.mediaStreamTrack);
      track.detach();
    }
  }

  private async notifyListeners(state: string) {
    if (this.listeners) {
      this.listeners.forEach((listener) => {
        listener.rtcStateChange(state);
      });
    }
  }

  getIsRemoteVideoCropping(): CropSettings {
    console.error('Method not implemented.');
    return null;
  }
  public setRemoteVideoCrop(cropSettings: CropSettings) {
    console.log('Set cropping ', cropSettings);
    const data = {
      type: 'levels',
      id: this.socket.connectionID,
      crop: cropSettings
    };
    this.sendRawMessage(this.encoder.encode(JSON.stringify(data)), 'messages');
    this.isRemoteVideoCropping = cropSettings;
  }
  startRemoteVisualizer(start: boolean): void {
    console.error('Method not implemented.');
  }
  getAnalyser(): AudioAnalyser {
    return this.analyser;
  }
  getVisualizer(): Visualizer {
    return this.visualizer;
  }
  getOutputStream(): MediaStream {
    return this.outputStream;
  }
  acceptConnection(): void {
    try {
      clearTimeout(this.connectionTimeoutTimer);
    } catch (error) {
      console.error(error);
    }
    this.socket.accept();
    if (this.socket && this.isBeingCalled) {
      this.socket.send(
        JSON.stringify({
          mode: 'livekit',
          type: 'accept'
        })
      );
    } else {
      console.error('Rtc: Do not call connect when isitiating a call');
    }
  }
  setBandwidth(bw: number): void {
    console.log('RTC SetBandwidth no implemented');
  }
  muteRemoteAudio(mute: boolean): void {
    console.error('Method not implemented.');
    this.participant.videoTracks.forEach(
      (pub: { kind: any; setEnabled: (arg0: boolean) => void }) => {
        if (pub.kind === Track.Kind.Audio) {
          pub.setEnabled(mute);
        }
      }
    );
  }
  muteRemoteVideo(mute: boolean): void {
    console.error('Method not implemented.');
    this.participant.videoTracks.forEach(
      (pub: { kind: any; setEnabled: (arg0: boolean) => void }) => {
        if (pub.kind === Track.Kind.Video) {
          pub.setEnabled(mute);
        }
      }
    );
  }
  masterMuteLocalVideo(mute: boolean): void {
    console.error('Method not implemented.');
  }
  masterMuteLocalAudio(arg0: boolean): void {
    console.error('Method not implemented.');
  }

  private encoder = new TextEncoder();
  private decoder = new TextDecoder();

  sendChatMessage(message: string): void {
    const data = this.encoder.encode(
      JSON.stringify({
        type: 'message',
        id: this.socket.connectionID,
        message: message
      })
    );

    this.sendRawMessage(data, 'messages');
  }

  receivedDataMessage(packet: any, messageType: string) {
    try {
      if (messageType === 'messages') {
        // console.log('Rtc: received data packet : ' + messageType + ' ' + packet['data']);
        const data = JSON.parse(packet);
        const dataType = data.type;
        if (dataType === 'message') {
          //          console.log('Rtc: message ' + data.message);
          this.listeners.forEach((listener) => {
            listener.rtcMessage(data.message, this);
          });
          this.service.newRtcChatRecieved(data.message, this);
        } else if (dataType === 'levels') {
          if (this.isRemoteControlled) {
            this.service.remoteCtlDataMessage(packet, messageType);
          }

          if (data.hasOwnProperty('volume')) {
            // this.setLocalVolume(data.volume.level);
            console.log('Not Implemented');
          }
          if (data.hasOwnProperty('eq')) {
            console.log('Not Implemented');
            if (data.eq.hasOwnProperty('values')) {
              // this.EQValuesReceived(
              //   new EqualizerValues(null).setValues(data.eq.values)
              // );
            } else {
              // this.setLocalEq(data.eq.type, data.eq.level);
            }
          }
          if (data.hasOwnProperty('ng')) {
            console.log('Not Implemented');
            // this.setLocalNoiseGate(data.ng.level);
          }
          if (data.hasOwnProperty('audioMute')) {
            console.log('Not Implemented');
            // this.muteAudioByRemote(data.audioMute);
          }
          if (data.hasOwnProperty('videoMute')) {
            console.log('Not Implemented');
            // this.muteVideoByRemote(data.videoMute);
          }
          if (data.hasOwnProperty('hold')) {
            // this.localHoldByRemote(data.hold);
            console.error('Not implementd');
          }

          if (data.hasOwnProperty('talking')) {
            console.log('Not Implemented, talking');
            // this.remoteIsTalking(data.talking);
          }

          if (data.hasOwnProperty('videoVisualize')) {
            // this.sendVisualizerData(data.videoVisualize);
            console.log('Not Implemented');
          }
          if (data.hasOwnProperty('crop')) {
            // this.setLocalVideoCrop(data.crop);
            console.log('Not Implemented');
          }
        } else if (dataType === 'peerNotification') {
          console.log('Not Implemented peernotification');
          // const destAddress = data.destAddress;
          // // const srcAddress = data.srcAddress;
          // const nickname = data.nickname;
          // this.receivedPeerNotofication(destAddress, nickname);
        } else if (dataType === 'nickname') {
          this.remoteNickName = data.nickname;
        } else if (dataType === 'remoteControlRequest') {
          if (!this.isRemoteControlled) {
            // this.service.remoteControlRequested(this, data.message);
            this.service.systemBus.emit(
              { message: data.message, connection: this },
              'rtc/remoteControl/request'
            );
          } else {
            this.remoteControlRequestSendAnswer(true);
          }
        } else if (dataType === 'remoteControlResponse') {
          this.areRemoteControlling = data.message;

          this.remoteControlAnswerFunction(data.message);
        } else if (dataType === 'listDevicesRequest') {
          this.remoteListDevicesRequest(data.message);
        } else if (dataType === 'listDevicesResponse') {
          this.listDevicesAnswerFunction(data.message);
        } else if (dataType === 'setDeviceSettings') {
          this.processDeviceSettingsRequest(data.message);
        } else if (dataType === 'anlz') {
          if (this.visualizer) {
            const d = Uint8Array.from(data.data);
            this.visualizer.visualize(d);
          }
        } else if (dataType === 'videoMute') {
          let muted = <boolean>data.value;
          console.log('Video mute ' + muted);
          this.notifyListeners('track:video:' + (muted ? 'ended' : 'added'));
        } else if (dataType === 'audioMute') {
          let muted = <boolean>data.value;
          console.log('Audio mute ' + muted);
          this.notifyListeners('track:audio:' + (muted ? 'ended' : 'added'));
        } else {
          console.error('Invalid message type ', packet);
        }
      } else if (messageType === 'socket') {
        const data = JSON.parse(packet['data']);
        //this.service.rtcSocketMessage(data);
        console.error('Not Implemented', data);
      } else {
        console.error('Rtc: DataMessage: Unknown origin ' + messageType);
      }
    } catch (err) {
      console.error('RtcConnect: receivedDataMessage: error ', err);
    }
  }

  requestRemoteControl(message: string): Promise<any> {
    console.error('Method not implemented.');
    return null;
  }
  close(): void {
    if (this.closed) {
      return;
    }
    this.closed = true;
    this.service.systemBus.emit(this.destinationID, 'rtc/connection/closed');
    try {
      clearTimeout(this.connectionTimeoutTimer);
    } catch (error) {
      console.error('Rtc: error disposing ' + error);
    }

    try {
      this.socket.close();
      this.socket = null;

      if (this.analyser) {
        this.analyser.dispose();
        this.analyser = null;
      }
      this.visualizer = null;
      this.rtcStateChange('closed');
    } finally {
      try {
        this.service.rtcConnectionClosed(this);
      } catch (error) {
        console.error('Rtc: error disposing ' + error);
      }
      try {
        this.listeners.forEach((listener) => {
          listener.rtcClosed(this);
        });
      } catch (error) {
        console.error('Rtc: error disposing ' + error);
      }
    }
  }

  private remoteListDevicesRequest(_message: string) {
    if (this.isRemoteControlled) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((deviceInfos: Array<MediaDeviceInfo>) => {
          let ids = this.service.streamHandler.getDeviceIDs();
          ids.devicesInfos = deviceInfos;
          this.listDevicesSendAnswer(ids);
        })
        .catch((error) => {
          console.error(error);
          this.listDevicesSendAnswer({ error: error });
        });
    } else {
      console.warn('Remote control missing');
      this.listDevicesSendAnswer({ error: 'Remote control missing' });
    }
  }

  private listDevicesSendAnswer(deviceInfo: any) {
    const data = {
      type: 'listDevicesResponse',
      message: deviceInfo,
      id: this.socket.connectionID
    };
    this.sendRawMessage(this.encoder.encode(JSON.stringify(data)), 'messages');
  }

  private processDeviceSettingsRequest(message: any) {
    if (this.isRemoteControlled) {
      this.service.streamHandler.setDeviceIDs(message);
    } else {
      console.error('Not under Remote control');
    }
  }

  private dispose() {
    if (!this.disposed) {
      this.disposed = true;
      this.analyser = null;
      this.visualizer = null;
      this.listeners.length = 0;
      this.listeners = null;
    }
  }

  public hold(hold: boolean) {
    console.log('Not Implemented');
  }

  private localHold(hold: boolean) {
    this.analyser.localHold(hold);
    // if (this.inputStream && this.inputStream.getVideoTracks().length > 0) {
    //   console.log(this.inputStream);
    //   this.inputStream.getVideoTracks()[0].enabled = !hold;
    // }

    console.error('Not Implemented');
  }

  streamHandlerOnStreamChangeEvent(stream: MediaStream): void {
    console.error('Method not implemented.');
  }
  sendTalking(talking: boolean): void {
    console.error('Method not implemented.');
  }
  remoteCtlDataMessage(packet: any, messageType: string): void {
    console.error('Method not implemented.');
  }
  sendPeerNotification(destinationID: string, remoteNickName: string): void {
    console.error('Method not implemented.');
  }
  remoteControlRequestSendAnswer(answer: boolean): void {
    console.error('Method not implemented.');
  }
  // used by the AudioAnalyser
  sendRawMessage(message: Uint8Array, origin: string) {
    // const channel = this.channels.get(origin);
    // if (channel) {
    //     try {
    //         channel.send(message);
    //     } catch (error) {
    //         let array = this.dataMessageQueue.get(origin);
    //         if (!array) {
    //             array = new Array();
    //             array.push(message);
    //             this.dataMessageQueue.set(origin, array);
    //         } else {
    //             array.push(message);
    //         }
    //     }
    // } else {
    //     console.log('Rtc: sendRawMessage: Queuing message: No Channel');
    //     let array = this.dataMessageQueue.get(origin);
    //     if (!array) {
    //         array = new Array();
    //         array.push(message);
    //         this.dataMessageQueue.set(origin, array);
    //     } else {
    //         array.push(message);
    //     }
    // }
    this.service.sendRTCMessage(message, origin, this.participant);
  }
  sendEQValues(arg0: EqualizerValues): void {
    console.error('Method not implemented.');
    //send datachannel
  }
  listDevices(): Promise<unknown> {
    console.error('Method not implemented.');
    return null;
  }
  public removeListener(listener: RtcListener) {
    if (this.disposed) {
      return;
    }
    this.listeners.splice(this.listeners.indexOf(listener), 1);
  }

  public addListener(listener: RtcListener) {
    if (this.disposed) {
      return;
    }
    this.listeners.push(listener);
  }
  setRemoteNoiseGate(val: number): void {
    console.error('Method not implemented.');
  }
  public setRemoteEq(type: string, level: number) {
    if (level > 40 || level < -40) {
      console.error('Invalid Remote EQ setting ' + type + ':' + level);
      return;
    }
    const data = {
      type: 'levels',
      id: this.socket.connectionID,
      eq: {
        level: level,
        type: type
      }
    };
    this.sendRawMessage(this.encoder.encode(JSON.stringify(data)), 'messages');
  }
  setRemoteVolume(volume: number): void {
    console.error('Method not implemented.');
  }
  async getStats(): Promise<any> {
    //console.error('Method not implemented.');
    return null;
  }
  setRemoteDeviceSettings(ret: any): void {
    console.error('Method not implemented.');
  }
  getAreRemoteControlling(): boolean {
    console.error('Method not implemented.');
    return false;
  }
  getIsTalking(): boolean {
    console.error('Method not implemented.');
    return false;
  }
  getRemoteNickName(): string {
    return this.remoteNickName;
  }
  getIsRemoteControlled(): boolean {
    console.error('Method not implemented.');
    return false;
  }
  getState(): string {
    console.error('Method not implemented.');
    return null;
  }
  getIsBeingCalled(): boolean {
    return this.isBeingCalled;
  }
  getDestinationID(): string {
    return this.destinationID;
  }
  setIsRemoteControlledAnswer(answer: boolean): void {
    console.error('Method not implemented.');
  }
}

@Injectable({
  providedIn: 'root'
})
export class LivekitService
  implements StreamChangeEventListener, MessageObserver, AudioAnalyserCtlr
{
  private setBWTimer: NodeJS.Timer;
  public sockets: Map<string, Socket> = new Map(); // key is address
  public bandwidthKb = 1024;
  public masterVolume = 0.5;
  masterHold: boolean;
  private room: Room;
  audioContext: AudioContext;
  public rtcConnections: RtcLivekitConnectionImpl[] = [];
  public rtcChatMessages: RtcChatMessage[] = [];
  public contacts: any[];
  public contactSvc: any;
  public ourNickname = 'rtcunknown'; // value set by contact service
  inputstream: MediaStream;
  videoMutedByRemote: any;
  localVideoMuted: any;
  localAudioMuted: any;
  audioMutedByRemote: any;
  private encoder = new TextEncoder();
  private decoder = new TextDecoder();

  constructor(
    public network: NetworkService,
    public systemBus: SystemBusService,
    public api: EqcallapiService,
    public ngZone: NgZone,
    public proxySvc: RtcproxyService,
    public streamHandler: StreamhandlerService,
    public config: ConfigService
  ) {
    systemBus.subscribe(this);
    setLogLevel('debug');
  }

  /* audio Anylizer methods */
  sendRawMessage(data: any, arg: string): void {
    console.error('Method not implemented.');
    if (data.type == 'anlz') {
      /*
    send to rtcconnections who care
    if none, stop
    */
    }
  }

  sendRTCMessage(
    message: Uint8Array,
    origin: string,
    participant: RemoteParticipant
  ) {
    this.room.localParticipant.publishData(message, DataPacket_Kind.RELIABLE, [
      participant.sid
    ]);
  }

  newRtcChatRecieved(message: string, connection: RtcConnection) {
    const rtcMessage = new RtcChatMessage(
      connection.getRemoteNickName(),
      message,
      false
    );
    this.rtcChatMessages.push(rtcMessage);
    this.systemBus.emit(this, 'rtc/chatMessage/new');
  }

  public async remoteCtlDataMessage(packet: any, messageType: string) {
    const data = JSON.parse(packet['data']);
    const dataType = data.type;
    if (messageType === 'messages' && dataType === 'levels') {
      if (
        data.hasOwnProperty('volume') ||
        data.hasOwnProperty('eq') ||
        data.hasOwnProperty('ng') ||
        data.hasOwnProperty('audioMute') ||
        data.hasOwnProperty('videoMute') ||
        data.hasOwnProperty('hold')
      ) {
        // this.rtcConnections.forEach((connection) => {
        //   if (!connection.getIsRemoteControlled()) {
        //     connection.remoteCtlDataMessage(packet, messageType);
        //   }
        // });
        console.error('Not Implemented');
      }
    }
  }

  private setLocalVolume(level: number) {
    console.error('Not Implemented', level);
    // this.analyser.setVolume(level);
    // this.sendEQValues(this.analyser.getLocalLevels());
  }

  private roomDataReceived(
    payload: Uint8Array,
    participant: Participant,
    kind: DataPacket_Kind
  ) {
    let rtc = this.getConnection(participant.identity);
    let data = this.decoder.decode(payload);
    console.error(' Data recieved ', rtc, data);
    rtc.receivedDataMessage(data, 'messages');
  }

  getDestinationID(): string {
    return this.network.address;
  }

  sendEQValues(values: EqualizerValues): void {
    console.error('Method not implemented.');
  }

  async rtcConnectionClosed(rtcConnection: RtcLivekitConnectionImpl) {
    this.rtcConnections.splice(this.rtcConnections.indexOf(rtcConnection), 1);
    this.systemBus.emit(rtcConnection, 'rtc/connection/removed');
    this.sockets.delete(rtcConnection.getDestinationID());
    if (this.rtcConnections.length === 0) {
      this.setMasterVideoMute(true);
      this.room.localParticipant.unpublishTracks(this.inputstream.getTracks());
      await this.room.disconnect();
      this.room = null;
      this.streamHandler.returnMediaStream(this.inputstream);
      console.error(
        'LiveKit Service Streamhandler mediastream closed ',
        this.inputstream.getTracks()
      );
      this.inputstream = null;
    }
  }

  public setMasterHold(hold: boolean) {
    this.masterHold = hold;
    for (let connection of this.rtcConnections) {
      connection.hold(hold);
    }
  }
  public setBandwidth(bandwidthKb: number) {
    if (bandwidthKb > 2000) {
      bandwidthKb = 2000;
    }
    this.bandwidthKb = bandwidthKb;

    if (typeof Storage !== 'undefined') {
      this.config.setItem('masterBandwidth', String(bandwidthKb));
    }
    clearTimeout(this.setBWTimer);
    this.setBWTimer = setTimeout(() => {
      this.fixBandwidth();
    }, 5000);
  }
  private fixBandwidth() {
    for (let connection of this.rtcConnections) {
      connection.setBandwidth(this.bandwidthKb);
    }
  }
  public shareDesktop(share: boolean, audio: boolean) {
    console.error('Reimplement');
    this.streamHandler.shareDesktop(share, audio);
  }
  public endAllCalls() {
    for (let connection of this.rtcConnections) {
      setTimeout(() => {
        connection.close();
      }, 1000);
    }
  }
  public setAudioInDevice(deviceID: string) {
    this.streamHandler.setAudioInDevice(deviceID);
  }

  public setAudioOutDevice(deviceID: string) {
    this.streamHandler.setAudioOutDevice(deviceID);
  }

  public setVideoInDevice(deviceID: string) {
    this.streamHandler.setVideoInDevice(deviceID);
  }
  public setVideoResolution(width: number, height: number) {
    this.streamHandler.setVideoResolution(width, height);
  }
  public setMasterVolume(level: number) {
    this.masterVolume = level / 100.0;
    const allVids = document.querySelectorAll('video');
    for (let i = 0; i < allVids.length; ++i) {
      allVids[i].volume = this.masterVolume;
    }
    if (typeof Storage !== 'undefined') {
      this.config.setItem('masterVolume', String(this.masterVolume));
    }
  }

  public getMasterVolume(): number {
    return this.masterVolume * 100;
  }

  onBusMessage(message: any, type: string): void {
    if (type === 'rtc/masterAudioMute') {
      this.setMasterAudioMute(message);
    } else if (type === 'rtc/masterVideoMute') {
      this.setMasterVideoMute(message);
    } else if (type === 'streamHandler/Initialized') {
      this.audioContext = this.streamHandler.audioContext;
      this.streamHandler.webAudioUnlock();
      this.network.listen(
        'Rtc',
        new RtcSocketListenerCallback(this, this.contactSvc)
      );
      this.updateSavedValues();
      this.streamHandler.getVuMeter().setTalkingListener(this);
      this.streamHandler.addStreamChangeEventListener(this);
    } else {
      console.error('Unhandeled messagetype ', type);
    }
  }
  updateSavedValues() {
    if (typeof Storage !== 'undefined') {
      const volume = this.config.getItem('masterVolume');
      if (volume) {
        this.setMasterVolume(Number(volume) * 100);
      }
      let val = this.config.getItem('masterBandwidth');
      if (val) {
        this.setBandwidth(Number(val));
      }
    }
  }
  async setMasterVideoMute(mute: boolean) {
    if (this.room) {
      let pubs = this.room.localParticipant.getTracks();
      pubs.forEach((pub: { kind: any }) => {
        if (pub.kind == Track.Kind.Video) {
          if (mute) {
            (<LocalTrackPublication>pub).mute();
          } else {
            (<LocalTrackPublication>pub).unmute();
          }
        }
      });
    }
  }
  setMasterAudioMute(mute: boolean) {
    if (this.room) {
      let pubs = this.room.localParticipant.getTracks();
      pubs.forEach((pub: { kind: any }) => {
        if (pub.kind == Track.Kind.Audio) {
          if (mute) {
            (<LocalTrackPublication>pub).mute();
          } else {
            (<LocalTrackPublication>pub).unmute();
          }
        }
      });
    }
  }

  busMessageFilter(messageType: string): boolean {
    return (
      messageType === 'rtc/masterAudioMute' ||
      messageType === 'rtc/masterVideoMute' ||
      messageType === 'streamHandler/Initialized'
    );
  }

  /**
   * Called by streamHandler through RTCService when video / audio streams change
   */
  public async streamHandlerOnStreamChangeEvent(stream: MediaStream) {
    console.log(
      'new/orig video tracks:',
      stream.getVideoTracks(),
      this.inputstream.getVideoTracks()
    );
    console.log(
      'new/orig audio tracks:',
      stream.getAudioTracks(),
      this.inputstream.getAudioTracks()
    );
    let ntrack: MediaStreamTrack = null;
    let otrack: MediaStreamTrack = null;
    let nid: string = null;
    let oid: string = null;
    let tracks = stream.getVideoTracks();
    if (tracks && tracks.length > 0) {
      ntrack = tracks[0];
      if (ntrack.readyState === 'live') {
        nid = ntrack.id;
      } else {
        ntrack = null;
        console.error('video track not live');
      }
    }
    tracks = this.inputstream.getVideoTracks();
    if (tracks && tracks.length > 0) {
      otrack = tracks[0];
      oid = otrack.id;
    }
    if (oid !== nid) {
      console.log('New video track');
      if (otrack) {
        console.error('Removed old video track', otrack);
        this.room.localParticipant.unpublishTrack(otrack, true);
        this.inputstream.removeTrack(otrack);
      }
      if (ntrack) {
        let vmuted =
          this.localVideoMuted ||
          this.videoMutedByRemote ||
          this.streamHandler.masterVideoMute;
        console.error(
          ntrack,
          this.localVideoMuted,
          this.videoMutedByRemote,
          this.streamHandler.masterVideoMute
        );
        await this.room.localParticipant.publishTrack(
          ntrack,
          this.getVideoPublishOptions()
        );
        this.inputstream.addTrack(ntrack);
        //  ntrack.enabled = vmuted;
      }
    }

    otrack = null;
    tracks = stream.getAudioTracks();
    if (tracks && tracks.length > 0) {
      ntrack = tracks[0];
      if (ntrack.readyState === 'live') {
        nid = ntrack.id;
      } else {
        ntrack = null;
        console.error('audio track not live');
      }
    }
    tracks = this.inputstream.getAudioTracks();
    if (tracks && tracks.length > 0) {
      otrack = tracks[0];
      oid = otrack.id;
    }

    if (oid !== nid) {
      console.log('New audio track');
      if (otrack) {
        console.error('Removed old Audio track', otrack);
        this.room.localParticipant.unpublishTrack(otrack, true);
        this.inputstream.removeTrack(otrack);
      }
      if (ntrack) {
        let vmuted =
          this.localAudioMuted ||
          this.audioMutedByRemote ||
          this.streamHandler.masterAudioMute;
        console.error(
          ntrack,
          this.localAudioMuted,
          this.audioMutedByRemote,
          this.streamHandler.masterAudioMute
        );
        ntrack.enabled = vmuted;
        await this.room.localParticipant.publishTrack(ntrack, {
          name: 'audio',
          audioBitrate: AudioPresets.music.maxBitrate,
          source: Track.Source.Microphone
        });
        this.inputstream.addTrack(ntrack);
      }
    }
    console.log('inputStream Video tracks', this.inputstream.getVideoTracks());
    console.log('inputStream Ausio tracks', this.inputstream.getAudioTracks());
  }

  private getVideoPublishOptions() {
    let layers = [VideoPresets.h180, VideoPresets.h540, VideoPresets.h720];
    let framerate = 25;
    let bitrate = this.bandwidthKb * 1024;
    if (this.streamHandler.shareingDesktop) {
      layers = [VideoPresets.h720];
      framerate = 5;
    }
    layers.forEach((layer) => {
      layer.encoding.maxBitrate = bitrate;
    });
    return {
      name: 'video',
      simulcast: true,
      videoSimulcastLayers: layers,
      videoEncoding: {
        maxBitrate: bitrate,
        maxFramerate: framerate
      },
      source: Track.Source.Camera
    };
  }

  public setContacts(contacts: import('./contacts.service').Contact[]) {
    this.contacts = contacts;
  }
  public sendChatMessageToAll(message: string) {
    const rtcMessage = new RtcChatMessage('You', message, true);
    this.rtcChatMessages.push(rtcMessage);
    for (let connection of this.rtcConnections) {
      connection.sendChatMessage(message);
    }
  }

  public getMasterHold(): boolean {
    return this.masterHold;
  }

  public async talking(talking: boolean) {
    console.log('talking ' + talking);
    try {
      if (!this.streamHandler.masterAudioMute && !this.masterHold) {
        for (let rtc of this.rtcConnections) {
          rtc.sendTalking(talking);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * Called to initiate a connection
   */
  public async connect(
    contact: Contact,
    joinGroup: boolean,
    enableVideo: boolean
  ): Promise<RtcConnection> {
    if (enableVideo) {
      if (this.rtcConnections.length === 0) {
        this.streamHandler.setMasterVideoMute(false);
      }
    } else {
      if (this.rtcConnections.length === 0) {
        this.streamHandler.setMasterVideoMute(true);
      }
    }
    console.log('Connect: dest,src ', contact.destAddress, contact.srcAddress);
    const currentSocket = this.sockets.get(contact.destAddress);
    if (!currentSocket) {
      let header = {
        joinGroup: joinGroup,
        nickname: this.ourNickname,
        keyCode: contact.keyCode,
        proxyData: <any>undefined
      };

      // get a proxy token for this contact
      const body = await this.api.getcontactProxy(
        contact.destAddress,
        contact.srcAddress,
        contact.nickname
      );
      console.error('Body:', body);
      const data = JSON.parse(body);
      console.error('Token DATA=', data);
      header.proxyData = { token: data.participantToken, URL: data.url };

      console.warn('Proxy header =', header);
      await this.initRoom(data.adminToken, data.url);
      console.error('Sending socket connection requst');
      const socket = this.network.connect(
        contact.destAddress,
        contact.srcAddress,
        'Rtc',
        JSON.stringify(header)
      );

      const connection = new RtcLivekitConnectionImpl(this, socket, contact);
      socket.connect();
      this.rtcConnections.push(connection);
      this.fixBandwidth();
      this.systemBus.emit(connection, 'rtc/connection/new');
      // client will send an accept packet which will cause webRtcInit to be called on the RtcConnection
      return connection;
    } else {
      console.error('RTCService: connect: Already connected');
      this.rtcConnections.forEach((connection) => {
        if (connection.getDestinationID() === contact.destAddress) {
          return connection;
        }
      });
    }
  }

  private async initRoom(token: any, livekiturl: string) {
    if (!this.room) {
      this.room = new Room({
        // automatically manage subscribed video quality
        adaptiveStream: true,
        // optimize publishing bandwidth and CPU for simulcasted tracks
        dynacast: true
      });
      // set up event listeners

      this.room
        .on(
          RoomEvent.ActiveSpeakersChanged,
          this.handleActiveSpeakerChange.bind(this)
        )
        .on(RoomEvent.Disconnected, this.handleDisconnect.bind(this))
        .on(
          RoomEvent.LocalTrackUnpublished,
          this.handleLocalTrackUnpublished.bind(this)
        )
        .on(
          RoomEvent.ParticipantConnected,
          this.handleParticipantConnected.bind(this)
        )
        .on(
          RoomEvent.ParticipantDisconnected,
          this.handleParticipantDisconnected.bind(this)
        )
        .on(RoomEvent.StateChanged, this.roomStateChanged.bind(this))
        .on(RoomEvent.Reconnecting, this.roomReconnecting.bind(this))
        .on(RoomEvent.Reconnected, this.roomReconnected.bind(this))
        .on(RoomEvent.DataReceived, this.roomDataReceived.bind(this))
        .on(
          RoomEvent.ConnectionQualityChanged,
          this.roomConnectionQualityChanged.bind(this)
        );

      // connect to room
      await this.room.connect(livekiturl, token, {
        // don't subscribe to other participants automatically
        autoSubscribe: true
      });
      console.log('connected to room', this.room.name);
      //  await this.room.localParticipant.enableCameraAndMicrophone();

      this.inputstream = await this.streamHandler.getMediaStream();

      await this.room.localParticipant.publishTrack(
        (await this.inputstream).getVideoTracks()[0],
        this.getVideoPublishOptions()
      );

      await this.room.localParticipant.publishTrack(
        (await this.inputstream).getAudioTracks()[0],
        {
          name: 'audio',
          source: Track.Source.Microphone
        }
      );
    }
  }

  async getUserMedia() {
    if (!this.inputstream) {
      this.inputstream = await this.streamHandler.getMediaStream();
      this.initGetUserMediaStreams(this.inputstream);
    }
    return this.inputstream;
  }

  private initGetUserMediaStreams(stream: MediaStream) {
    const vtracks = stream.getVideoTracks();
    if (vtracks.length > 0) {
      this.inputstream = new MediaStream(vtracks);
    }
    const atracks = stream.getAudioTracks();
    if (atracks.length > 0) {
      //   this.analyser = new AudioAnalyserImpl(
      //     this.streamHandler.audioContext,
      //     this,
      //     this.socket.connectionID,
      //     this.streamHandler
      //   );
      //   let audioLine = this.service.audioContext.createMediaStreamDestination();
      //   this.analyser.connect(atracks, audioLine);
      //   if (!this.inputstream) {
      //     this.inputStream = audioLine.stream;
      //   } else {
      //     this.inputstream.addTrack(audioLine.stream.getAudioTracks()[0]);
      //   }
      // } else {
      //   console.warn('RtcConnection: initGetUserMedia: no audio');
      //   this.analyser = new NoAudioAnalyserImpl();
      // }
      // if (!this.inputstream) {
      //   this.inputstream = stream;
      // }
      // const canvas = <HTMLCanvasElement>(
      //   document.getElementById('visualizer-' + this.socket.getDestination())
      // );
      // if (canvas) {
      //   this.visualizer.setCanvas(canvas);
    }
  }

  private roomConnectionQualityChanged(
    quality: ConnectionQuality,
    participant: Participant
  ) {
    console.error(
      'LiveKit roomConnectionQualityChanged ',
      quality,
      participant
    );
  }

  private roomReconnecting() {
    console.error('LiveKit room reconnecting');
  }

  private roomReconnected() {
    console.error('LiveKit room reconnected');
  }
  /*
   * Called to initialize connection after receiving connection request.
   * Still have to call acceptConnection on rtcConnection after videoElements have been created
   */
  public async connectSocket(
    socket: Socket,
    contact: Contact,
    joinGroup: boolean,
    enableVideo: boolean
  ) {
    if (this.haveConnection(socket.getDestination())) {
      socket.close();
      // TODO Handle this better !!
      console.error('Already connected!!!!!!!!!!!');
      return;
    }
    if (enableVideo) {
      if (this.rtcConnections.length === 0) {
        this.streamHandler.setMasterVideoMute(false);
      }
    } else {
      if (this.rtcConnections.length === 0) {
        this.streamHandler.setMasterVideoMute(true);
      }
    }
    //let mode = await this.getMode();
    // const connection = new RTC(socket, this, true, joinGroup, remoteNickname, mode, this.streamHandler, true, enableVideo);
    let header = JSON.parse(socket.getHeader());
    console.log('Header ', header);

    await this.initRoom(header.proxyData.token, header.proxyData.URL);
    const connection = new RtcLivekitConnectionImpl(this, socket, contact);
    connection.isBeingCalled = true;
    this.rtcConnections.push(connection);

    // socket.accept();
    // socket.send(
    //   JSON.stringify({
    //     index: 0,
    //     mode: 'livekit',
    //     type: 'accept'
    //   })
    // );
    this.systemBus.emit(connection, 'rtc/connection/new');
    //  if (connection.joinGroup) {
    // 	 this.sendGroupPeers(connection);
    //  }
    this.addCurrentRoomParticipants();
  }

  private addCurrentRoomParticipants() {
    this.room.participants.forEach((participant: Participant) => {
      if (participant instanceof RemoteParticipant) {
        let connection = this.getConnection(participant.identity);
        if (connection) {
          connection.setParticipant(participant);
        } else {
          console.log('adding current room participant');
          let contact = this.contactSvc.getContactByAddress(
            participant.identity
          );
          connection = new RtcLivekitConnectionImpl(this, null, contact);
          connection.setParticipant(participant);
          this.rtcConnections.push(connection);
          this.systemBus.emit(connection, 'rtc/connection/new');
        }
      }
    });
  }
  private haveConnection(destAddr: string): boolean {
    for (let connection of this.rtcConnections) {
      if (connection.getDestinationID() === destAddr) {
        return true;
      }
    }
    return false;
  }
  private getConnection(destAddr: string): RtcLivekitConnectionImpl {
    for (let connection of this.rtcConnections) {
      if (connection.getDestinationID() === destAddr) {
        return connection;
      }
    }
    return null;
  }

  public connectSocketDefaultVideo(
    socket: Socket,
    contact: Contact,
    joinGroup: boolean
  ) {
    this.connectSocket(
      socket,
      contact,
      joinGroup,
      !this.streamHandler.masterVideoMute
    );
  }

  public answerRemoteControlRequest(
    answer: boolean,
    connection: RtcConnection
  ) {
    connection.setIsRemoteControlledAnswer(answer);
    this.rtcConnections.forEach((c) => {
      if (c.getDestinationID() !== connection.getDestinationID()) {
        c.setIsRemoteControlledAnswer(false); // only one remote controller
      }
    });
    connection.remoteControlRequestSendAnswer(answer);
  }

  handleParticipantConnected(remoteParticipant: RemoteParticipant) {
    console.log(
      'LiveKit handleParticipantConnected',
      remoteParticipant,
      this.rtcConnections
    );
    const identity = remoteParticipant.identity;
    this.rtcConnections.forEach((c) => {
      if (c.getDestinationID() === identity) {
        c.setParticipant(remoteParticipant);
      }
    });
  }

  handleParticipantDisconnected(remoteParticipant: RemoteParticipant) {
    console.log(
      'LiveKit handleParticipantDisconnected',
      remoteParticipant,
      this.rtcConnections
    );
    const identity = remoteParticipant.identity;
    let connection = this.getConnection(remoteParticipant.identity);
    if (connection) {
      connection.close();
    }
  }

  private handleLocalTrackUnpublished(
    track: LocalTrackPublication,
    participant: LocalParticipant
  ) {
    // when local tracks are ended, update UI to remove them from rendering
    //track.detach();
    console.log('LiveKit handleLocalTrackUnpublished ', track, participant);
  }

  private handleActiveSpeakerChange(speakers: Participant[]) {
    // show UI indicators when participant is speaking
    console.log('LiveKit handleActiveSpeakerChange ', speakers);
  }

  private handleDisconnect() {
    console.log('LiveKit dhandleDisconnect from room');

    if (this.room) {
      this.endAllCalls();
    }
  }

  private roomStateChanged(roomState: RoomState) {
    console.log('LiveKit RoomState change', roomState);
  }
}
