import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Messenger } from '../../../service/message.service';

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['../../../../assets/css/custom.css']
})
export class MessengerComponent implements OnInit {
  @Input() messenger: Messenger;
  @Input() inCall: boolean;
  @Input() rtcButtons: boolean;
  @Output() saEvent = new EventEmitter();
  public messengerType: string;
  public showAll = false;
  constructor() {}

  ngOnInit() {
    this.messengerType = this.messenger.messengerType;
  }

  public saStatus($event: any) {
    this.showAll = $event;
    this.saEvent.emit(this.showAll);
  }
}
