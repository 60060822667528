import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { LoggedInCallback, UserLoginService } from './cognito.service';
import { MessageObserver, SystemBusService } from './system-bus.service';
import { TranslationService } from './translation.service';

// https://www.npmjs.com/package/cordova-plugin-tts-advanced
export class RingTone {
  constructor(
    public desc: string,
    public index: string,
    public inUrl: string,
    public outUrl: string,
    public busyUrl: string
  ) {}
}

export class CordovaVoice implements SpeechSynthesisVoice {
  default = false;
  lang: string;
  localService = true;
  name: string;
  voiceURI: string;
  constructor(voice: TTS.TTSVoice) {
    this.lang = voice.language;
    this.name = voice.name;
    this.voiceURI = voice.identifier;
  }
}

class UrlSoundPlayer {
  private cache = new Map<string, AudioBuffer>();
  private context: AudioContext;
  private sources: Set<AudioBufferSourceNode> = new Set();
  constructor() {
    this.webAudioUnlock();
  }

  private webAudioUnlock() {
    if (!this.context) {
      this.context = new AudioContext();
    }
    if (this.context.state === 'suspended') {
      if ('ontouchstart' in window) {
        let unlock = () => {
          this.context.resume().then(() => {
            document.body.removeEventListener('touchstart', unlock);
            document.body.removeEventListener('touchend', unlock);
          });
        };

        document.body.addEventListener('touchstart', unlock, false);
        document.body.addEventListener('touchend', unlock, false);
      } else {
        this.context.resume();
      }
    }
  }

  public preloadUrl(url: string) {
    let buf = this.cache.get(url);
    if (!buf) {
      const request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = 'arraybuffer';

      request.onload = () => {
        // Asynchronously decode the audio file data in request.response

        this.context.decodeAudioData(
          request.response,
          (buffer: any) => {
            if (!buffer) {
              console.error('UrlSoundPlayer: error decoding file data: ' + url);
              return;
            }
            // console.log('UrlSoundPlayer: got URL Buffer');
            this.cache.set(url, buffer);
          },
          function (error: any) {
            console.error('UrlSoundPlayer: decodeAudioData error', error);
          }
        );
      };

      request.onerror = function () {
        console.error('UrlSoundPlayer: BufferLoader: XHR error');
      };

      request.send();
    }
  }

  public playUrl(url: string, loop: boolean) {
    // Load buffer asynchronously
    this.webAudioUnlock();
    let buf = this.cache.get(url);
    if (!buf) {
      const request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = 'arraybuffer';

      request.onload = () => {
        // Asynchronously decode the audio file data in request.response

        this.context.decodeAudioData(
          request.response,
          (buffer: any) => {
            if (!buffer) {
              console.error('UrlSoundPlayer: error decoding file data: ' + url);
              return;
            }
            console.log('UrlSoundPlayer: got URL Buffer');
            this.cache.set(url, buffer);
            this.playBuffer(buffer, loop);
          },
          function (error: any) {
            console.error('UrlSoundPlayer: decodeAudioData error', error);
          }
        );
      };

      request.onerror = function () {
        console.error('UrlSoundPlayer: BufferLoader: XHR error');
      };

      request.send();
    } else {
      //   console.log('Playing from cache');
      this.playBuffer(buf, loop);
    }
  }

  public stopAll() {
    this.sources.forEach((source) => {
      source.stop();
      source.disconnect();
    });
    this.sources.clear();
  }

  private playBuffer(buffer: any, loop: boolean): AudioBufferSourceNode {
    // this.stop();
    let source = this.context.createBufferSource();
    if (loop) {
      this.sources.add(source);
    }
    source.onended = () => {
      source.disconnect();
      this.sources.delete(source);
    };
    source.loop = loop;
    source.buffer = buffer;
    source.connect(this.context.destination);
    //   console.log('UrlSoundPlayer: Starting source.');
    source.start(0);
    return source;
  }
}

@Injectable()
export class AudioService implements LoggedInCallback, MessageObserver {
  private urlSoundPlayer: UrlSoundPlayer;
  public ringTones: RingTone[] = [];
  public currentRingTone: RingTone;
  public speech = true;
  public privateChat = true;
  public groupChat = true;
  public voices: SpeechSynthesisVoice[] = [];
  public selectedVoice: SpeechSynthesisVoice;
  public isCordova = false;

  constructor(
    private userService: UserLoginService,
    private tr: TranslationService,
    private busSvc: SystemBusService,
    private config: ConfigService
  ) {
    this.userService.onceAuthenticated(this);
  }

  isLoggedIn(_message: string, _loggedIn: boolean): void {
    this.init();
    this.busSvc.subscribe(this);
  }

  private async init() {
    this.updateRingTones();
    this.updateMute();
    if (window.speechSynthesis) {
      window.speechSynthesis.onvoiceschanged = () => {
        this.updateVoice();
      };
    } else if (TTS !== undefined) {
      this.isCordova = true;
      this.updateVoice();
    } else {
      console.log('No speach');
      this.speech = false;
    }
  }

  private getPlayer(): UrlSoundPlayer {
    if (!this.urlSoundPlayer) {
      this.urlSoundPlayer = new UrlSoundPlayer();
    }
    return this.urlSoundPlayer;
  }

  private async updateVoice() {
    let savedVoiceURI = this.config.getItem('voice');
    this.selectedVoice = undefined;
    if (this.isCordova) {
      let ttsVoices = await TTS.getVoices();
      console.error('TTS voices = ', ttsVoices);
      this.voices = ttsVoices.map((voice) => {
        return new CordovaVoice(voice);
      });
    } else {
      this.voices = speechSynthesis.getVoices();
    }
    console.log('Voices = ', this.voices);
    let lang = this.tr.selectedLanguage;
    this.voices.forEach((voice) => {
      if (savedVoiceURI) {
        if (savedVoiceURI === voice.voiceURI) {
          this.selectedVoice = voice;
          // console.log('Selecting saved voice ', voice);
        }
      } else if (voice.lang.startsWith(lang) && !this.selectedVoice) {
        //  console.log('Selecting voice ', voice);
        this.selectedVoice = voice;
      }
    });

    if (!this.selectedVoice) {
      if (savedVoiceURI) {
        // did not find a match, remove saved
        this.config.removeItem('voice');
        this.updateVoice();
      } else {
        this.selectedVoice = this.voices[0];
      }
    }
  }

  public setVoice(voiceURI: string) {
    //  console.log('VoiceURI=', voiceURI);
    this.voices.forEach((voice) => {
      if (voice.voiceURI === voiceURI) {
        //   console.log('found vocie');
        this.selectedVoice = voice;
      }
    });

    this.config.setItem('voice', this.selectedVoice.voiceURI);
  }

  onBusMessage(_message: any, _type: string): void {
    this.updateVoice();
  }

  busMessageFilter(messageType: string): boolean {
    return messageType === 'language/changed';
  }

  private async updateRingTones() {
    this.ringTones.push(
      new RingTone(
        'Standard eqCall Ring Tone/Dial Tone',
        '1',
        'assets/sounds/incomingRtc.mp3',
        'assets/sounds/modem.mp3',
        'assets/sounds/Vibrate.mp3'
      )
    );
    this.ringTones.push(
      new RingTone(
        'Retro eqCall Ring Tone/Dial Tone',
        '2',
        'assets/sounds/OldPhone.mp3',
        'assets/sounds/RingingPhone.mp3',
        'assets/sounds/Vibrate.mp3'
      )
    );
    this.ringTones.push(
      new RingTone(
        'Afternoon Delight Ring Tone/Dial Tone',
        '3',
        'assets/sounds/incomingRtc.mp3',
        'assets/sounds/RingingPhone.mp3',
        'assets/sounds/Vibrate.mp3'
      )
    );
    this.ringTones.push(
      new RingTone(
        'Ring and Dial Sounds Off',
        '4',
        '',
        '',
        'assets/sounds/Vibrate.mp3'
      )
    );

    let savedIdx = this.config.getItem('RingToneIndex');
    if (savedIdx) {
      this.ringTones.forEach((rngtone: RingTone) => {
        if (rngtone.index === savedIdx) {
          this.currentRingTone = rngtone;
        }
      });
    } else {
      this.currentRingTone = this.ringTones[0];
    }
  }

  public async playURL(url: string) {
    if (url.length !== 0) {
      this.getPlayer().playUrl(url, false);
    }
  }

  public async preLoadURL(url: string) {
    this.getPlayer().preloadUrl(url);
  }

  private async playURLLoop(url: string) {
    console.log('Playing URL loop ', url);
    if (url.length !== 0) {
      this.getPlayer().playUrl(url, true);
    }
  }

  public RtcRingIn(start: boolean) {
    if (start) {
      this.playURLLoop(this.currentRingTone.inUrl);
    } else {
      this.stop();
    }
  }

  public RtcRingOut(start: boolean) {
    console.log('RtcRingout ' + start);
    if (start) {
      this.playURLLoop(this.currentRingTone.outUrl);
    } else {
      this.stop();
    }
  }

  public PhoneRingIn(start: boolean) {
    if (start) {
      this.playURLLoop(this.currentRingTone.inUrl);
    } else {
      this.stop();
    }
  }

  public ringBusy(start: boolean) {
    console.warn('busy');
    if (start) {
      this.playURLLoop(this.currentRingTone.busyUrl);
    } else {
      this.stop();
    }
  }

  public PhoneRingOut(start: boolean) {
    if (start) {
      this.playURLLoop(this.currentRingTone.outUrl);
    } else {
      this.stop();
    }
  }

  public stop() {
    // console.log('Stop() called');
    this.getPlayer().stopAll();
  }

  public async playText(text: string) {
    if (this.speech) {
      if (this.isCordova) {
        if (this.selectedVoice) {
          TTS.speak({ text: text, identifier: this.selectedVoice.voiceURI });
        } else {
          TTS.speak(text);
        }
      } else {
        let msg = new SpeechSynthesisUtterance(text);
        msg.lang = this.tr.selectedLanguage;
        if (this.selectedVoice) {
          msg.voice = this.selectedVoice;
        }
        (<any>window).speechSynthesis.speak(msg);
      }
    } else {
      console.log('speech muted');
    }
    return;
  }

  public setRingTone(ringTone: RingTone) {
    this.currentRingTone = ringTone;
    this.config.setItem('RingToneIndex', ringTone.index);
  }

  public muteSpeech(mute: boolean) {
    this.speech = !mute;
    this.config.setItem('MuteSpeech', mute ? 'true' : 'false');
  }

  public mutePrivateChat(mute: boolean) {
    this.privateChat = !mute;
    this.config.setItem('MutePrivateChat', mute ? 'true' : 'false');
  }

  public muteGroupChat(mute: boolean) {
    this.groupChat = !mute;
    this.config.setItem('MuteGroupChat', mute ? 'true' : 'false');
  }

  private async updateMute() {
    this.speech = this.config.getItem('MuteSpeech') !== 'true';
    this.privateChat = this.config.getItem('MutePrivateChat') !== 'true';
    this.groupChat = this.config.getItem('MuteGroupChat') !== 'true';
  }
}
