import { AudioService } from '../../../../service/audio.service';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Contact, ContactsService } from '../../../../service/contacts.service';
import { LivekitService } from '../../../../service/livekit.service';
import {
  MessageObserver,
  SystemBusService
} from '../../../../service/system-bus.service';
import { Socket, SocketListener } from '../../../../service/network.service';
import { TranslationService } from '../../../../service/translation.service';
import { TwilioService } from '../../../../service/twilio.service';

@Component({
  selector: 'app-ringer',
  templateUrl: './ringer.component.html',
  styleUrls: ['../../../../../assets/css/custom.css']
})
export class RingerComponent
  implements SocketListener, MessageObserver, OnDestroy
{
  public ringing = false;
  public socket: Socket;
  public contact: Contact;
  public joinGroup: any;
  public alreadyingroup: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private rtcSvc: LivekitService,
    private systemBus: SystemBusService,
    private audioSvc: AudioService,
    public tr: TranslationService,
    private twilioSvc: TwilioService,
    private contactSvc: ContactsService
  ) {
    this.systemBus.subscribe(this);
  }

  ngOnDestroy(): void {
    this.systemBus.unSubscribe(this);
  }

  onBusMessage(message: any, type: string): void {
    if (type === 'rtc/connection/request') {
      const socket: Socket = message;
      const address = socket.getDestination();
      let contact = this.contactSvc.getContactByAddress(address);

      if (!document.hasFocus()) {
        console.log('Notification for call');
        navigator.serviceWorker.ready.then((registration) => {
          console.log('Ringer, sending Call notification');
          registration.showNotification('Call', {
            body: 'Call from ' + contact.nickname,
            icon: '/favicon.ico',
            badge: '/assets/img/iconWhite.png',
            tag: 'eqcall',
            vibrate: [400, 200, 400, 200, 400],
            renotify: true,
            requireInteraction: true,
            data: {
              address: contact.destAddress,
              onActionClick: {
                default: { operation: 'openWindow', url: 'dev.eqcall.com' }
              }
            },
            actions: [
              {
                action: 'answer',
                title: 'Answer',
                icon: '/assets/img/call_answer.png'
              },
              {
                action: 'ignore',
                title: 'Ignore',
                icon: '/assets/img/call_end.png'
              }
            ]
          });
        });
      }

      if (!this.twilioSvc.autoAnswer(contact)) {
        console.log(
          'Criticall: onBusMessage: address=' +
            contact.destAddress +
            ' nickname = ' +
            contact.nickname
        );
        this.show(socket, contact);
        if (this.rtcSvc.rtcConnections.length === 0) {
          this.audioSvc.playText(
            this.tr.get('criticall', 'answer.voice.txt.1') +
              this.contactSvc.localContact[0].nickname +
              this.tr.get('criticall', 'answer.voice.txt.2') +
              contact.nickname +
              this.tr.get('criticall', 'answer.voice.txt.3')
          );
          this.audioSvc.RtcRingIn(true);
        } else {
          this.audioSvc.ringBusy(true);
        }
      } else {
        this.rtcSvc.connectSocketDefaultVideo(socket, contact, true);
      }
    } else if (type === 'pushMessageClick') {
      if (message.notification.title === 'Call') {
        if (message.action === 'answer') {
          this.answerAudio();
        } else if (message.action === 'ignore') {
          this.hangup();
        } else {
          console.log('No selection', message);
        }
      }
    }
  }

  show(socket: Socket, contact: Contact) {
    this.socket = socket;
    this.contact = contact;
    this.ringing = true;
    this.changeDetectorRef.detectChanges();
    this.socket.addListener(this);
    this.joinGroup = JSON.parse(this.socket.getHeader()).joinGroup;
    this.alreadyingroup = this.rtcSvc.rtcConnections.length > 0;
    setTimeout(() => {
      (<any>$('#ringerRequest')).modal('show');
    }, 1000);
  }

  busMessageFilter(messageType: string): boolean {
    return (
      messageType.startsWith('rtc/connection') ||
      messageType === 'pushMessageClick'
    );
  }

  public close() {
    this.audioSvc.stop();
    this.socket.removeListener(this);
    (<any>$('#ringerRequest')).modal('hide');
    this.ringing = false;
  }

  socketOnRecieve(data: string, _socket: Socket): void {
    const packet = JSON.parse(data);
    if ((packet.type = 'offer')) {
      this.close();
    } else {
      console.log('********************** ' + data);
    }
  }

  socketRemotelyClosed() {
    this.missedCall();
    this.close();
  }

  socketClosed() {
    this.close();
  }

  private missedCall() {
    const message = {
      type: 'info',
      message: this.tr.get('criticallRP', 'mis.msg') + this.contact.nickname
    };
    this.systemBus.emit(message, 'warning');
  }

  public groupVideo() {
    this.socket.removeListener(this);
    this.rtcSvc.connectSocket(this.socket, this.contact, true, true);
    this.close();
  }

  public groupAudio() {
    this.socket.removeListener(this);
    this.rtcSvc.connectSocket(this.socket, this.contact, true, false);
    this.close();
  }

  public answerAudio() {
    this.socket.removeListener(this);
    this.rtcSvc.connectSocket(this.socket, this.contact, false, false);
    this.close();
  }

  public answerVideo() {
    this.socket.removeListener(this);
    this.rtcSvc.connectSocket(this.socket, this.contact, false, true);
    this.close();
  }

  public hangup() {
    this.socket.close();
    this.close();
  }
}
