<div class="row no-gutters">
  <div
    class="collapse multi-collapse button-overlay"
    id="multiCollapseCheckboxKeys"
  >
    <div class="col-auto">
      <input
        type="checkbox"
        class="checkbox-template mt-3"
        data-bs-placement="top"
        [title]="tr.get('media-selector', 'vd.pureHD.title')"
      />
    </div>
  </div>
  <div class="col">
    <button
      style="margin-bottom: 4px; white-space: normal"
      type="button"
      class="collapsed btn-outline-contact btn-lg btn-block"
      data-bs-toggle="modal"
      [attr.data-bs-target]="'#' + key.keyCode + 'collapseLinkSetting'"
      aria-expanded="false"
      data-bs-placement="top"
      [title]="tr.get('key', 'key.menut')"
    >
      {{ tr.get('key', 'key') }}: {{ key.keyCode }}
    </button>
  </div>
</div>
<div
  class="modal fade"
  [attr.id]="key.keyCode + 'collapseLinkSetting'"
  data-bs-backdrop="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <div class="modal-content">
      <div
        class="modal-header"
        style="background-color: rgba(44, 62, 80, 0.77)"
      >
        <h5 class="modal-title ml-5">{{ tr.get('key', 'key.info') }}</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="No"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body ta-left" style="background-color: transparent">
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col-sm-auto">
                {{ tr.get('key', 'key') }}: {{ key.keyCode }}
              </div>
              <div class="col-sm-auto">
                <button
                  (click)="copy()"
                  type="submit"
                  [ngClass]="isDomain ? 'btn-warning' : 'btn-primary'"
                  class="btn-links btn-outline-borderless"
                  [title]="tr.get('key', 'copyt')"
                  title="Copy link to clip board"
                  style="
                    box-shadow: none;
                    outline: none;
                    border: none;
                    background: transparent;
                    color: #37cfdc;
                    margin-bottom: -5px;
                    margin-top: -8px;
                    margin-left: -13px;
                  "
                >
                  {{ tr.get('key', 'copy') }}
                </button>
              </div>
              <div class="col-sm-auto">
                {{ tr.get('key', 'expires') }}:
                {{ key.expiryDate | date: 'short' }}
              </div>
            </div>
            <div class="row">
              <hr />
            </div>
            <div class="row no-gutters">
              <div class="col">
                <input
                  id="desc"
                  #addDsc
                  type="text"
                  [value]="key.description"
                  class="form-control form-control-sm input-sm"
                  [placeholder]="tr.get('key', 'descp')"
                  [title]="tr.get('key', 'desct')"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          attr.id="{{ 'collapseAdvancedKeys' + key.keyCode }}"
          class="panel-collapse collapse key-selection-collapse"
          aria-expanded="false"
        >
          <div class="row">
            <div class="col">
              <div class="text-left">
                <hr />
                {{ tr.get('key', 'permissions') }}
              </div>
              <div class="row">
                <div class="col-sm-auto">
                  <input
                    id="id_callin"
                    class="checkbox-template"
                    type="checkbox"
                    [checked]="key.callin"
                    (change)="key.callin = !key.callin"
                    [title]="tr.get('key', 'dialint')"
                  />&nbsp;
                  {{ tr.get('key', 'dialin') }}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-auto">
                  <input
                    id="id_callout"
                    class="checkbox-template"
                    type="checkbox"
                    [checked]="key.callout"
                    (change)="key.callout = !key.callout"
                    [title]="tr.get('key', 'dialoutt')"
                  />&nbsp;
                  {{ tr.get('key', 'dialout') }}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-auto">
                  <input
                    id="id_restricted"
                    class="checkbox-template"
                    type="checkbox"
                    [checked]="key.restricted"
                    (change)="key.restricted = !key.restricted"
                    [title]="tr.get('key', 'r.lobbyt')"
                  />&nbsp;
                  {{ tr.get('key', 'r.lobby') }}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-auto">
                  <input
                    #ac
                    id="autoConnect"
                    class="checkbox-template"
                    type="checkbox"
                    [checked]="key.autoConnect"
                    (change)="key.autoConnect = !key.autoConnect"
                    [title]="tr.get('key', 'auto.ct')"
                  />&nbsp;
                  {{ tr.get('key', 'auto.c') }}
                </div>
              </div>
              <div class="row">
                <hr />
              </div>
              <div class="row">
                <div class="col-sm">
                  <input
                    id="uname"
                    #userName
                    type="text"
                    [value]="key.userName"
                    class="form-control form-control-sm input-sm"
                    [placeholder]="tr.get('key', 'usernamep')"
                    [title]="tr.get('key', 'usernamet')"
                  />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer d-flex justify-content-center">
        <div class="d-flex">
          <div class="me-auto p-2 ml-3">
            <button
              class="btn btn-primary btn-lg"
              [title]="tr.get('key', 'savet')"
              data-bs-toggle="collapse"
              data-bs-parent="#accordion"
              attr.data-bs-target="{{ '#collapseAdvancedKeys' + key.keyCode }}"
              aria-expanded="false"
              data-bs-placement="top"
            >
              <i class="fa fa-ellipsis-h"></i>
            </button>
          </div>
          <div class="p-2">
            <button
              class="btn btn-primary btn-lg"
              [title]="tr.get('key', 'savet')"
              (click)="save(addDsc.value, userName.value)"
            >
              <i class="fa fa-floppy-o"></i>
            </button>
          </div>
          <div class="p-2">
            <button
              type="button"
              data-bs-toggle="modal"
              [attr.data-bs-target]="'#' + key.keyCode + 'deleteKeyModal'"
              class="btn btn-danger btn-lg"
              [title]="tr.get('key', 'deletet')"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Delete Key Modal -->
<div
  class="modal fade"
  [attr.id]="key.keyCode + 'deleteKeyModal'"
  data-bs-backdrop="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Delete this Key?</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="No"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to delete this key?</p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-warning"
          data-bs-dismiss="modal"
          data-bs-placement="top"
          [title]="tr.get('contact', 'deleten')"
        >
          {{ tr.get('button', 'no') }}
        </button>
        <button
          (click)="deleteKey()"
          type="submit"
          class="btn btn-primary"
          data-bs-dismiss="modal"
          data-bs-placement="top"
          [title]="tr.get('contact', 'deletey')"
        >
          {{ tr.get('button', 'yes') }}
        </button>
      </div>
    </div>
  </div>
</div>
