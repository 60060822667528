import { Injectable, Inject } from '@angular/core';
import { RegistrationUser } from '../public/auth/register/registration.component';
import { ConfigService } from './config.service';
import { SystemBusService, InputRequest } from './system-bus.service';
import { Router } from '@angular/router';
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
  ICognitoUserAttributeData,
  IAuthenticationCallback
} from 'amazon-cognito-identity-js';
import { config, CognitoIdentityCredentials, CognitoIdentity } from 'aws-sdk';
import * as AmazonCognitoIdentity from 'amazon-cognito-auth-js';
import { CognitoAuth, CognitoAuthOptions } from 'amazon-cognito-auth-js';
import { TranslationService } from './translation.service';

export interface CognitoCallback {
  cognitoCallback(message: string, result: any): void;
}

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void;
}

export interface Callback {
  callback(): void;
  callbackWithParam(result: any): void;
}

declare var apigClientFactory: any;

@Injectable()
export class CognitoUtil {
  private userPool: CognitoUserPool;
  public currentUser: CognitoUser;
  private refreshTimer: NodeJS.Timer;
  private profileChecked = false;
  public failed = false;

  constructor(
    public appConfig: ConfigService,
    public systemBus: SystemBusService,
    public router: Router
  ) {}

  getUserPool() {
    if (!this.userPool) {
      this.userPool = new CognitoUserPool({
        UserPoolId: this.appConfig.userPoolId,
        ClientId: this.appConfig.appId
      });
    }
    return this.userPool;
  }

  getCurrentUser() {
    if (this.failed) {
      this.failed = false;
      this.currentUser = undefined;
    } else {
      if (!this.currentUser) {
        this.currentUser = this.getUserPool().getCurrentUser();
      }
    }
    return this.currentUser;
  }

  public getCredentials(): CognitoIdentityCredentials {
    return <CognitoIdentityCredentials>config.credentials;
  }

  public getRegion(): string {
    return this.appConfig.region;
  }

  getCognitoIdentityID(): string {
    let cc = <CognitoIdentityCredentials>config.credentials;
    return cc.identityId;
  }

  getAccessToken(callback: Callback): void {
    if (callback == null) {
      throw new Error(
        'CognitoUtil: callback in getAccessToken is null...returning'
      );
    }
    if (this.getCurrentUser() != null) {
      this.getCurrentUser().getSession(function (err: any, session: any) {
        if (err) {
          console.error("CognitoUtil: Can't set the credentials:" + err);
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getAccessToken().getJwtToken());
          }
        }
      });
    } else {
      console.error('Cognito: getAccessToken: returning null token!');
      callback.callbackWithParam(null);
    }
  }

  getIdTokenPromise(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getIdToken(
        new (class {
          callback() {
            reject();
          }
          callbackWithParam(idToken: any) {
            resolve(idToken);
          }
        })()
      );
    });
  }

  getIdToken(callback: Callback): void {
    if (callback == null) {
      throw new Error(
        'CognitoUtil: callback in getIdToken is null...returning'
      );
    }
    if (this.getCurrentUser() != null) {
      this.getCurrentUser().getSession(function (err: any, session: any) {
        if (err) {
          console.error("CognitoUtil: Can't set the credentials:" + err);
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getIdToken().getJwtToken());
          } else {
            callback.callbackWithParam(null);
          }
        }
      });
    } else {
      if (
        config.credentials &&
        (<CognitoIdentityCredentials>config.credentials).identityId
      ) {
        let cognitoIdentity = new CognitoIdentity();
        cognitoIdentity.getOpenIdToken(
          {
            IdentityId: (<CognitoIdentityCredentials>config.credentials)
              .identityId
          },
          function (err2: any, data: any) {
            if (err2) {
              console.log(err2, err2.stack); // an error occurred
              callback.callbackWithParam(null);
            } else {
              callback.callbackWithParam(data.Token);
            }
          }
        );
      } else {
        callback.callbackWithParam(null);
      }
    }
  }

  getRefreshToken(callback: Callback): void {
    if (callback == null) {
      throw new Error(
        'CognitoUtil: callback in getRefreshToken is null...returning'
      );
    }
    if (this.getCurrentUser() != null) {
      this.getCurrentUser().getSession(function (err: any, session: any) {
        if (err) {
          console.error("CognitoUtil: Can't set the credentials:" + err);
          callback.callbackWithParam(null);
        } else {
          if (session.isValid()) {
            callback.callbackWithParam(session.getRefreshToken());
          } else {
            console.error('Cognito: getRefreshToken: session is not valid');
          }
        }
      });
    } else {
      console.error('Cognito: getRefreshToken: returning null');
      callback.callbackWithParam(null);
    }
  }

  public async refresh(force: boolean) {
    return new Promise((resolve, reject) => {
      let cognitoUser = this.getCurrentUser();
      const creds = <CognitoIdentityCredentials>config.credentials;
      if (cognitoUser) {
        cognitoUser.getSession((err: any, session: any) => {
          if (err || !session) {
            if (!session) {
              console.log('Reloading');
              location.reload();
            } else {
              console.error('Error getting session', session, err);
              this.router.navigate(['/home/login']);
              reject();
            }
          } else {
            // Get refresh token before refreshing session
            // console.log('cognitoUserSession', session);

            let refresh_token = session.getRefreshToken();

            if (force || creds.needsRefresh()) {
              cognitoUser.refreshSession(
                refresh_token,
                (nerr: any, nsession: any) => {
                  if (nerr) {
                    if (nerr.code === 'UserNotFoundException') {
                      console.error(
                        'CognitoSvc: refresh: Account was deleted ',
                        nerr
                      );
                      cognitoUser.signOut();
                      location.reload();
                    } else if (nerr.code === 'NotAuthorizedException') {
                      cognitoUser.signOut();
                      location.reload();
                    }
                    console.error(
                      'CognitoSvc: refresh: Error refreshing sessions: ',
                      nerr
                    );
                    if (creds) {
                      try {
                        creds.clearCachedId();
                      } catch (e) {
                        console.error(
                          'CognitoSvc: refresh: Error clearing creds',
                          e
                        );
                      }
                    }

                    // this.failed = true;
                    // const message = {
                    //     type: 'info',
                    //     message: nerr.message,
                    // };
                    // this.systemBus.emit(message, 'warning');
                    // this.router.navigate(['/home/login']);
                    reject();
                    // location.reload();
                  } else {
                    (<any>creds.params).Logins[
                      'cognito-idp.' +
                        this.appConfig.region +
                        '.amazonaws.com/' +
                        this.appConfig.userPoolId
                    ] = nsession.getIdToken().getJwtToken();
                    creds.refresh((nnerr: any) => {
                      if (nnerr) {
                        console.error('Error refreshing credentials: ', nnerr);
                        creds.clearCachedId();
                        this.failed = true;
                        const message = {
                          type: 'info',
                          message: nnerr.message
                        };
                        this.systemBus.emit(message, 'warning');
                        this.router.navigate(['/home/login']);
                        reject();
                      } else {
                        this.setNextRefresh();
                        this.checkProfile();
                        resolve(null);
                      }
                    });
                  }
                }
              );
            } else {
              console.warn('Credentials do not need refresh');
              resolve(null);
            }
          }
        });
      } else {
        console.warn('No CognitoUser');
        if (force || (creds && creds.needsRefresh())) {
          creds.refresh((nnerr: any) => {
            if (nnerr) {
              console.error('Error refreshing credentials: ', nnerr);
              this.failed = true;
              creds.clearCachedId();
              const message = {
                type: 'info',
                message: nnerr.message
              };
              this.systemBus.emit(message, 'warning');
              this.router.navigate(['/home/login']);
              reject();
            } else {
              this.setNextRefresh();
              resolve(null);
            }
          });
        } else {
          console.warn('Credentials do not need refresh');
        }
        resolve(null);
      }
    });
  }

  public setNextRefresh() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
    let creds = <CognitoIdentityCredentials>config.credentials;
    let date = creds.expireTime;
    // console.info('Credentials will be expire at ' + date);
    let t = 0;
    if (date != null) {
      t = date.getTime() - new Date().getTime();
      if (t > 120000) {
        t -= 60000;
      }
    } else {
      t = 5000;
    }
    console.log('Credentials will be renewd in ' + t);
    this.refreshTimer = setTimeout(() => this.refresh(true), t); // one minute before expiry
    this.systemBus.emit(creds, 'credentials/updated');
  }

  private async checkProfile() {
    console.error('Check Profile');
    if (this.profileChecked) {
      return;
    }

    const cognitoUser = this.getCurrentUser();
    cognitoUser.getSession((err: any, _session: any) => {
      if (err) {
        console.error("UserParametersService: Couldn't retrieve the user");
      } else {
        this.profileChecked = true;
        cognitoUser.getUserAttributes((lerr: any, result: any) => {
          console.error('Got attributes', result);
          if (lerr) {
            console.error('UserParametersService: in checkProfile: ', lerr);
          } else {
            let found = false;
            for (let i = 0; i < result.length; i++) {
              if (result[i].getName() === 'custom:cognitoId') {
                found = true;

                let current = (<any>config.credentials).identityId;
                let val = result[i].getValue();
                console.log('Current cognitoID=' + current + ' saved=' + val);
                if (val !== current) {
                  this.updatePolicy();
                }
                break;
              }
            }
            if (!found) {
              this.updatePolicy();
            }
          }
        });
      }
    });
  }

  async updatePolicy() {
    console.error('Updateing policy');
    const that = this;
    try {
      let cognitoUser = that.getCurrentUser();
      cognitoUser.getSession(function (err: any, _session: any) {
        console.error('Got current session', _session);
        if (err) {
          console.error(
            "LonginComponent: updatePolicy: Couldn't retrieve the user",
            err
          );
        } else {
          let attributeList = [];
          const attr = {
            Name: 'custom:cognitoId',
            Value: (<any>config.credentials).identityId
          };
          let attribute = <ICognitoUserAttributeData>attr;
          attributeList.push(attribute);
          cognitoUser.updateAttributes(
            attributeList,
            function (lerr: any, _result: any) {
              if (lerr) {
                // eslint-disable-next-line max-len
                console.error(
                  'LonginComponent: updatePolicy:  cognitoUser.updateAttributes: Error updating user cognitoId attribute',
                  lerr
                );
                alert(lerr);
                return;
              }
              cognitoUser = that.getCurrentUser();
              cognitoUser.getSession(function (l2err: any, _l2session: any) {
                if (l2err) {
                  // eslint-disable-next-line max-len
                  console.error(
                    "LonginComponent: updatePolicy:  cognitoUser.updateAttributes: Couldn't retrieve the user session",
                    l2err
                  );
                } else {
                  attributeList = [];
                  const attr2 = {
                    Name: 'custom:cognitoId',
                    Value: (<any>config.credentials).identityId
                  };
                  attribute = attr2;
                  attributeList.push(attribute);
                  cognitoUser.updateAttributes(
                    attributeList,
                    function (l3err: any, _l2result: any) {
                      if (l3err) {
                        // eslint-disable-next-line max-len
                        console.error(
                          'LonginComponent: updatePolicy: 2 cognitoUser.updateAttributes: Error updating user cognitoId attribute',
                          l3err
                        );
                        alert(l3err);
                        return;
                      }
                      // eslint-disable-next-line max-len
                      that.callPolicyUpdate();
                    }
                  );
                }
              });
            }
          );
        }
      });
    } catch (err) {
      console.log('LonginComponent: updatePolicy: Error ', err);
    }
  }

  private callPolicyUpdate() {
    this.callFunctionWithID((idToken: any, _arg: any) => {
      const apigClient = apigClientFactory.newClient({
        invokeUrl: this.appConfig.apiInvokeUrl
      });
      const params = {};
      const body = {
        principal: this.getCognitoIdentityID()
      };
      const additionalParams = {
        headers: {
          Authorization: idToken
        }
      };
      apigClient
        .updatepolicyPost(params, body, additionalParams)
        .then(function (_result: any) {})
        .catch(function (result: any) {
          console.error('CognitoService: callPolicyUpdate: errr :', result);
        });
    }, null);
  }

  private callFunctionWithID(func: any, arg: any) {
    this.getIdToken(
      new (class {
        callback() {}
        callbackWithParam(idToken: any) {
          func(idToken, arg);
        }
      })()
    );
  }
}

@Injectable()
export class UserRegistrationService {
  constructor(@Inject(CognitoUtil) public cognitoUtil: CognitoUtil) {}

  register(user: RegistrationUser, callback: CognitoCallback): void {
    const attributeList = [];

    const dataEmail = {
      Name: 'email',
      Value: user.email
    };
    const dataNickname = {
      Name: 'nickname',
      Value: user.name
    };
    const dataCognitoId = {
      Name: 'custom:cognitoId',
      Value: 'none'
    };

    attributeList.push(new CognitoUserAttribute(dataEmail));
    attributeList.push(new CognitoUserAttribute(dataNickname));
    attributeList.push(new CognitoUserAttribute(dataCognitoId));
    this.cognitoUtil
      .getUserPool()
      .signUp(
        user.userID,
        user.password,
        attributeList,
        null,
        function (err: any, result: any) {
          if (err) {
            callback.cognitoCallback(err, null);
          } else {
            console.log(
              'UserRegistrationService: registered user is ' + result
            );
            callback.cognitoCallback(null, result);
          }
        }
      );
  }

  confirmRegistration(
    username: string,
    confirmationCode: string,
    callback: CognitoCallback
  ): void {
    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmRegistration(
      confirmationCode,
      true,
      function (err: any, result: any) {
        if (err) {
          callback.cognitoCallback(err.message, null);
        } else {
          callback.cognitoCallback(null, result);
        }
      }
    );
  }

  resendCode(username: string, callback: CognitoCallback): void {
    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.resendConfirmationCode(function (err: any, result: any) {
      if (err) {
        callback.cognitoCallback(err.message, null);
      } else {
        callback.cognitoCallback(null, result);
      }
    });
  }
}
@Injectable()
export class AwsUtil {
  public static firstLogin = false;
  public static runningInit = false;

  constructor(public cognitoUtil: CognitoUtil) {
    config.region = this.cognitoUtil.appConfig.region;
    config.correctClockSkew = true;
  }

  /**
   * This is the method that needs to be called in order to init the aws global creds
   */
  initAwsService(callback: Callback, isLoggedIn: boolean, idToken: string) {
    if (AwsUtil.runningInit === true) {
      // Need to make sure I don't get into an infinite loop here, so need to exit if this method is running already
      console.log(
        "AwsUtil: Aborting running initAwsService()...it's running already."
      );
      // instead of aborting here, it's best to put a timer
      if (callback != null) {
        callback.callback();
        callback.callbackWithParam(null);
      }
      return;
    }
    AwsUtil.runningInit = true;

    // First check if the user is authenticated already
    if (isLoggedIn) {
      this.setupAWS(isLoggedIn, callback, idToken);
    } else {
      if (callback != null) {
        callback.callbackWithParam(null);
      }
    }
  }

  /**
   * Sets up the AWS global params
   *
   * @param isLoggedIn
   * @param callback
   */
  setupAWS(isLoggedIn: boolean, callback: Callback, idToken: string): void {
    if (isLoggedIn) {
      this.addCognitoCredentials(idToken);
    }

    if (callback != null) {
      callback.callback();
      callback.callbackWithParam(null);
    }
  }

  addCognitoCredentials(idTokenJwt: string): void {
    // console.log('CognitoService: addCognitoCredentials', idTokenJwt);
    const url =
      'cognito-idp.' +
      this.cognitoUtil.appConfig.region.toLowerCase() +
      '.amazonaws.com/' +
      this.cognitoUtil.appConfig.userPoolId;
    const logins: any = [];
    logins[url] = idTokenJwt;
    const params = {
      IdentityPoolId: this.cognitoUtil.appConfig.identityPoolId /* required */,
      Logins: logins
      // , LoginId: CognitoUtil.userName
    };
    config.credentials = new CognitoIdentityCredentials(params);

    (<CognitoIdentityCredentials>config.credentials).get(function (err: any) {
      if (!err) {
        // console.error('Cognito: got creds');
        // var id = AWS.config.credentials.identityId;
        if (AwsUtil.firstLogin) {
          // save the login info to DDB
          // this.ddb.writeLogEntry("login");
          AwsUtil.firstLogin = false;
        }
      } else {
        console.error('CognitoSvc: addCognitoCredentials: Error ', err);
      }
    });
  }

  callFunctionWithID(func: any, arg: any) {
    this.cognitoUtil.getIdToken(
      new (class {
        callback() {}
        callbackWithParam(idToken: any) {
          func(idToken, arg);
        }
      })()
    );
  }
}

@Injectable()
export class UserLoginService {
  private cognitoUser: CognitoUser;
  public autoConnect: boolean;
  public autoConnectVideo = true;
  public anonNickName: string;
  private anon = false;
  private keyCode: string = null;
  private isAuthenticate = false;
  private onceAuthCallBacks: LoggedInCallback[] = [];
  private authData: CognitoAuthOptions;
  private auth: AmazonCognitoIdentity.CognitoAuth;
  // private session: any;
  constructor(
    public cognitoUtil: CognitoUtil,
    public systemBus: SystemBusService,
    private tr: TranslationService,
    private awsUtil: AwsUtil,
    private appConfig: ConfigService,
    public router: Router
  ) {
    config.region = this.cognitoUtil.appConfig.region;
    config.correctClockSkew = true;
  }

  private getAuthInstance() {
    this.authData = {
      ClientId: this.appConfig.appId,
      AppWebDomain: this.appConfig.oauthDomain,
      TokenScopesArray: [
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin'
      ],
      RedirectUriSignIn: this.appConfig.redirectUriSI,
      RedirectUriSignOut: this.appConfig.redirectUriSO,
      IdentityProvider: '',
      UserPoolId: this.appConfig.userPoolId,
      AdvancedSecurityDataCollectionFlag: true
    };

    this.auth = new CognitoAuth(this.authData);

    this.auth.userhandler = {
      onSuccess: (session: any) => {
        console.log('Signin success');
        this.signedIn(session);
      },
      onFailure: (error: string) => {
        console.error('Error: ' + error);
        this.onFailureMethod();
      }
    };

    this.auth.useCodeGrantFlow();
    // this.auth.parseCognitoWebResponse(this.router.url);
  }

  private signedIn(session: any) {
    // this.session = session;
    const userData = {
      Username: this.auth.getCurrentUser(),
      Pool: this.cognitoUtil.getUserPool()
    };

    this.cognitoUser = new CognitoUser(userData);
    this.cognitoUtil.currentUser = this.cognitoUser;
    const logins: any = {};

    logins[
      'cognito-idp.' +
        this.cognitoUtil.appConfig.region +
        '.amazonaws.com/' +
        this.cognitoUtil.appConfig.userPoolId
    ] = session.getIdToken().getJwtToken();

    // Add the User's Id Token to the Cognito credentials login map.
    config.credentials = new CognitoIdentityCredentials({
      IdentityPoolId: this.cognitoUtil.appConfig.identityPoolId,
      Logins: logins,
      LoginId: this.auth.getCurrentUser()
    });

    let key = session.getIdToken().payload.sub;
    // TODO add Prompt for PIN
    let pin = prompt('Please enter a pin code to protect your communications');
    key = pin + key + pin;
    (<CognitoIdentityCredentials>config.credentials)
      .getPromise()
      .then(() => {
        this.authenticated(key);
        this.router.navigate(['/eqCallHome'], { replaceUrl: true });
      })
      .catch((err: any) => {
        console.error(err);
        (<CognitoIdentityCredentials>config.credentials).clearCachedId();
      });
    console.log(config.credentials);
  }

  private onFailureMethod() {
    console.warn('Failed');
    //  this.session = undefined;

    this.router.navigate(['/home/login'], { replaceUrl: true });
  }

  public CognitoUIlogin() {
    console.log('Login');
    if (!this.auth) {
      this.getAuthInstance();
    }
    this.auth.getSession();
  }

  public processResp() {
    console.log('Process Response ' + location.href);
    if (!this.auth) {
      this.getAuthInstance();
    }
    this.auth.parseCognitoWebResponse(location.href);
  }

  async authenticate(username: string, password: string) {
    const that = this;
    // Need to provide placeholder keys unless unauthorised user access is enabled for user pool
    // AWSCognito.config.update({accessKeyId: 'anything', secretAccessKey: 'anything'});

    const authenticationData = {
      Username: username,
      Password: password
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    this.cognitoUser = new CognitoUser(userData);
    this.cognitoUtil.currentUser = this.cognitoUser;

    return new Promise<void>((resolve, reject) => {
      this.cognitoUser.authenticateUser(authenticationDetails, <
        IAuthenticationCallback
      >{
        onSuccess: (result: any) => {
          console.log('CognitoService: authenticate:', result);
          const logins: any = {};
          logins[
            'cognito-idp.' +
              this.cognitoUtil.appConfig.region +
              '.amazonaws.com/' +
              this.cognitoUtil.appConfig.userPoolId
          ] = result.getIdToken().getJwtToken();

          // Add the User's Id Token to the Cognito credentials login map.
          config.credentials = new CognitoIdentityCredentials({
            IdentityPoolId: this.cognitoUtil.appConfig.identityPoolId,
            Logins: logins,
            LoginId: username
          });

          (<CognitoIdentityCredentials>config.credentials)
            .getPromise()
            .then(() => {
              that.authenticated(password);
              resolve();
            })
            .catch((err: any) => {
              (<CognitoIdentityCredentials>config.credentials).clearCachedId();
              reject(err);
            });
        },
        onFailure: (err: any) => {
          reject(err);
        },
        mfaSetup: function (challengeName: any, challengeParameters: any) {
          console.log('MFA SETUP', challengeName, challengeParameters);
          this.cognitoUser.associateSoftwareToken(this);
        },

        associateSecretCode: (secretCode: any) => {
          console.log('SECRET CODE: ', secretCode);
          // await this.setState({ QRCode: secretCode, showQRCode: true });
          setTimeout(() => {
            const challengeAnswer = prompt('Please input the TOTP code.', '');
            this.cognitoUser.verifySoftwareToken(
              challengeAnswer,
              'My TOTP device',
              {
                onSuccess: (session: any) =>
                  console.log('SUCCESS TOTP: ', session),
                onFailure: (err: any) => console.error('ERROR TOTP: ', err)
              }
            );
          }, 2000);
        },

        selectMFAType: function (challengeName: any, challengeParameters: any) {
          // valid values for mfaType is "SMS_MFA", "SOFTWARE_TOKEN_MFA"
          console.log(challengeName, challengeParameters);
          let mfaType = prompt('Please select the MFA method.', '');
          this.cognitoUser.sendMFASelectionAnswer(mfaType, this);
        },

        totpRequired: function (secretCode: any) {
          let challengeAnswer = prompt('Please input the TOTP code.', '');
          this.cognitoUser.sendMFACode(
            challengeAnswer,
            this,
            'SOFTWARE_TOKEN_MFA'
          );
        },

        mfaRequired: function (codeDeliveryDetails: any) {
          let verificationCode = prompt('Please input verification code', '');
          this.cognitoUser.sendMFACode(verificationCode, this);
        },
        newPasswordRequired: (userAttributes: any) => {
          // this.setState({
          //     user: userAttributes,
          //      showNewPassword: true
          // });
        }
      });
    });
  }

  public getCurrentUser(): CognitoUser {
    return this.cognitoUtil.getCurrentUser();
  }

  public async anonAuthenticate() {
    console.log('Anon login');
    config.region = this.cognitoUtil.appConfig.region;

    config.credentials = new CognitoIdentityCredentials({
      IdentityPoolId: this.cognitoUtil.appConfig.identityPoolId
    });

    (<CognitoIdentityCredentials>config.credentials).get((err: any) => {
      if (err) {
        console.error(err);
        throw new Error(err.message);
      } else {
        this.cognitoUtil.getCurrentUser();
        this.anon = true;
        this.authenticated(null);
      }
    });
  }

  public deleteAccount() {
    this.callFunctionWithID((idToken: any, _arg: any) => {
      const apigClient = apigClientFactory.newClient({
        invokeUrl: this.cognitoUtil.appConfig.apiInvokeUrl
      });
      const params = {};
      const body = {};
      const additionalParams = {
        headers: {
          Authorization: idToken
        }
      };
      apigClient
        .currentuserDelete(params, body, additionalParams)
        .then((_result: any) => {
          alert('Account Deleted');
          this.logout(true);
        })
        .catch(function (result: any) {
          console.error(
            'Error destroying self, error = ' + JSON.stringify(result)
          );
        });
    }, null);
  }

  private callFunctionWithID(func: any, arg: any) {
    this.cognitoUtil.getIdToken(
      new (class {
        callback() {}
        callbackWithParam(idToken: any) {
          func(idToken, arg);
        }
      })()
    );
  }

  public isAnonLogin(): boolean {
    return this.anon;
  }

  public setKeyCode(keyCode: string) {
    if (keyCode == null) {
      this.keyCode = null;
    } else {
      this.keyCode = keyCode;
      this.systemBus.emit(this.keyCode, 'keyCodeSet');
    }
  }

  public getKeyCode(): string {
    return this.keyCode;
  }

  public async updateUserEmail(newEmail: string) {
    console.log(newEmail);

    const currentEmail = await this.getUserAttribute('email');
    if (newEmail === currentEmail) {
      console.log('New Email same as the old');
      return 'NOCHANGE';
    }
    const that = this;

    const p = new Promise((res, rej) => {
      // we create an array for our attributes that we want to update, and push all `CognitoUserAttribute` objects into it
      const attributeList: CognitoUserAttribute[] = [];

      let attribute = {
        Name: 'email',
        Value: newEmail
      };
      // convert into `CognitoUserAttribute` object
      let x = new CognitoUserAttribute(attribute);
      // add it to the `attributeList` array
      attributeList.push(x);

      console.log(attributeList);
      // instantiate the `cognitoUser` from our userPool (we can do this because
      // the user is already signed in if they are attempting to change their attributes)
      const cognitoUser = this.getCurrentUser();
      // get the latest cognito session so that we can `updateAttributes()`
      cognitoUser.getSession((err: any, result: any) => {
        if (result) {
          // if we successfully got the latest session, we can `updateAttributes()`
          // from 'cognitoUser', passing in the `attributeList` array
          cognitoUser.updateAttributes(
            attributeList,
            (err2: any, result2: any) => {
              // reject promise if the update attempt failed
              if (err2) {
                console.log('Error updateing email address', err2);
                rej(err2);
                return;
              }
              console.log('Update email', result2);
              cognitoUser.getAttributeVerificationCode('email', {
                onSuccess: function () {
                  console.log('call result: ');
                  res(null);
                },
                onFailure: function (err3: any) {
                  let message = that.tr.get('cognito', err3.name);
                  if (!message) {
                    message = err3.message;
                    console.error(err3);
                  }

                  let warning = {
                    type: 'warning',
                    message: message,
                    timeOut: 5
                  };
                  that.systemBus.emit(warning, 'warning');
                  // reset back to old email
                  attributeList[0].Value = currentEmail;
                  cognitoUser.updateAttributes(
                    attributeList,
                    (err4: any, _result4: any) => {
                      if (err4) {
                        console.error(
                          'Error resetting back to old email address',
                          err4
                        );
                      } else {
                        console.error('Email reset back to origonal ');
                      }
                      rej('NOCHANGE');
                    }
                  );
                },
                inputVerificationCode: function () {
                  let resp = (verificationCode: string) => {
                    if (!verificationCode || verificationCode.length < 1) {
                      this.onFailure({ message: 'Cancelled' });
                      return;
                    }
                    cognitoUser.verifyAttribute('email', verificationCode, {
                      onSuccess: this.onSuccess,
                      onFailure: (err5: any) => {
                        let message = that.tr.get('cognito', err5.name);
                        if (!message) {
                          message = err5.message;
                          console.error(err5);
                        }

                        let warning = {
                          type: 'warning',
                          message: message,
                          timeOut: 3
                        };
                        that.systemBus.emit(warning, 'warning');
                        this.inputVerificationCode(); // try again
                      }
                    });
                  };
                  const request = new InputRequest();
                  request.message = that.tr.get('cognito', 'request.message');
                  request.title = that.tr.get('cognito', 'request.title');
                  request.buttonTxt = that.tr.get(
                    'cognito',
                    'request.buttonTxt'
                  );
                  request.resolve = resp;
                  that.systemBus.emit(request, 'inputRequest');
                }
              });
            }
          );
        } else {
          console.log('error getting user session ', err);
          rej(err);
        }
      });
    });
    return p;
  }

  private async getUserAttributes(): Promise<CognitoUserAttribute[]> {
    return new Promise((res, rej) => {
      this.cognitoUser.getUserAttributes((err: any, result: any) => {
        // reject promise if failed
        if (err) {
          console.log(err);
          rej(err);
          return;
        }
        // if success, then ` again and resolve the promise with `userProfileObject`
        console.log('Success!', result);
        res(result);
      });
    });
  }

  private async getUserAttribute(attribute: string): Promise<string> {
    let attrs = await this.getUserAttributes();
    for (let attr of attrs) {
      if (attr.Name === attribute) {
        return attr.Value;
      }
    }
    return null;
  }

  public forgotPassword(username: string, callback: CognitoCallback) {
    const userData = {
      Username: username,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.forgotPassword({
      onSuccess: function (_result: any) {},
      onFailure: function (err: any) {
        callback.cognitoCallback(err.message, null);
      },
      inputVerificationCode() {
        callback.cognitoCallback(null, null);
      }
    });
  }

  public confirmNewPassword(
    email: string,
    verificationCode: string,
    password: string,
    callback: CognitoCallback
  ) {
    const userData = {
      Username: email,
      Pool: this.cognitoUtil.getUserPool()
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: function () {
        callback.cognitoCallback(null, null);
      },
      onFailure: function (err: any) {
        callback.cognitoCallback(err.message, null);
      }
    });
  }

  public async changePassword(oldpw: string, newpw: string) {
    if (!this.getCurrentUser()) {
      throw new Error('Not supported for not cognito users');
    }

    return new Promise((resolve, reject) => {
      this.getCurrentUser().changePassword(
        oldpw,
        newpw,
        function (err: any, result: any) {
          if (err) {
            reject(err);
          } else {
            this.cryptoSvc.setPwd(newpw);
            resolve(result);
          }
        }
      );
    });
  }

  public async logout(reload: boolean) {
    this.appConfig.removeItem('pf');
    setTimeout(() => {
      this.isAuthenticate = false;
      if (this.cognitoUser) {
        this.cognitoUser.globalSignOut({
          onSuccess: (_result: string) => {
            //  console.error(result);
            if (config.credentials) {
              console.log('Cognito: logout: clearning config.credentials');
              (<CognitoIdentityCredentials>config.credentials).clearCachedId();
            }
            if (reload) {
              location.reload();
            }
          },
          onFailure: (error: Error) => {
            alert(error);
            if (config.credentials) {
              console.log('Cognito: logout: clearning config.credentials');
              (<CognitoIdentityCredentials>config.credentials).clearCachedId();
            }
            if (reload) {
              location.reload();
            }
          }
        });
      } else {
        console.error('Cognito: signOut: ciognitoUser is not set');
        if (this.auth) {
          this.auth.signOut();
        } else {
          console.error('Cognito: signOut: auth is not set');
        }
        if (config.credentials) {
          console.log('Cognito: logout: clearning config.credentials');
          (<CognitoIdentityCredentials>config.credentials).clearCachedId();
        }
        if (reload) {
          location.reload();
        }
      }

      this.systemBus.emit('User logged out', 'user/loggedOut');
    }, 250);
  }

  async authenticated(passwd: string) {
    console.log('Cognito: authenticated');
    await this.cognitoUtil.refresh(false);
    this.isAuthenticate = true;
    const id = this.cognitoUtil.getCredentials().identityId;
    if (!id) {
      console.error(
        'CognitoSvc: authenticated: ID is NOT defined',
        this,
        new Error()
      );
      setTimeout(() => {
        this.authenticated(passwd);
      }, 2000);
      return;
    } else {
      this.appConfig.setID(id);
    }
    let cb = this.onceAuthCallBacks;
    if (cb == null) {
      return;
    }
    this.onceAuthCallBacks = null;
    cb.forEach((callback) => {
      callback.isLoggedIn(null, true);
    });
    cb.splice(0, cb.length);
    this.systemBus.emit(passwd, 'user/loggedIn');
  }

  public checkSavedAuthenticated(callback: LoggedInCallback) {
    if (this.anon && config.credentials.accessKeyId) {
      //   console.error('ChecSaved 1');
      callback.isLoggedIn(null, false);
      return;
    }
    const that = this;
    if (callback == null) {
      throw new Error(
        'UserLoginService: Callback in isAuthenticated() cannot be null'
      );
    }
    this.cognitoUser = this.cognitoUtil.getCurrentUser();

    if (this.cognitoUser != null) {
      //    console.error('ChecSaved 2');
      this.cognitoUser.getSession(function (err: any, session: any) {
        if (err) {
          console.error("UserLoginService: Couldn't get the session: ", err);
          callback.isLoggedIn(err, false);
        } else {
          //  console.error('ChecSaved 3');
          if (session.isValid()) {
            //   console.error('ChecSaved 4');
            if (!config.credentials || !config.credentials.accessKeyId) {
              //    console.error('ChecSaved 5');
              that.cognitoUtil.getIdToken({
                callback() {
                  //        console.error('ChecSaved 6');
                },
                callbackWithParam(token: any) {
                  that.awsUtil.initAwsService(
                    {
                      callback() {
                        //              console.error('ChecSaved 7');
                        callback.isLoggedIn(null, true);
                      },
                      async callbackWithParam(_token2: any) {
                        //              console.error('ChecSaved 8');
                        callback.isLoggedIn(null, true);
                      }
                    },
                    true,
                    token
                  );
                }
              });
            } else {
              //       console.error('ChecSaved 9');
              if (
                (<CognitoIdentityCredentials>config.credentials).needsRefresh()
              ) {
                //          console.error('ChecSaved 10');
                console.warn(
                  'CognitoService: checkSavedAuthenticated: Credentials expired'
                );
                (<CognitoIdentityCredentials>config.credentials).refresh(
                  (erre: any) => {
                    console.error(
                      'CognitoService: checkSavedAuthenticated: credential refresh failed',
                      erre
                    );
                    that.logout(true);
                    callback.isLoggedIn(
                      "Can't retrieve the CurrentUser",
                      false
                    );
                  }
                );
              }
            }
            // that.systemBus.emit(null, 'user/loggedIn');
          } else {
            console.error('Cognito: isAuthenticated: session is not valid');
            callback.isLoggedIn(err, session.isValid());
          }
        }
      });
    } else {
      console.log("UserLoginService: can't retrieve the current user");
      callback.isLoggedIn("Can't retrieve the CurrentUser", false);
    }
  }

  /**
   * Add to array that will be called once we are logged in;
   */

  public onceAuthenticated(callback: LoggedInCallback) {
    if (!this.isAuthenticate) {
      if (this.onceAuthCallBacks == null) {
        this.onceAuthCallBacks = [];
      }
      this.onceAuthCallBacks.push(callback);
    } else {
      callback.isLoggedIn(null, true);
    }
  }
}
