<div
  [attr.id]="fid"
  style="
    border: 0px solid rgb(3, 6, 7);
    border-right: none;
    padding-right: 0px;
    min-height: 0px;
    width: 100%;
    background-color: #2c3e50;
  "
  [ngStyle]="{ height: stripView ? 'none' : '100%' }"
>
  <div
    *ngIf="showControls"
    class="content d-none d-md-block"
    style="
      background-color: #2c3e50;
      color: #bdc3c7;
      text-align: center;
      border: 0px solid #2980b9;
      margin-bottom: -24px;
    "
    [ngStyle]="{
      position: fullscreen ? 'relative' : 'relative',
      'z-index': fullscreen ? '1' : '1',
      width: fullscreen ? '100%' : '100%',
      left: fullscreen ? '0vw' : '0vw',
      top: fullscreen ? '0vh' : 'auto',
      border: fullscreen ? 'none' : '0px solid #2980b9',
      background: fullscreen
        ? ' linear-gradient(rgba(79, 82, 84, 0.4) 0%, rgb(36, 44, 51, 0.4) 100%)'
        : 'linear-gradient(rgba(79, 82, 84, 0.7) 0%, rgb(36, 44, 51, 0.7) 100%)'
    }"
  >
    <i
      [style.color]="colour"
      class="fa fa-signal"
      style="position: absolute; left: 13px; top: 3px"
    ></i
    >{{ nickName }}&nbsp;{{ state
    }}<i
      [ngClass]="{ riTalking: talking, riNotTalking: !talking }"
      class="fa fa-volume-up"
    ></i>
  </div>
  <div *ngIf="showControls" class="dropdown-rtc dropleft">
    <button
      class="btn-rtc btn-outline-light btn-sm rtc-right-menu"
      data-bs-toggle="modal"
      [attr.data-bs-target]="'#' + fid + 'ParticipantSettingsModal'"
      title="Participant Menu"
      style="box-shadow: none; outline: none; border: none"
    >
      <i class="fa fa-ellipsis-v" style="width: 12px; color: #fff"></i>
    </button>
  </div>
  <div
    class="modal fade"
    [attr.id]="fid + 'ParticipantSettingsModal'"
    data-bs-backdrop="false"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    data-bs-toggle="modal"
    [attr.data-bs-target]="'#' + fid + 'ParticipantSettingsModal'"
  >
    <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
      <div class="modal-content">
        <div
          class="modal-header"
          style="background-color: rgba(44, 62, 80, 0.77)"
        >
          <h5 class="modal-title ml-5">Settings: {{ nickName}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="No"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ta-left" style="background-color: transparent">
          <div class="row no-gutters">
            <div class="col-sm">
              <div class="container-fluid">
                <button
                  (click)="pin()"
                  class="btn-rtc btn-primary dropdown-item"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  [title]="tr.get('rtc-connection', 'pin')"
                  style="position: relative"
                  type="button"
                  [ngClass]="{
                    'btn-primary active': pinned,
                    'btn-default': !pinned
                  }"
                  data-bs-dismiss="modal"
                  aria-label="No"
                >
                  <i
                    class="fa fa-sliders mr-3"
                    style="width: 12px; color: #ecf0f1"
                  ></i
                  >Pin Video
                </button>
                <button
                  (click)="toggleVisualizer()"
                  class="btn-rtc btn-primary dropdown-item"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  [title]="tr.get('rtc-connection', 'audio.ct')"
                  style="position: relative"
                  type="button"
                  [ngClass]="{
                    'btn-primary active': showEQcontrols,
                    'btn-default': !showEQcontrols
                  }"
                  data-bs-dismiss="modal"
                  aria-label="No"
                >
                  <i
                    class="fa fa-sliders mr-3"
                    style="width: 12px; color: #ecf0f1"
                  ></i
                  >Audio Controls
                </button>
                <button
                  (click)="toggleVideoMute()"
                  type="button"
                  class="btn-rtc btn-primary dropdown-item"
                  [title]="tr.get('rtc-connection', 'video.ct')"
                  [ngClass]="{
                    'btn-danger active': muteVideo,
                    'btn-default': !muteVideo
                  }"
                  data-bs-dismiss="modal"
                  aria-label="No"
                >
                  <i
                    class="fa fa-video mr-3"
                    style="width: 12px; color: #ecf0f1"
                    [ngClass]="{
                      'fa-eye-slash': muteVideo,
                      'fa-video': !muteVideo
                    }"
                  ></i
                  >Camera Off
                </button>
                <button
                  (click)="toggleMute()"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  [title]="tr.get('rtc-connection', 'mutet')"
                  type="button"
                  class="btn-rtc btn-primary dropdown-item"
                  [ngClass]="{
                    'btn-danger active': muteAudio,
                    'btn-default': !muteAudio
                  }"
                  data-bs-dismiss="modal"
                  aria-label="No"
                >
                  <i
                    class="fa mr-3"
                    style="width: 12px; color: #ecf0f1"
                    [ngClass]="{
                      'fa-volume-off': muteAudio,
                      'fa-volume-up': !muteAudio
                    }"
                  ></i
                  >Mute Microphone
                </button>
                <button
                  (click)="toggleOnHold()"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  [title]="tr.get('rtc-connection', 'holdt')"
                  type="button"
                  class="btn-rtc btn-primary dropdown-item"
                  [ngClass]="{
                    'btn-danger active': onHold,
                    'btn-outline-primary': !onHold
                  }"
                  data-bs-dismiss="modal"
                  aria-label="No"
                >
                  <i
                    class="fa mr-3"
                    style="width: 12px; color: #ecf0f1"
                    [ngClass]="{ 'fa-play': onHold, 'fa-pause': !onHold }"
                  ></i
                  >Place on Hold
                </button>
                <button
                  (click)="toggleFullScreen()"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  [title]="tr.get('rtc-connection', 'f.screent')"
                  type="button"
                  class="btn-rtc btn-primary dropdown-item dropdown-item"
                  [ngClass]="{
                    'btn-primary active': fullscreen,
                    'btn-default': !fullscreen
                  }"
                  data-bs-dismiss="modal"
                  aria-label="No"
                >
                  <i
                    class="fa fa-arrows-alt mr-3"
                    style="width: 12px; color: #ecf0f1"
                  ></i
                  >Toggle Fullscreen
                </button>
                <button
                  (click)="toggleEQBars()"
                  type="button"
                  class="btn-rtc btn-primary dropdown-item"
                  [title]="tr.get('rtc-connection', 'video.ct')"
                  [ngClass]="{
                    'btn-primary active': showEQBars,
                    'btn-primary': !showEQBars
                  }"
                  data-bs-dismiss="modal"
                  aria-label="No"
                >
                  <i
                    class="fa fa-bar-chart mr-3"
                    style="width: 12px; color: #ecf0f1"
                  ></i
                  >Toggle EQ Visualiser
                </button>
                <div class="dropdown-divider"></div>
                <button
                  type="button"
                  class="btn-rtc btn-primary dropdown-item"
                  (click)="stopRtc()"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  [title]="tr.get('rtc-connection', 'endt')"
                  data-bs-dismiss="modal"
                  aria-label="No"
                >
                  <i
                    class="fa fa-eject mr-3"
                    style="width: 12px; color: #e74c3c"
                  ></i
                  >End Call
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    data-bs-toggle="modal"
    [attr.data-bs-target]="'#' + fid + 'ParticipantSettingsModal'"
    [ngStyle]="{
      'pointer-events':
        rtcConnections.length === 2 || rtcConnections.length > 2
          ? 'auto'
          : 'none'
    }"
  >
    <video
      playsinline
      autoplay
      poster=""
      (click)="showBtnsToggle()"
      #vid
      [attr.id]="videoOutput"
      style="position: absolute; height: 100%; width: 100%; top: 0; left: 0"
      [ngClass]="{
        'video-display-none': !videoPlaying,
        'video-display': videoPlaying
      }"
    ></video>
    <!-- Overlay Toasts-->
    <div
      class="support"
      [ngStyle]="{
        display:
          onHold || !videoPlaying || !showVideo || muteVideo || !showControls
            ? 'none'
            : 'block'
      }"
    >
      <div class="timer">{{ runTimer | timer }}</div>
      <a *ngIf="muteAudio" style="color: #e74c3c"
        ><i class="material-icons">mic_off</i></a
      ><br *ngIf="muteAudio" />
      <a *ngIf="remoteAudioMuted && !muteAudio"
        ><i class="material-icons">mic_off</i></a
      ><br *ngIf="remoteAudioMuted && !muteAudio" />
    </div>
    <!-- Change to Voice Only View Overlay -->
    <section
      *ngIf="!videoPlaying || !showVideo || muteVideo"
      class="video-container-voice"
      [ngStyle]="{ display: onHold ? 'none' : 'block' }"
    >
      <img src="../assets/img/home-inner.jpg" class="bg" alt="" />
      <div *ngIf="!onHold" class="callout">
        <h1 *ngIf="state === 'connecting'">
          {{ nickName }}
        </h1>
        <!-- <h1 *ngIf="state === ''">Voice Call</h1> -->
        <div>
          <div *ngIf="state === ''">
            <div class="d-flex justify-content-center">
              <div *ngIf="!muteAudio">
                <div *ngIf="!remoteAudioMuted" class="p-2">
                  <i class="material-icons">mic</i>
                </div>
              </div>
              <div *ngIf="!muteAudio">
                <div *ngIf="remoteAudioMuted" class="p-2">
                  <i class="material-icons">mic_off</i>
                </div>
              </div>
              <div class="p-2" *ngIf="muteAudio">
                <i class="material-icons" style="color: #e74c3c">mic_off</i>
              </div>
              <div *ngIf="!muteVideo">
                <div class="p-2" *ngIf="!videoPlaying || !showVideo">
                  <i class="material-icons">videocam_off</i>
                </div>
              </div>
              <div class="p-2" *ngIf="muteVideo">
                <i class="material-icons" style="color: #e74c3c"
                  >videocam_off</i
                >
              </div>
            </div>
            <div class="d-none d-sm-block">
              <div class="avatar avatar--default default--two"></div>
              <div *ngIf="!talking" class="bot"></div>
              <div *ngIf="talking" class="bot speaking"></div>
            </div>
          </div>
          <span *ngIf="state === 'connecting'"
            >Calling <span class="d">&nbsp;.</span><span class="d d-2">.</span
            ><span class="d d-3">.</span>
          </span>
        </div>
      </div>
      <div *ngIf="state === ''" class="support">
        <div class="timer">{{ runTimer | timer }}</div>
        <a *ngIf="muteVideo" style="color: #e74c3c"
          >You turned User Camera off</a
        ><br *ngIf="muteVideo" />
        <a *ngIf="muteAudio" style="color: #e74c3c"
          >You turned User Microphone off</a
        ><br *ngIf="muteAudio" />
        <a *ngIf="remoteAudioMuted">User turned Microphone off</a
        ><br *ngIf="remoteAudioMuted" />
        <a *ngIf="!videoPlaying">User turned Camera off</a><br />
      </div>
    </section>
    <!-- Change to On Hold View Overlay -->
    <section *ngIf="onHold" class="video-container-voice">
      <img src="../assets/img/home-inner.jpg" class="bg" alt="" />
      <div class="callout">
        <h1 *ngIf="state === 'connecting'">
          {{ nickName }}
        </h1>
        <!-- <h1 *ngIf="state === ''">On Hold</h1> -->
        <div>
          <div *ngIf="state === ''">
            <div class="d-flex justify-content-center">
              <div>
                <div class="p-2">
                  <i class="material-icons">mic_off</i>
                </div>
              </div>
              <div>
                <div class="p-2">
                  <i class="material-icons">videocam_off</i>
                </div>
              </div>
            </div>
            <div class="d-none d-sm-block">
              <div class="avatar avatar--default default--two--off">
                <i
                  class="material-icons"
                  style="font-size: 100px; margin-left: -1px; margin-top: 2px"
                  >pause_circle_outline</i
                >
              </div>
              <div class="bot-off"></div>
            </div>
          </div>
          <span *ngIf="state === 'connecting'"
            >Calling <span class="d">&nbsp;.</span><span class="d d-2">.</span
            ><span class="d d-3">.</span></span
          >
        </div>
      </div>
      <div *ngIf="state === ''" class="support">
        <div class="timer">On Hold {{ runTimer | timer }}</div>

        <a>User can still communicate with others</a><br />
      </div>
    </section>
    <div
      [ngStyle]="{ display: remoteAudioMuted || onHold ? 'none' : 'inline' }"
    >
      <canvas
        class="visualiser-showall"
        (click)="showBtnsToggle()"
        *ngIf="showEQBars && !showvisualizer"
        [attr.id]="visualizer"
      ></canvas>
    </div>
  </div>
  <div
    *ngIf="showvisualizer"
    style="
      position: fixed;
      z-index: 1050;
      background: linear-gradient(
        rgba(79, 82, 84, 0.7) 0%,
        rgba(36, 44, 51, 0.7) 100%
      );
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    "
  >
    <div
      *ngIf="showvisualizer"
      class="modal-dialog modal-dialog-centered eqmodal"
      role="document"
    >
      <div class="modal-content">
        <div
          class="modal-header"
          style="background-color: rgba(44, 62, 80, 0.77)"
        >
          <h5 class="modal-title ml-5">{{ nickName }}: Audio Controls</h5>
          <button
            (click)="toggleVisualizer()"
            type="button"
            class="btn-close"
            aria-label="No"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body ta-left" style="background-color: transparent">
          <div class="row no-gutters">
            <div class="col-sm">
              <div class="container-fluid">
                <div class="sliders" *ngIf="showEQcontrols">
                  <div>
                    <button
                      class="btn-rtc btn-outline-light btn-sm info-modal"
                      data-bs-toggle="modal"
                      data-bs-target="#infoacModal"
                    >
                      <i class="material-icons">info_outline</i>
                    </button>
                    <div class="row">
                      <div class="col-sm">
                        <span
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          [title]="tr.get('rtc-connection', 'vol.ct')"
                        >
                          <small>{{ tr.get('rtc-connection', 'vol') }}</small>
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm">
                        <input
                          [attr.id]="volumeSlider"
                          type="text"
                          data-slider-min="0"
                          data-slider-max="200"
                          data-slider-step="1"
                          data-slider-value="100"
                          data-slider-tooltip="hide"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm">
                        <span
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          [title]="tr.get('rtc-connection', 'silt')"
                        >
                          <small>{{ tr.get('rtc-connection', 'sil') }}</small>
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm">
                        <input
                          [attr.id]="noiseGateSlider"
                          type="text"
                          data-slider-min="0"
                          data-slider-max="200"
                          data-slider-step="1"
                          data-slider-value="0"
                          data-slider-tooltip="hide"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm">
                        <span
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          [title]="tr.get('rtc-connection', 'eqt')"
                        >
                          <small>{{ tr.get('rtc-connection', 'eq') }}</small>
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm">
                        <input
                          [attr.id]="eqPresenceSlider"
                          type="text"
                          data-slider-min="-30"
                          data-slider-max="+30"
                          data-slider-step="1"
                          data-slider-value="0"
                          data-slider-tooltip="hide"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm" *ngIf="!showEQBars">
                <canvas
                  class="visualiser-showmodal"
                  (click)="showBtnsToggle()"
                  *ngIf="showvisualizer && !showEQBars"
                  [attr.id]="visualizer"
                >
                </canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
