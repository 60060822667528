import { AbstractMessenger, MessageService } from './message.service';
import { AudioService } from './audio.service';
import {
  CognitoUtil,
  LoggedInCallback,
  UserLoginService
} from './cognito.service';
import { ConfigService } from './config.service';
import { EqcallapiService } from './eqcallapi.service';
import { Injectable } from '@angular/core';
import { Key, KeyService } from './key.service';
import { LivekitService } from './livekit.service';
import { MessageObserver, SystemBusService } from './system-bus.service';
import { NetworkService, Socket } from './network.service';
import { Router } from '@angular/router';
import { RtcConnection, RtcListener } from './rtc.service';

export class ContactList implements MessageObserver {
  private allContacts: Contact[] = [];
  private onlineContacts: Contact[] = [];
  private offlineContacts: Contact[] = [];
  private blockedContacts: Contact[] = [];
  private sortedAZContacts: Contact[] = [];
  private sortedActivContacts: Contact[] = [];
  private lists: Contact[][] = new Array<Contact[]>();
  private allLists: Contact[][] = new Array<Contact[]>();
  private groupContacts: Contact[] = [];
  public display = 5;

  constructor(private busSvc: SystemBusService, private config: ConfigService) {
    this.lists.push(this.onlineContacts);
    this.lists.push(this.offlineContacts);
    this.lists.push(this.blockedContacts);
    this.allLists.push(this.onlineContacts);
    this.allLists.push(this.offlineContacts);
    this.allLists.push(this.blockedContacts);
    this.allLists.push(this.sortedAZContacts);
    this.allLists.push(this.sortedActivContacts);
    this.allLists.push(this.groupContacts);
    this.allLists.push(this.allContacts);
    this.busSvc.subscribe(this);
    let ds = this.config.getItem('ContactsDisplay');
    if (ds) {
      this.display = parseInt(ds, 10);
    }
  }

  onBusMessage(message: any, type: string): void {
    console.log('ContactList: onBusMessage', message, type);
    if (type === 'contactDisplay') {
      this.display = message;
      this.config.setItem('ContactsDisplay', this.display.toString());
    } else if (type === 'notice/contactDeleted') {
      let contact = this.getContactByAddress(message);
      if (contact) {
        this.removeContact(contact);
        contact.destroy();
      } else {
        console.error('Did not find contact', this.allContacts);
      }
    } else {
      let addr: string;
      if (type === 'rtc/connection/request') {
        addr = message.destID;
      } else if (type === 'newChatMessage') {
        let contact: Contact = message;
        addr = contact.destAddress;
      }
      if (addr) {
        this.config.setItem('lastActivity-' + addr, Date.now().toString());
      } else {
        console.error('No address??');
      }
      setTimeout(() => {
        this.updateSortedLists();
      }, 1000);
    }
  }

  busMessageFilter(messageType: string): boolean {
    return (
      messageType === 'newChatMessage' ||
      messageType === 'rtc/connection/request' ||
      messageType === 'notice/contactDeleted' ||
      messageType === 'contactDisplay'
    );
  }

  public addGroupContact(contact: Contact) {
    if (this.isNewContact(contact)) {
      this.groupContacts.push(contact);
    }
  }

  public addContact(contact: Contact) {
    if (this.isNewContact(contact)) {
      this.allContacts.push(contact);
      this.sortByNickname(this.allContacts);
      this.sortedAZContacts.push(contact);
      this.sortedActivContacts.push(contact);
    } else {
      console.error('readding same contact', contact);
    }
    this.updateContact(contact.destAddress, contact.state[0]);
  }

  private isNewContact(contact: Contact) {
    for (let c of this.allContacts) {
      if (c.destAddress === contact.destAddress) {
        return false;
      }
    }
    return true;
  }

  public updateContact(address: string, state: string): Contact {
    let contact = this.getContactByAddress(address);
    if (contact) {
      contact.changeState(state);
      if (state === 'disconnected' && contact.keyCode && contact.isAnon) {
        // keyCode user went offline, remove user
        setTimeout(() => {
          this.removeContact(contact);
        }, 2000);
      }

      if (contact.isBlocked) {
        this.moveToList(this.blockedContacts, contact);
      } else {
        const rstate = contact.state[0];
        if (rstate === 'connected') {
          this.moveToList(this.onlineContacts, contact);
        } else {
          this.moveToList(this.offlineContacts, contact);
        }
      }
      this.updateSortedLists();
    } else {
      console.error(
        'Trying to update non existing contact ' + address,
        state,
        new Error()
      );
    }
    return contact;
  }

  private getContactByAddress(address: string): Contact {
    let contact: Contact = null;
    for (let ct of this.allContacts) {
      if (ct.destAddress === address) {
        contact = ct;
        break;
      }
    }
    return contact;
  }

  /**
   *
   * @param list
   * @param contact
   *
   *  Move contact to list and remove from other lists,
   * lists are online, offline and blocked only, does not touch sorted lists
   */
  private moveToList(list: Contact[], contact: Contact) {
    this.lists.forEach((l: Contact[]) => {
      if (l === list) {
        let idx = l.indexOf(contact);
        if (idx === -1) {
          l.push(contact);
          this.sortByNickname(l);
        }
      } else {
        let idx = l.indexOf(contact);
        if (idx !== -1) {
          l.splice(idx, 1);
        }
      }
    });
  }

  public updateSortedLists() {
    this.sortByNickname(this.sortedAZContacts);
    this.sortByActivity(this.sortedActivContacts);
  }

  private sortByNickname(array: Contact[]) {
    array.sort((a: Contact, b: Contact) => {
      this.checknickname(a);
      this.checknickname(b);
      let an = a.nickname.toLocaleLowerCase();
      let bn = b.nickname.toLocaleLowerCase();
      if (an < bn) {
        return -1;
      }
      if (an > bn) {
        return 1;
      }
      return 0;
    });
  }

  private checknickname(contact: Contact) {
    if (!contact.nickname) {
      console.error('invalid nickname', contact.nickname);
      contact.nickname = '';
      console.error('invalid nickname', contact);
    }
  }

  private sortByActivity(array: Contact[]) {
    array.sort((a: Contact, b: Contact) => {
      if (a.isBlocked) {
        // sort blocked to the bottom
        if (!b.isBlocked) {
          return 1;
        } else {
          return 0;
        }
      }

      if (a.unreadMessageCount > b.unreadMessageCount) {
        // sort with unread messages to the top
        return -1;
      } else if (a.unreadMessageCount < b.unreadMessageCount) {
        return 1;
      }

      let as: string = this.config.getItem('lastActivity-' + a.destAddress);
      let bs: string = this.config.getItem('lastActivity-' + b.destAddress);
      if (!as) {
        as = '0';
      }
      if (!bs) {
        bs = '0';
      }
      const an = parseInt(as, 10);
      const bn = parseInt(bs, 10);

      if (an > bn) {
        return -1;
      }
      if (an < bn) {
        return 1;
      }

      // if equal break tie on online status
      const astat = a.isOnline;
      const bstat = b.isOnline;
      if (astat && !bstat) {
        return -1;
      }
      if (bstat && !astat) {
        return 1;
      }

      // if equal break tie on nickname
      as = a.nickname.toLocaleLowerCase();
      bs = b.nickname.toLocaleLowerCase();
      if (as < bs) {
        return -1;
      }
      if (as > bs) {
        return 1;
      }
      return 0;
    });
  }

  public removeContact(contact: Contact) {
    console.log('Contact: removeContact', contact);
    this.allLists.forEach((list: Contact[]) => {
      for (let idx = 0; idx < list.length; ++idx) {
        let c = list[idx];
        if (contact.destAddress === c.destAddress) {
          list.splice(idx, 1);
          console.log('Deleted contact from a list');
          break;
        }
      }
      console.log('Contact not found in list ', list);
    });
  }

  public getLists() {
    return {
      all: this.allContacts,
      online: this.onlineContacts,
      offline: this.offlineContacts,
      blocked: this.blockedContacts,
      sortedAZ: this.sortedAZContacts,
      sortedActivity: this.sortedActivContacts,
      groupContacts: this.groupContacts
    };
  }
  public numberOfOnline() {
    return this.onlineContacts.length;
  }
  public numberOfOffline() {
    return this.offlineContacts.length;
  }
  public numberAll() {
    return this.allContacts.length;
  }
}

export interface ContactObserver {
  onContactEvent(contact: Contact, type: string): void;
}

export class MailingAddress {
  public street: string;
  public city: string;
  public region: string;
  public country: string;
  public postalCode: string;
  public firstName: string;
  public lastName: string;
  constructor() {}
  update(d: any) {
    if (d) {
      this.city = d.city;
      this.country = d.country;
      this.region = d.region;
      this.postalCode = d.postalCode;
      this.street = d.street;
      this.firstName = d.firstName;
      this.lastName = d.lastName;
    }
  }
}

export class Contact extends AbstractMessenger implements RtcListener {
  private static dateFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };

  public isLocal = false;
  public nickname = '';
  public email = '';
  public state: string[] = [];
  public isOnline = false;
  public isRequest = false;
  public type = 'contact';
  public destAddress: string;
  public srcAddress: string; // our address  unless contact is anonymous with key
  public rtcConnection: RtcConnection[] = [];
  public observers: ContactObserver[] = [];
  public keyCode: string;
  public isAnon = false;
  public permissions: string;
  public phoneNumber: string;
  public callerID: string;
  public firstName: string;
  public lastName: string;
  public mailingAddress = new MailingAddress();
  public key: Key;
  public soundLevel = 0;
  public isBlocked = false;
  public secret = '';
  public messengerType = 'contact';
  public twilioBalance = 0;
  public awayable = false;
  public talking = false;
  public unreadMessageCount = 0;
  public timeDifference = 0; // UTC difference in minutes between us
  public dateTime = '-';
  private dateTimer: NodeJS.Timeout;
  private lastConnected = 0;

  constructor(arg0: any, private contactsService: ContactsService) {
    super(contactsService.msgSvc);
    if (arg0 != null) {
      // console.log('calling update from constructor');
      this.update(arg0);
    }
    console.log('new Contact', this, new Error());
  }

  update(args0: any) {
    //   console.warn(args0);
    this.nickname = args0.nickname;
    this.keyCode = args0.keyCode;
    this.state[0] = args0.presence;
    this.isOnline = args0.presence === 'connected' ? true : false;
    this.isRequest = args0.isRequest;
    this.type = args0.type
      ? args0.type
      : this.isRequest
      ? 'request'
      : 'contact';
    this.isBlocked = args0.isBlocked;
    this.awayable = args0.awayable;
    this.permissions = args0.permissions;
    this.phoneNumber = args0.phoneNumber;
    this.callerID = args0.callerID;
    this.email = args0.email;
    this.firstName = args0.firstName ? args0.firstName : '';
    this.lastName = args0.lastName ? args0.lastName : '';
    this.secret = args0.secret;
    this.mailingAddress.update(args0.mailingAddress);
    if (!args0.address) {
      console.error(
        'Contacts: Contact: forceing destAddress to be same as address'
      );
    } else {
      this.destAddress = args0.address;
    }

    if (!args0.srcAddress) {
      if (this.keyCode) {
        if (this.contactsService.keyCode) {
          // this is a peer
          this.key = this.contactsService.key;
          this.srcAddress = this.contactsService.localContact[0].destAddress;
        } else {
          // We are the KeyMaster
          this.srcAddress = this.keyCode;
        }
      } else {
        this.srcAddress = this.contactsService.localContact[0].destAddress;
      }
    } else {
      this.srcAddress = args0.srcAddress;
    }
    if (args0.twilioBalance) {
      this.twilioBalance = args0.twilioBalance / 100;
    }
    if (!this.srcAddress) {
      console.error('SrcAddress is not set!!!!!', this);
    }
    this.setMessages();
    this.setTimeOffset(null);
  }

  /**
   * Called from hello message that contains date offset in minutes
   */
  public setTimeOffset(timeOffset: any): any {
    if (timeOffset) {
      this.contactsService.config.setItem(
        this.destAddress + ':tzoffset',
        timeOffset
      );
    } else {
      timeOffset = this.contactsService.config.getItem(
        this.destAddress + ':tzoffset'
      );
      if (!timeOffset) {
        return;
      }
    }

    this.timeDifference =
      (this.contactsService.timeOffset - timeOffset) * 60000;

    // console.log('Time diifference ' + this.timeDifference);
    if (this.timeDifference !== 0 && !this.dateTimer) {
      this.dateTime = new Date(
        Date.now() + this.timeDifference
      ).toLocaleDateString('en', Contact.dateFormatOptions as any);
      this.dateTimer = setInterval(() => {
        this.dateTime = new Date(
          Date.now() + this.timeDifference
        ).toLocaleDateString('en', Contact.dateFormatOptions as any);
      }, 60000);
    }
  }

  public registerObserver(listener: ContactObserver) {
    this.observers.push(listener);
  }

  public unregisterObserver(listener: ContactObserver) {
    this.observers.splice(this.observers.indexOf(listener), 1);
  }

  notifyObservers(type: string) {
    this.observers.forEach((observer) => {
      try {
        observer.onContactEvent(this, type);
      } catch (error) {
        console.error('Contacts: Contact: notifyObservers:', error);
      }
    });
  }

  public acceptRequest(accept: boolean) {
    this.contactsService.acceptRequest(accept, this);
  }

  public changeState(state: string) {
    console.log('changeState:' + state, this);
    if (state === 'connected') {
      if (Date.now() - this.lastConnected > 300000) {
        this.contactsService.systemBus.emit(
          this.nickname + ' ' + state,
          'toast'
        );
      }
      this.lastConnected = Date.now();
    }
    const cstate = this.state[0];
    if (state === 'hello') {
      super.changeState(state);
      if (cstate === 'disconnected') {
        this.state[0] = 'connected';
        this.isOnline = true;
        if (!this.isAnon && this.contactsService.keyCode) {
          this.contactsService.systemBus.emit(this, 'contactSelected');
          if (this.contactsService.autoConnect) {
            this.connectRtc(this.contactsService.autoConnectVideo, false);
            this.contactsService.autoConnect = false;
          } else {
            let into = {
              messenger: this,
              message:
                'Hello from ' + this.contactsService.localContact[0].nickname
            };
            this.contactsService.systemBus.emit(into, 'sendChatMessage');
          }
        }
      }
      return;
    }

    if (this.state[0] === state) {
      return;
    }
    this.isOnline = state === 'connected' ? true : false;
    this.state[0] = state;
    this.notifyObservers('state');
  }

  public hasPermission(perm: string): boolean {
    let hasPerm = false;
    if (this.permissions && this.permissions.length > 0) {
      const idx = this.permissions.indexOf(perm);
      hasPerm = idx !== -1;
    }
    return hasPerm;
  }

  public addRtcConnection(connection: RtcConnection) {
    const idx = this.rtcConnection.indexOf(connection);
    if (idx === -1) {
      this.rtcConnection.push(connection);
      connection.addListener(this);
    } else {
      console.error('Contact: addRtcConnection: allready connected');
    }
  }

  public async connectRtc(enableVideo: boolean, joinGroup: boolean) {
    if (this.isLocal) {
      console.error('Calling ourselvs', new Error().stack);
      return;
    }
    if (this.isRequest || this.type !== 'contact') {
      console.error(
        'Contacts: Contact: connectRtc: this contact is a request, action not supported'
      );
      return;
    }
    if (this.rtcConnection.length === 0) {
      // let message = '';
      // if (enableVideo) {
      //     message = 'Video Calling ' + this.nickname + '.'
      // } else {
      //     message = 'Calling ' + this.nickname + '.'
      // }
      // this.contactsService.audioService.playText(message);
      if (this.isOnline) {
        this.contactsService.audioService.RtcRingOut(true);
        this.rtcConnection[0] = await this.contactsService.rtcService.connect(
          this,
          joinGroup,
          enableVideo
        );
      } else if (this.awayable) {
        // send Push request
        let actions = [
          {
            action: 'call',
            title: 'Call',
            icon: '/assets/img/call_answer.png'
          },
          {
            action: 'ignore',
            title: 'Ignore',
            icon: '/assets/img/call_end.png'
          }
        ];
        this.contactsService.msgSvc.api.sendPushMessage(
          this.destAddress,
          'Call',
          'Call Me',
          actions
        );
        const message = {
          type: 'info',
          messageID: 'CallME',
          message: 'Call Request sent',
          timeOut: 4
        };
        this.contactsService.systemBus.emit(message, 'warning');
      } else {
        console.error('Contact is not available', this);
      }
      this.notifyObservers('rtc');
    } else {
      console.error('Contacts: Contact: connectRtc: allready connected to Rtc');
    }
  }

  rtcMessage(message: string): void {
    console.log(
      'ContactService: Contact: rtcMessage: ignoring message ' + message
    );
  }

  rtcTalking(talking: boolean): void {
    this.talking = talking;
  }

  rtcConnectionRequest(_address: string, connection: RtcConnection) {
    if (this.rtcConnection.indexOf(connection) < 0) {
      this.rtcConnection.push(connection);
    } else {
      console.warn(
        'Contacts: Contact: rtcConnectionRequest: connection already in connection list'
      );
    }
  }

  rtcClosed() {
    this.contactsService.audioService.PhoneRingOut(false);
    this.rtcConnection.length = 0;
    this.notifyObservers('rtc');
  }

  rtcStateChange(state: string): void {
    if (state === 'connected') {
      this.contactsService.audioService.stop();
    }
    if (state === 'closed') {
      this.contactsService.audioService.stop();
    }
  }

  rtcSoundLevelChange(volume: number) {
    this.soundLevel = volume;
  }

  public destroy() {
    super.destroy();
    if (this.dateTimer) {
      clearTimeout(this.dateTimer);
    }
    this.observers = null;
    this.secret = null;
    this.contactsService = null;
  }
}

@Injectable()
export class ContactsService implements MessageObserver, LoggedInCallback {
  public autoConnect: boolean;
  public autoConnectVideo = true;
  public keyCode: string;
  public key: Key;
  public anonNickName: string;
  private keyContact: Contact;
  public localContact: Contact[] = [];
  public contactsList: ContactList;
  public timeOffset = new Date().getTimezoneOffset();

  constructor(
    public userService: UserLoginService,
    public cognitoUtil: CognitoUtil,
    public systemBus: SystemBusService,
    public router: Router,
    public networkSvc: NetworkService,
    public rtcService: LivekitService,
    public audioService: AudioService,
    public keySvc: KeyService,
    public api: EqcallapiService,
    public msgSvc: MessageService,
    public config: ConfigService
  ) {
    // this.contactsList = new ContactList(systemBus, config);

    setTimeout(() => {
      const message = {
        type: 'info',
        messageID: 'ContactSvcStart',
        message: 'Loading contacts',
        timeOut: 10
      };
      this.systemBus.emit(message, 'warning');
    }, 200);

    this.userService.onceAuthenticated(this);
  }

  public addGroupContact(nickname: string, address: string): Contact {
    let contact = new Contact(null, this);
    contact.nickname = nickname;
    contact.destAddress = address;
    contact.setMessages();
    contact.srcAddress = this.localContact[0].srcAddress;
    this.contactsList.addGroupContact(contact);
    this.networkSvc.addTrustedAddress(contact.destAddress);
    return contact;
  }

  public async updateContact(contact: Contact) {
    this.contactsList.updateContact(contact.destAddress, contact.state[0]);
    this.api.updateContact(contact);
    this.networkSvc.block(contact.destAddress, contact.isBlocked);
  }

  public createContactWithSocket(socket: Socket, nickname: string): Contact {
    let contact;
    try {
      const address = socket.getDestination();
      contact = new Contact(
        {
          nickname: nickname,
          email: '',
          userID: 'none',
          address: address
        },
        this
      );
      contact.setMessages();
      contact.isAnon = true;
      contact.changeState('connected');
      this.contactsList.addContact(contact);
    } catch (err) {
      console.error('ContactService: createContactWithSocket: exception ', err);
    }
    return contact;
  }

  public acceptRequest(accept: boolean, contact: Contact) {
    if (accept) {
      contact.isRequest = false;
      contact.type = 'contact';
      this.api
        .acceptContact(contact.destAddress)
        .then((_result: any) => {
          this.sendHello(contact, true);
        })
        .catch((result: any) => {
          console.error('Contacts:  acceptRequest: error = ', result);
        });
    } else {
      this.removeContact(contact);
    }
  }

  isLoggedIn(_message: string, isLoggedIn: boolean) {
    this.contactsList = new ContactList(this.systemBus, this.config);
    this.autoConnect = this.userService.autoConnect;
    this.autoConnectVideo = this.userService.autoConnectVideo;
    this.anonNickName = this.userService.anonNickName;
    this.keyCode = this.userService.getKeyCode();
    this.localContact.push(new Contact(null, this));
    if (!isLoggedIn) {
      console.log('Nav to login');
      this.router.navigate(['/home/login']);
    }

    if (this.keyCode) {
      this.keySvc.getKey(this.keyCode).then((key: Key) => {
        if (!key) {
          console.error('Could not retrieve key ' + this.keyCode);
          alert('Could not retrieve key or invalid key ' + this.keyCode);
          this.keyCode = null;
          this.userService.logout(true);
          return;
        }
        this.key = key;
        this.getCurrentContact();
      });
    } else {
      this.getCurrentContact();
    }
    this.rtcService.contactSvc = this;
    this.rtcService.setContacts(this.contactsList.getLists().all);
  }

  gotLocalContact(success: boolean) {
    if (!success) {
      console.error('Error getting local contact');
      const message = {
        type: 'warning',
        message: 'Error connecting',
        timeOut: 10
      };
      this.systemBus.emit(message, 'warning');
    } else {
      this.systemBus.subscribe(this);
      this.rtcService.ourNickname = this.localContact[0].nickname;
      this.systemBus.emit(this.localContact[0], 'contacts/gotlocalContact');
      this.updateContacts();
    }
  }

  onBusMessage(message: any, type: string): void {
    if (type.startsWith('iot/presence/')) {
      if (type === 'iot/presence/otherDisconnected') {
        if (this.localContact[0].state[0] !== 'disconnected') {
          console.log('sending state because other changed');
          setTimeout(
            () => this.api.setOnlineState(this.localContact[0].state[0]),
            1000
          );
        }
        return;
      }

      let address = message.address;
      // is this a keyCode presence?
      if (address === this.localContact[0].destAddress) {
        return;
      }
      if (!address) {
        console.error(
          'Contacts: onBusMessage: Presence message missing address'
        );
      } else {
        message.destAddress = address;
      }
      let state = message.state;
      this.config.setItem('lastActivity-' + address, Date.now().toString());

      let contact = this.contactsList.updateContact(address, state);
      if (!contact) {
        console.log('Contacts: onBusMessage: Presence from unknown contact');
        if (message.state === 'disconnected') {
          console.error(
            'Got disconnection presence message from contact we do not have'
          );
          return;
        } else {
          if (message.srcAddress === message.address) {
            return;
          }
          contact = new Contact(message, this);
          contact.isAnon = true;
          contact.isOnline = true;
          contact.awayable = false;
          state = 'connected';
          contact.changeState(state);
          if (state === 'hello') {
            contact.setTimeOffset(message.timeOffset);
          }
          this.contactsList.addContact(contact);
          this.sendHello(contact, false);
          return;
        }
      }
      if (state === 'disconnected' && contact.keyCode && contact.isAnon) {
        // keyCode user went offline, remove user
        setTimeout(() => {
          this.removeContact(contact);
        }, 2000);
      }
      if (
        message.nickname &&
        (!contact.nickname ||
          !contact.nickname ||
          contact.nickname.length === 0)
      ) {
        contact.nickname = message.nickname;
      }
      if (state === 'connected') {
        this.sendHello(contact, false);
      }
      if (state === 'hello') {
        contact.setTimeOffset(message.timeOffset);
      }
    } else if (type === 'rtc/connection/new') {
      const connection: RtcConnection = <RtcConnection>message;
      const address = connection.getDestinationID();
      let contact = this.getContactByAddress(address);
      if (!contact) {
        const data = {
          nickname: connection.getRemoteNickName(),
          presence: 'connected',
          isequest: false,
          awayable: false,
          address: connection.getDestinationID()
        };
        contact = new Contact(data, this);
        contact.isAnon = true;
        contact.isOnline = true;
        contact.changeState('connected');
        this.contactsList.addContact(contact);
        this.sendHello(contact, false);
      }
      connection.addListener(contact);
    } else if (type === 'notice/contactRequest') {
      if ('refresh' === message) {
        this.updateContacts();
      }
    } else if (type === 'iot/connection/connected') {
      // send hello to key if we have one
      if (this.keyContact) {
        this.sendHello(this.keyContact, true);
      }
    } else if (type === 'contacts/new') {
      this.sendHello(message, true);
    } else {
      console.error('Contacts: onBusMessage: Invalid presence type');
    }
  }

  private sendHello(contact: Contact, delay: boolean) {
    if (contact.isBlocked) {
      return;
    }

    // connectionID is destination

    let contactKeyCode = contact.keyCode;
    if (contactKeyCode) {
      // is it one of our codes?
      let key = this.keySvc.getKeyByCode(contactKeyCode);
      if (key) {
        contact.srcAddress = contact.keyCode;
        this.networkSvc.addTrustedAddress(contact.destAddress);
      }
    }

    if (contact.type === 'contact') {
      const data = {
        type: 'Presence',
        state: 'hello',
        keyCode: this.keyCode,
        address: contact.srcAddress,
        nickname: this.localContact[0].nickname,
        timeOffset: new Date().getTimezoneOffset()
      };
      if (delay) {
        setTimeout(() => {
          this.networkSvc.sendHello(contact.destAddress, data);
        }, 1000 + Math.random() * 500);
      } else {
        this.networkSvc.sendHello(contact.destAddress, data);
      }
    }
  }

  busMessageFilter(messageType: string): boolean {
    if (messageType === 'rtc/connection/new') {
      return true;
    } else if (messageType === 'notice/contactRequest') {
      return true;
    } else if (messageType.startsWith('iot/presence/')) {
      return true;
    } else if (messageType === 'contacts/new') {
      return true;
    } else {
      return false;
    }
  }

  public async updateContacts() {
    const that = this;

    if (this.key) {
      let contact = new Contact(this.key, this);
      this.networkSvc.addTrustedAddress(contact.destAddress);
      contact.srcAddress = this.localContact[0].srcAddress;
      this.keyContact = contact;
      contact.changeState('disconnected');
      this.contactsList.addContact(contact);
      this.systemBus.emit(contact, 'contacts/new');
      this.networkSvc.addListenerKey(this.key, true);

      const message = {
        type: 'cancel',
        messageID: 'ContactSvcStart'
      };
      this.systemBus.emit(message, 'warning');
      this.systemBus.emit(this, 'contacts/gotContacts');
      this.networkSvc.ready(true);
      return;
    }

    this.api
      .getContacts()
      .then((result: any) => {
        const isInContacts = function (d: Contact): boolean {
          for (let c of that.contactsList.getLists().all) {
            if (c.destAddress === d.destAddress) {
              console.log(
                'Contacts: updateContacts: Contact is already in list ' + c
              );

              c.keyCode = null;
              c.nickname = d.nickname;
              // c.state = d.state;
              c.isRequest = d.isRequest;
              c.isAnon = false;
              c.changeState(d.state[0]);
              return true;
            }
          }
          return false;
        };

        try {
          const data = result.data;
          const updated: Contact[] = [];
          const newContacts: Contact[] = [];
          const newContactAddresses: string[] = [];
          for (let i of data) {
            const contact = new Contact(i, that);
            updated.push(contact);
            if (!isInContacts(contact)) {
              newContacts.push(contact);
              if (!contact.isBlocked) {
                newContactAddresses.push(contact.destAddress);
              }
            }
          }

          this.networkSvc.addTrustedAddresses(newContactAddresses);

          newContacts.forEach((contact: Contact) => {
            this.networkSvc.addTrustedAddress(contact.destAddress);
            that.contactsList.addContact(contact);
            if (contact.isBlocked) {
              this.networkSvc.block(contact.destAddress, true);
            } else {
              if (contact.isRequest || contact.type === 'request') {
                that.systemBus.emit(contact, 'contacts/newRequest');
              } else {
                that.systemBus.emit(contact, 'contacts/new');
              }
            }
          });

          // remove contacts from all that do not exist in updated

          for (let c of that.contactsList.getLists().all) {
            if (c.keyCode) {
              continue;
            }
            let matched = false;
            for (let d of updated) {
              if (d.destAddress === c.destAddress) {
                matched = true;
                break;
              }
            }
            if (!matched) {
              console.log('Removing unmatched contact after update');
              that.contactsList.removeContact(c);
            }
          }

          const message = {
            type: 'cancel',
            messageID: 'ContactSvcStart'
          };
          this.systemBus.emit(message, 'warning');
          this.systemBus.emit(this, 'contacts/gotContacts');
          this.networkSvc.ready(true);
        } catch (err) {
          console.error('Contacts: updateContacts:AHHHHH ', err);
        }
      })
      .catch(function (result: any) {
        console.error('Contacts: updateContacts: error = ', result);
      });
  }

  public async updateLocalContact() {
    await this.api
      .getCurrentUser()
      .then((result: any) => {
        try {
          console.error('ContactSvc: updateLocalContact: result', result);
          if (this.localContact.length === 0) {
            this.localContact[0] = new Contact(result.data, this);
            console.error(
              'ContactSvc: updateLocalContact: new contact created'
            );
          } else {
            this.localContact[0].update(result.data);
            console.error(
              'ContactSvc: updateLocalContact: updated existing contact'
            );
          }
          this.localContact[0].isLocal = true;
        } catch (error) {
          console.error('Contacts: getCurrentContact: ', error);
        }
      })
      .catch((result: any) => {
        console.error('Contacts: getCurrentContact: error = ', result);
      });
  }

  private getCurrentContact() {
    if (this.keyCode) {
      let name: string;
      if (this.anonNickName) {
        name = this.anonNickName;
      } else {
        name = this.key.nickname;
      }
      let cid = this.cognitoUtil.getCognitoIdentityID();
      let params = {
        nickname: name,
        email: this.key.email,
        userID: this.key.keyCode,
        address: cid,
        srcAddress: cid
      };
      this.localContact[0].update(params);
      this.gotLocalContact(true);
    } else {
      this.updateLocalContact()
        .then(() => {
          this.gotLocalContact(true);
        })
        .catch(() => {
          this.gotLocalContact(false);
        });
    }
  }

  public async addContact(email: string) {
    this.api
      .requestContact(email)
      .then((_result: any) => {
        console.log('New Contact created');
        this.systemBus.emit('refresh', 'notice/contactRequest');
      })
      .catch((result: any) => {
        console.error('Contacts: addContact: error = ', result);
      });
  }

  public removeContact(contact: Contact) {
    if (contact.keyCode) {
      this.contactsList.removeContact(contact);
      this.systemBus.emit('refresh', 'notice/contactRequest');
    } else {
      this.api
        .removeContact(contact.destAddress)
        .then((_result: any) => {
          try {
            this.contactsList.removeContact(contact);
            // ToDo change tp delete message
            this.systemBus.emit('refresh', 'notice/contactRequest');
          } catch (err) {
            console.error('Contacts: removeContact: AHHHHH Delete', err);
          }
        })
        .catch((result: any) => {
          console.error('Contacts: removeContact: Delete error = ', result);
        });
    }
  }

  public getContactByAddress(address: string): Contact {
    for (let c of this.contactsList.getLists().all) {
      if (c.destAddress === address) {
        return c;
      }
    }
    for (let c of this.contactsList.getLists().groupContacts) {
      if (c.destAddress === address) {
        return c;
      }
    }
    return null;
  }
}
