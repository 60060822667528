import {
  AudioAnalyser,
  AudioAnalyserCtlr,
  AudioAnalyserImpl,
  CropSettings,
  EqualizerValues,
  NoAudioAnalyserImpl,
  StreamChangeEventListener,
  StreamhandlerService,
  Visualizer,
  VisualizerImpl,
  VuMeter
} from './streamhandler.service';
import { ConfigService } from './config.service';
import { EqcallapiService } from './eqcallapi.service';
import { Injectable, NgZone } from '@angular/core';
import { MessageObserver, SystemBusService } from './system-bus.service';
import {
  NetworkService,
  Socket,
  SocketListener,
  SocketListenerCallback
} from './network.service';
import { RtcProxyConnection, RtcproxyService } from './rtcproxy.service';

export class RtcChatMessage {
  public time: number;
  constructor(
    public nickname: string,
    public message: string,
    public local: boolean
  ) {
    this.time = new Date().getTime();
  }
}

export class BandwidthHandler {
  public static bandwidthHandler = new BandwidthHandler();
  public default(sdp: string, _bandwidth: number): string {
    return sdp;
    // if (bandwidth >= 1900) {
    //     return sdp;
    // }
    // console.log(sdp);
    // bandwidth = Math.ceil(bandwidth);

    // const video = !sdp.includes('a=inactive');
    // //  console.log('RtcConnection: bandwidth set to ' + bandwidth + ' with video ' + video);
    // let videoBW = 10;
    // let audioBW = 85;
    // if (bandwidth < 10) {
    //     bandwidth = 10;
    // }
    // if (video) {
    //     audioBW = Math.max(Math.min(bandwidth, 85), 45);
    //     videoBW = Math.max(bandwidth - audioBW, 10); // video gets what's left
    // } else {
    //     audioBW = Math.max(bandwidth, 85);
    // }
    // console.log('Bandwidth: audio=' + audioBW + ' video=' + videoBW);
    // let modifier = 'AS';
    // if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
    //     bandwidth = (bandwidth >>> 0) * 1000;
    //     modifier = 'TIAS';
    // }

    // let output = '';
    // let lines = sdp.match(/[^\r\n]+/g)
    // let processing: string; // m line in sdp, = audio or video or application
    // let idx = 0;
    // for (let line of lines) {
    //     if (line.startsWith('m=')) {
    //         const l = line.split(' ')[0];
    //         processing = l.split('=')[1].trim();

    //     } else if (line.startsWith('a=mid:')) {
    //         console.log('Processing ' + processing);
    //         let bw: number
    //         if (processing === 'video') {
    //             bw = videoBW;
    //         } else if (processing === 'audio') {
    //             bw = audioBW;
    //         }
    //         if (bw !== undefined) {
    //             line += '\r\nb=' + modifier + ':' + bw;
    //         }
    //     }
    //     output += line + '\r\n';
    //     idx++;
    // }
    // console.log(output);
    // return output;
  }
}

export interface RtcConnection extends StreamChangeEventListener {
  service: any;
  remoteNickName: string;
  setIsRemoteControlledAnswer(answer: boolean): void;
  getDestinationID(): string;
  getIsBeingCalled(): boolean;
  getState(): string;
  getIsRemoteControlled(): boolean;
  getRemoteNickName(): string;
  getIsTalking(): boolean;
  getAreRemoteControlling(): boolean;
  setRemoteDeviceSettings(ret: any): void;
  getStats(): Promise<any>;
  setRemoteVolume(volume: number): void;
  setRemoteEq(arg0: string, val: number): void;
  setRemoteNoiseGate(val: number): void;
  addListener(arg0: RtcListener): void;
  removeListener(arg0: RtcListener): void;
  listDevices(): Promise<unknown>;
  sendEQValues(arg0: EqualizerValues): void;
  sendRawMessage(dataArray: Uint8Array, arg1: string): void;
  remoteControlRequestSendAnswer(answer: boolean): void;
  sendPeerNotification(destinationID: string, remoteNickName: string): void;
  remoteCtlDataMessage(packet: any, messageType: string): void;
  sendTalking(talking: boolean): void;
  streamHandlerOnStreamChangeEvent(stream: MediaStream): void;
  hold(hold: boolean): void;
  close(): void;
  requestRemoteControl(message: string): Promise<any>;
  sendChatMessage(message: string): void;
  masterMuteLocalAudio(arg0: boolean): void;
  masterMuteLocalVideo(mute: boolean): void;
  muteRemoteVideo(mute: boolean): void;
  muteRemoteAudio(mute: boolean): void;
  setBandwidth(bw: number): void;
  acceptConnection(): void;
  getOutputStream(): MediaStream;
  getAnalyser(): AudioAnalyser;
  getVisualizer(): Visualizer;
  startRemoteVisualizer(start: boolean): void;
  setRemoteVideoCrop(cropSettings: CropSettings): void;
  getIsRemoteVideoCropping(): CropSettings;
}

export class RtcConnectionImpl
  implements RtcConnection, StreamChangeEventListener
{
  private remoteBandwidth = 1024;
  private workingBandwidth = 1024;
  private bandwidth = 1024;
  private peerConnection: RTCPeerConnection;
  public outputStream: MediaStream;
  private audioContext: AudioContext;
  private signalingState: string;
  private renegTimerHandle: any;
  private audioMutedByRemote = false;
  private videoMutedByRemote = false;
  private localAudioMuted = false;
  private onHold = false;
  private isHoldByRemote: boolean;
  private inputStream: MediaStream;
  private localVideoMuted = false;
  private analyser: AudioAnalyser;
  private isready = false;
  private listeners: RtcListener[] = [];
  private offer: RTCSessionDescription;
  public destinationID = '';
  public state = 'connecting';
  private initialized: boolean;
  private iceQueue = new Array<RTCIceCandidate>();
  private isPlayingQueue = false;
  private channels = new Map<string, any>();
  disposed = false;
  private dataMessageQueue: Map<string, Array<any>> = new Map();
  public isTalking = false;
  public iOS =
    ['iPad', 'iPhone', 'iPod', 'MacIntel', 'MacPPC', 'Mac68K'].indexOf(
      navigator.platform
    ) >= 0;
  private remoteControlAnswerFunction: any;
  private listDevicesAnswerFunction: any;
  public isRemoteControlled = false;
  public areRemoteControlling = false;
  private closed: boolean;
  private visualizer: VisualizerImpl;
  private isRemoteVideoCropping: CropSettings;

  constructor(
    private socket: Socket,
    public service: RtcService,
    private streamHandler: StreamhandlerService,
    public isBeingCalled: boolean,
    public joinGroup: boolean,
    public remoteNickName: string,
    private rtc: RTC,
    private index: number,
    private mode: string,
    enableAudio: boolean,
    enableVideo: boolean
  ) {
    this.localAudioMuted = !enableAudio;
    this.localVideoMuted = !enableVideo;
    this.audioContext = service.audioContext;
    this.destinationID = rtc.getDestinationID();
    this.onHold = service.getMasterHold();
    this.outputStream = rtc.outputStream;
  }

  public getIsRemoteVideoCropping(): CropSettings {
    return this.isRemoteVideoCropping;
  }

  // public setAreRemoteControlling(answer: boolean) {
  //     this.areRemoteControlling = answer;
  // }

  public setIsRemoteControlledAnswer(answer: boolean): void {
    this.isRemoteControlled = answer;
  }

  public getDestinationID(): string {
    return this.destinationID;
  }
  public getIsBeingCalled(): boolean {
    return this.isBeingCalled;
  }
  public getState(): string {
    return this.state;
  }
  public getIsRemoteControlled(): boolean {
    return this.isRemoteControlled;
  }
  public getRemoteNickName(): string {
    return this.remoteNickName;
  }
  public getIsTalking(): boolean {
    return this.isTalking;
  }
  public getAreRemoteControlling(): boolean {
    return this.areRemoteControlling;
  }

  public getOutputStream(): MediaStream {
    return this.outputStream;
  }

  public getAnalyser(): AudioAnalyser {
    return this.rtc.getAnalyser();
  }
  public getVisualizer(): Visualizer {
    return this.rtc.getVisualizer();
  }

  public async sendTalking(talking: boolean) {
    // console.log('sendTalking ', talking);

    if (
      this.isready &&
      !this.onHold &&
      !this.localAudioMuted &&
      !this.audioMutedByRemote
    ) {
      const data = {
        type: 'levels',
        id: this.socket.connectionID,
        talking: talking
      };
      this.sendRawMessage(JSON.stringify(data), 'messages');
    }
  }

  private remoteIsTalking(talking: boolean) {
    //  console.error('RTCConnection: remoteIsTalking ' + talking);
    this.isTalking = talking;
    this.rtc.rtcTalking(talking);
  }

  public async getStats() {
    if (this.peerConnection) {
      return this.peerConnection.getStats();
    }
  }

  public setBandwidth(bandwidthKb: number) {
    this.bandwidth = bandwidthKb;
    if (this.peerConnection) {
      this.peerConnection.getSenders().forEach((sender) => {
        const parameters = sender.getParameters();
        if (!parameters.encodings) {
          parameters.encodings = [{}];
        }
        parameters.encodings.forEach((encoding) => {
          encoding.maxBitrate = bandwidthKb * 512;
        });
        sender.setParameters(parameters).catch((err) => {
          console.error('TrcService: setBandwidth: ', err);
        });
      });
    }
  }

  public addListener(listener: RtcListener) {
    if (this.disposed) {
      return;
    }
    this.listeners.push(listener);
  }

  public removeListener(listener: RtcListener) {
    if (this.disposed) {
      return;
    }
    this.listeners.splice(this.listeners.indexOf(listener), 1);
  }

  public sendPeerNotification(destAddress: string, nickname: string) {
    const data = {
      type: 'peerNotification',
      id: this.socket.connectionID,
      destAddress: destAddress,
      nickname: nickname
    };
    //  console.log('RtcConnection: sendPeerNotification: ', data);
    this.sendRawMessage(JSON.stringify(data), 'messages');
  }

  private receivedPeerNotofication(destAddress: string, nickname: string) {
    // console.log('RtcConnection: receivedPeerNotification: ' + destAddress + ' nickname=' + nickname);
    if (!this.isBeingCalled || this.joinGroup) {
      this.service.autoConnectPeer(destAddress, nickname);
    }
  }

  private async getUserMedia() {
    //  console.log('RtcConnection: getUserMedia: ')
    this.inputStream = await this.rtc.getUserMedia(this.mode);
    // if (this.inputStream.getAudioTracks().length > 0) {
    //     this.audioEnabled = true;
    // }
    this.analyser = this.rtc.getAnalyser();
    this.visualizer = this.rtc.visualizer;
  }

  processRtcReceivedConnectionPacket(packet: any) {
    try {
      //  console.log('RtcConnection: processRtcReceivedConnectionPacket ', packet);
      const type = packet.type;
      if (type !== 'accept' && packet.index !== this.index) {
        // console.log('Not me, im ' + this.index);
        return;
      }

      if (type === 'offer') {
        if (!this.isready) {
          try {
            this.listeners.forEach((listener) => {
              listener.rtcConnectionRequest(this.socket.getDestination(), this);
            });
          } catch (err) {
            console.error(err);
          }
        }
        this.processOffer(packet.data);
      } else if (type === 'ice') {
        this.processIceQueue(packet.data);
      } else if (type === 'sdpAnswer') {
        this.processAnswer(packet.data);
      } else if (type === 'accept') {
        let remoteMode = packet.mode;
        this.rtc.remoteMode = remoteMode;
        this.service.api.getIceConfiguration().then((ret: any) => {
          this.webRtcInit(ret.data);
        });
      } else if (type === 'negotiationneeded') {
        this.negotiationneeded();
      } else {
        this.error(
          'RtcConnection: processRtcReceivedConnectionPacket',
          'Unknown message type'
        );
      }
    } catch (err) {
      console.error(
        'RtcConnection: processRtcReceivedConnectionPacket: error ',
        err
      );
    }
  }

  private processAnswer(data: any) {
    // console.error('processAnswer', data);
    let answer = data.answer;

    this.remoteBandwidth = data.bw;
    if (this.remoteBandwidth < this.bandwidth) {
      // console.log('RtcConnection: processAnswer: lowering bandwidth from ' +
      //    this.workingBandwidth + ' to ' + this.remoteBandwidth);
      this.workingBandwidth = this.remoteBandwidth;
    } else {
      //  console.log('RtcConnection: processAnswer: setting bandwidth from ' +
      //  this.workingBandwidth + ' to ' + this.remoteBandwidth);
      this.workingBandwidth = this.bandwidth;
    }

    answer.sdp = BandwidthHandler.bandwidthHandler.default(
      answer.sdp,
      this.workingBandwidth
    );
    // console.log('RtcConnection: processAnswer ', answer);
    const that = this;
    this.peerConnection
      .setRemoteDescription(answer)
      .then(() => {
        this.addTracksAndProcessIce();
      })
      .catch((error: DOMException) => {
        that.error('RtcConnection: procesAnswer: Error', error.toString());
      });
  }

  /**
   * Called by streamHandler through RTCService when video / audio streams change
   */
  public async streamHandlerOnStreamChangeEvent(stream: MediaStream) {
    console.log('new video tracks:', stream.getVideoTracks());
    console.log('new audio tracks:', stream.getAudioTracks());
    let ntrack: MediaStreamTrack = null;
    let otrack: MediaStreamTrack = null;
    let nid: string = null;
    let oid: string = null;
    let tracks = stream.getVideoTracks();
    if (tracks && tracks.length > 0) {
      ntrack = tracks[0];
      if (ntrack.readyState === 'live') {
        nid = ntrack.id;
      } else {
        ntrack = null;
      }
    }
    tracks = this.inputStream.getVideoTracks();
    if (tracks && tracks.length > 0) {
      otrack = tracks[0];
      oid = otrack.id;
    }
    if (oid !== nid) {
      console.log('new video track');
      let sndr = null;
      let trans = this.getTransceiverByKind('video');
      if (trans) {
        sndr = trans.sender;
      }
      let vmuted =
        this.localVideoMuted ||
        this.videoMutedByRemote ||
        this.streamHandler.masterVideoMute;
      console.error(
        ntrack,
        this.localVideoMuted,
        this.videoMutedByRemote,
        this.streamHandler.masterVideoMute
      );
      if (ntrack && !vmuted) {
        if (sndr) {
          console.log('Replacing video track on PC');
          sndr.replaceTrack(ntrack);
          trans.direction = <RTCRtpTransceiverDirection>'sendrecv';
        } else {
          console.log('adding new video track to PC');
          this.peerConnection.addTrack(ntrack);
        }
        this.inputStream.addTrack(ntrack);
      } else {
        if (sndr) {
          sndr.replaceTrack(null);
        } else {
          console.warn(' we do not have a video sender');
        }
      }
      if (otrack) {
        this.inputStream.removeTrack(otrack);
      }
    }

    tracks = stream.getAudioTracks();
    if (tracks && tracks.length > 0) {
      ntrack = tracks[0];
      if (ntrack.readyState === 'live') {
        nid = ntrack.id;
      } else {
        ntrack = null;
      }
    }
    tracks = this.inputStream.getAudioTracks();
    if (tracks && tracks.length > 0) {
      otrack = tracks[0];
      oid = otrack.id;
    }

    if (oid !== nid) {
      let sndr = null;
      let trans = this.getTransceiverByKind('audio');
      if (trans) {
        sndr = trans.sender;
      }
      let amuted =
        this.localAudioMuted ||
        this.audioMutedByRemote ||
        this.streamHandler.masterAudioMute;
      console.log('Audio muted ' + amuted);
      if (ntrack && !amuted) {
        if (sndr) {
          sndr.replaceTrack(ntrack);
          trans.direction = <RTCRtpTransceiverDirection>'sendrecv';
        } else {
          this.peerConnection.addTrack(ntrack);
        }
      } else {
        if (sndr) {
          sndr.replaceTrack(null);
        } else {
          console.warn(' we do not have a audio sender');
        }
      }
      if (ntrack) {
        this.inputStream.addTrack(ntrack);
      }
      if (otrack) {
        this.inputStream.removeTrack(otrack);
      }
    }
    console.log('inputStream Video tracks', this.inputStream.getVideoTracks());
    console.log('inputStream Ausio tracks', this.inputStream.getAudioTracks());
  }

  /**
   * Called when we are initiating the call
   */
  private async addTracks() {
    let tracks = this.inputStream.getVideoTracks();
    const vtrans = this.getTransceiverByKind('video');
    if (!vtrans) {
      if (tracks.length === 0) {
        this.peerConnection.addTransceiver('video', { direction: 'recvonly' });
      } else {
        let track = tracks[0];
        let direction: RTCRtpTransceiverDirection = 'recvonly';
        if (this.streamHandler.masterVideoMute || this.localVideoMuted) {
          direction = 'recvonly';
        } else {
          direction = 'sendrecv';
        }

        if (this.onHold) {
          track.enabled = false;
        }
        this.peerConnection.addTransceiver(track, { direction: direction });
      }
    } else {
      if (tracks.length > 0) {
        let track = tracks[0];

        let direction: RTCRtpTransceiverDirection = 'recvonly';
        if (this.streamHandler.masterVideoMute || this.localVideoMuted) {
          direction = 'recvonly';
        } else {
          direction = 'sendrecv';
        }
        if (this.onHold) {
          track.enabled = false;
        }
        vtrans.direction = direction;
        if (!vtrans.sender.track || vtrans.sender.track.id !== track.id) {
          await vtrans.sender.replaceTrack(track);
        }
      } else {
        vtrans.direction = 'recvonly';
      }
    }

    tracks = this.inputStream.getAudioTracks();
    if (tracks) {
      const atrans = this.getTransceiverByKind('audio');
      if (!atrans) {
        if (tracks.length === 0) {
          this.peerConnection.addTransceiver('audio', {
            direction: 'recvonly'
          });
        } else {
          let track = tracks[0];
          let direction: RTCRtpTransceiverDirection = 'recvonly';
          if (this.streamHandler.masterAudioMute || this.localAudioMuted) {
            direction = 'recvonly';
          } else {
            direction = 'sendrecv';
          }

          if (this.onHold) {
            track.enabled = false;
          }
          this.peerConnection.addTransceiver(track, { direction: direction });
        }
      } else {
        if (tracks.length > 0) {
          let track = tracks[0];

          let direction: RTCRtpTransceiverDirection = 'recvonly';
          if (this.streamHandler.masterAudioMute || this.localAudioMuted) {
            direction = 'recvonly';
          } else {
            direction = 'sendrecv';
          }

          if (this.onHold) {
            track.enabled = false;
          }
          atrans.direction = direction;
          if (!atrans.sender.track || atrans.sender.track.id !== track.id) {
            await atrans.sender.replaceTrack(track);
          }
        } else {
          atrans.direction = 'recvonly';
        }
      }
    } else {
      this.peerConnection.addTransceiver('audio', { direction: 'recvonly' });
    }

    this.setBandwidth(this.bandwidth);
  }

  private getTransceiverByKind(kind: string): RTCRtpTransceiver {
    const transceivers = this.peerConnection.getTransceivers();
    let ret: RTCRtpTransceiver = null;
    transceivers.forEach((trans) => {
      //  if (trans.currentDirection !== 'inactive') {
      if (trans.receiver) {
        if (trans.receiver.track) {
          if (trans.receiver.track.kind === kind) {
            ret = trans;
          }
        }
      }
      if (trans.sender) {
        if (trans.sender.track) {
          if (trans.sender.track.kind === kind) {
            ret = trans;
          }
        }
      }
      //   } else {
      //      console.error('Tranceiver stopped', trans);
      //  }
    });
    return ret;
  }

  private async addTracksAndProcessIce() {
    // console.error('Rtc: ready: ', this.iceQueue);
    if (this.isready) {
      return;
    }
    if (!this.isBeingCalled) {
      // ontrack events will be called on the receiver side
      await this.addTracks();
    }

    if (!this.isPlayingQueue) {
      try {
        this.isPlayingQueue = true;
        this.isready = true;
        //   console.log('Rtc: replaying ice queue ', this.iceQueue);
        while (this.iceQueue.length > 0) {
          this.processIce(this.iceQueue.shift());
        }
      } catch (error) {
        this.error('ready', error);
      }
    } else {
      this.isready = true;
    }
  }

  /*
   * called from socet receiving candidate from other side
   */
  private processIceQueue(candidate: RTCIceCandidate) {
    this.iceQueue.push(candidate);
    if (this.isready) {
      if (!this.isPlayingQueue) {
        this.isPlayingQueue = true;
        while (this.iceQueue.length > 0) {
          this.processIce(this.iceQueue.shift());
        }
      } else {
        this.processIce(this.iceQueue.shift());
      }
    }
  }

  private async processIce(candidate: RTCIceCandidate) {
    // console.log('ProcessICE candidate =', candidate);
    if (candidate.candidate.length === 0) {
      //   console.error('ICECandidate 0');
      this.peerConnection
        .addIceCandidate({ candidate: '' })
        .catch((err) => console.error('NOPE!!!!', err));
    } else {
      await this.peerConnection.addIceCandidate(candidate).catch((error) => {
        console.error('RtcConnection: processIce: error ', error);
      });
    }
  }

  private async webRtcInit(servers: any) {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    await this.getUserMedia();

    try {
      let configuration = {
        // constructor of RTCPeerConnection
        iceServers: [servers],
        sdpSemantics: 'unified-plan'
      };

      if ((<any>RTCPeerConnection).setConfiguration) {
        this.peerConnection = new RTCPeerConnection({});
        this.peerConnection.setConfiguration(configuration);
      } else {
        this.peerConnection = new RTCPeerConnection(configuration);
      }

      await this.postWebRtcCreate();
    } catch (err) {
      this.error('Rtc: init: ', err);
      this.dispose();
    }
  }

  private async postWebRtcCreate() {
    this.peerConnection.onicecandidate = (event: any) => {
      if (event && event.candidate) {
        this.sendIce(event.candidate);
      }
    };

    const that = this;

    this.peerConnection.onconnectionstatechange = function (_event: any) {
      // console.log('Rtc: onconnectionstatechange: state=', this, event);
      that.state = this.connectionState;
      if (that.listeners) {
        that.listeners.forEach((listener) => {
          listener.rtcStateChange(this.connectionState);
        });
      }
      switch (this.connectionState) {
        case 'connected':
          //   console.log('Rtc: connectionState: connected');
          that.connected();
          break;
        case 'disconnected':
          console.log('Rtc: connectionState: disconnected');
          // that.close();
          break;
        case 'failed':
          that.close();
          console.error('Rtc: connectionState: failed ' + that.index);
          break;
        case 'closed':
          //  console.log('Rtc: connectionState: closed');
          break;
      }
    };

    this.peerConnection.onsignalingstatechange = function (_event: any) {
      //  console.log('Rtc: onsignalingstatechange: state=' + this.signalingState);
      that.signalingState = this.signalingState;
    };
    this.peerConnection.ondatachannel = function (ev: any) {
      that.newDataChannel(ev);
    };

    this.peerConnection.ontrack = (ev: RTCTrackEvent) => {
      this.onTrackAdded(ev);
    };

    this.createDataChannel('messages', 0);

    if (!this.isBeingCalled) {
      await this.addTracksAndProcessIce();
      this.negotiationneeded();
    }

    if (this.offer) {
      this.processOfferFinal();
    }
  }

  private async notifyListeners(state: string) {
    if (this.listeners) {
      this.listeners.forEach((listener) => {
        listener.rtcStateChange(state);
      });
    }
  }

  public setSoundLevel(level: number) {
    if (this.listeners) {
      this.listeners.forEach((listener) => {
        listener.rtcSoundLevelChange(level);
      });
    }
  }

  private async onTrackAdded(event: RTCTrackEvent) {
    console.log('onTrackAdded ', event);
    if (event.track.kind === 'audio') {
      if (this.outputStream.getAudioTracks().length > 0) {
        console.error(
          'Already have the audio track',
          this.outputStream.getAudioTracks()
        );
        return;
      }
    } else if (this.outputStream.getVideoTracks().length > 0) {
      console.error(
        'Already have the video track',
        this.outputStream.getVideoTracks()
      );
      return;
    }

    this.addTransceiverTrackHandlers(event);
    this.addsenders(event);
  }

  private addTransceiverTrackHandlers(event: RTCTrackEvent) {
    let out: AudioNode;
    let vu: VuMeter;
    let that = this;
    let track = event.track;
    this.outputStream.addTrack(event.track);
    if (track.kind === 'audio') {
      let clone = [];
      let trackclone = track;
      clone.push(trackclone);
      let stream = new MediaStream(clone);
      let node = this.audioContext.createMediaStreamSource(stream);
      //    console.log('node', node);
      vu = new VuMeter(this.audioContext);
      vu.addSoundLevelListener(that);
      out = vu.getOutputAudioNode(node);
      this.service.streamHandler.addToSpeaker(out);
      vu.setIncall(true);
    }
    track.onended = () => {
      this.notifyListeners('track:' + track.kind + ':ended');
      if (track.kind === 'audio') {
        try {
          this.service.streamHandler.removeFromSpeaker(out);
          out.disconnect();
          vu.destroy();
          out = null;
          vu = null;
        } catch (err) {
          console.error(err);
        }
      }
      track.onended = undefined;
      track.onmute = undefined;
      track.onunmute = undefined;
      if (!this.disposed) {
        this.outputStream.removeTrack(track);
      }
    };

    let muteTimeout: number;

    track.onmute = (_ev) => {
      if (this.isHoldByRemote || this.onHold || this.disposed) {
        return;
      }
      muteTimeout = window.setTimeout(() => {
        console.log('onmute ', _ev);
        muteTimeout = undefined;
        if (track.muted) {
          this.notifyListeners('track:' + track.kind + ':ended');

          if (track.kind === 'audio') {
            vu.setIncall(false);
          } else {
            this.outputStream.removeTrack(track);
          }
        } else {
          console.warn('onmute: Track is currently unmuted');
        }
      }, 3000);
    };

    track.onunmute = (_ev) => {
      console.log('onunmute ', _ev);
      if (this.isHoldByRemote || this.onHold || this.disposed) {
        return;
      }
      if (!muteTimeout) {
        if (!track.muted) {
          if (track.kind === 'audio') {
            vu.setIncall(true);
            // this.rtc.resetAudioOutput(this.service.streamHandler.getDeviceIDs().audioOutDeviceID);
            this.rtc.service.systemBus.emit(
              this.service.streamHandler.getDeviceIDs().audioOutDeviceID,
              'rtc/audioDevice/Out/changed'
            );
          } else {
            this.outputStream.addTrack(event.track);
          }
          this.notifyListeners('track:' + track.kind + ':added');
        }
      } else {
        clearTimeout(muteTimeout);
        muteTimeout = undefined;
      }
    };
    if (this.iOS) {
      this.notifyListeners('track:' + track.kind + ':added');
    }
  }

  private addsenders(event: RTCTrackEvent) {
    let transceiver = event.transceiver;
    let receiverType = event.track.kind;
    let direction = transceiver.currentDirection;
    if (!direction) {
      direction = 'inactive';
    }
    if (direction === 'inactive' || !direction) {
      // We are not sending, so do not add senders, but change to resceive
      transceiver.direction = 'recvonly';
      return;
    } else if (direction === 'recvonly') {
      // We are not supposed to be sending, so do not add senders
      return;
    }

    this.inputStream.getTracks().forEach((track: MediaStreamTrack) => {
      if (receiverType === track.kind) {
        transceiver.direction = 'sendrecv';
        if (transceiver.sender) {
          transceiver.sender.replaceTrack(track).catch((err) => {
            console.error('Error replacing track ', err);
          });
        } else {
          console.warn('addsenders: sender undefiend');
        }
      }
    });
    this.setBandwidth(this.bandwidth);
  }

  private async negotiationneeded() {
    if (this.disposed) {
      return;
    }
    //   console.log('renegitiating');
    let offerOptions: any = {
      voiceActivityDetection: false
    };
    if (this.renegTimerHandle) {
      clearTimeout(this.renegTimerHandle);
    }
    this.renegTimerHandle = setTimeout(() => {
      this.renegTimerHandle = null;
      //  console.log('renegitiating', new Error());

      if (this.isBeingCalled) {
        //  console.log('RtcConnection: sending negotiationneeded request: ');
        if (this.signalingState !== 'stable') {
          console.warn('RtcConnection: negotiationneeded: state not stable');
          setTimeout(() => {
            this.negotiationneeded();
          }, 150);
          return;
        }
        const packet = {
          index: this.index,
          type: 'negotiationneeded',
          data: 'negotiationneeded'
        };
        this.socket.send(JSON.stringify(packet));
      } else {
        //  console.log('creating offer');
        this.peerConnection
          .createOffer(offerOptions)
          .then((sdpOffer: any) => {
            this.peerConnection
              .setLocalDescription(sdpOffer)
              .then(() => {
                this.sendOffer(sdpOffer);
              })
              .catch((error) => {
                this.error(
                  'Rtc: onOffer: error setting localdescription',
                  error
                );
              });
          })
          .catch((error: any) => {
            this.error('Rtc: onOffer: Error creating offer ', error);
          });
      }
    }, 100);
  }

  private processOffer(data: any) {
    let offer = data.offer;
    this.remoteBandwidth = data.bw;
    if (this.remoteBandwidth < this.bandwidth) {
      //  console.log('RtcConnection: processOffer: lowering bandwidth from ' +
      // this.workingBandwidth + ' to ' + this.remoteBandwidth);
      this.workingBandwidth = this.remoteBandwidth;
    } else {
      //  console.log('RtcConnection: processOffer: setting bandwidth from ' + this.workingBandwidth + ' to ' + this.bandwidth);
      this.workingBandwidth = this.bandwidth;
    }
    // console.log('Rtc: processOffer: ' + offer.sdp);
    offer.sdp = BandwidthHandler.bandwidthHandler.default(
      offer.sdp,
      this.workingBandwidth
    );
    this.offer = offer;

    if (this.peerConnection) {
      this.processOfferFinal();
    } else {
      console.error('PeerConnection object is not set');
    }
  }

  private async processOfferFinal() {
    // console.error('processOfferFinal');
    this.peerConnection
      .setRemoteDescription(this.offer)
      .then(async () => {
        await this.addTracksAndProcessIce();
        // test
        //   console.warn('pre createAnswer', this.peerConnection.getTransceivers());
        await this.addTracks();
        this.peerConnection.createAnswer().then(
          (sdpAnswer) => {
            this.peerConnection
              .setLocalDescription(sdpAnswer)
              .then(() => {
                const response = {
                  index: this.index,
                  type: 'sdpAnswer',
                  data: {
                    answer: sdpAnswer,
                    bw: this.workingBandwidth
                  }
                };
                this.socket.send(JSON.stringify(response));
              })
              .catch((error) => {
                console.error('Error setting localDescription', error);
              });
          },
          (error) => {
            console.error('RtcConnection: processOffer: Error ', error);
          }
        );
      })
      .catch((err: DOMException) => {
        this.error('Rtc: processing offer error', err.toString());
        return;
      });
  }

  private newDataChannel(ev: any) {
    ev.channel.onopen = (_event: any) => {
      const label = ev.channel.label;
      ev.channel.binaryType = 'arraybuffer';
      this.resendMessages(label);
    };
    ev.channel.onmessage = (event: any) => {
      // console.log('Data channel message ', event)
      const label = ev.channel.label;
      this.receivedDataMessage(event, label);
    };
  }

  private createDataChannel(name: string, id: number) {
    try {
      const channel = this.peerConnection.createDataChannel(name, {
        negotiated: false,
        id: id
      });
      const that = this;
      const onClosed = function (_event: any) {
        // console.log('Rtc: data close' + event);
      };
      const onError = function (event: ErrorEvent) {
        console.log('Rtc: data error', event);
      };
      const onMessage = function (event: any) {
        //  console.log('Rtc: data message ' + event['data']);
        const label = channel.label;
        that.receivedDataMessage(event, label);
      };
      const onOpen = (_event: any) => {
        // console.log('Rtc: data open ' + JSON.stringify(event));
        const label = channel.label;
        this.resendMessages(label);
      };

      channel.binaryType = 'arraybuffer';
      channel.onmessage = onMessage;
      channel.onopen = onOpen;
      channel.onclose = onClosed;
      channel.onerror = onError;
      this.channels.set(name, channel);
    } catch (error) {
      this.error('create data channel', error);
    }
  }

  receivedDataMessage(packet: any, messageType: string) {
    try {
      if (messageType === 'messages') {
        // console.log('Rtc: received data packet : ' + messageType + ' ' + packet['data']);
        const data = JSON.parse(packet['data']);
        const dataType = data.type;
        if (dataType === 'message') {
          //          console.log('Rtc: message ' + data.message);
          this.listeners.forEach((listener) => {
            listener.rtcMessage(data.message, this);
          });
          this.service.newRtcChatRecieved(data.message, this);
        } else if (dataType === 'levels') {
          if (this.isRemoteControlled) {
            this.service.remoteCtlDataMessage(packet, messageType);
          }

          if (data.hasOwnProperty('volume')) {
            this.setLocalVolume(data.volume.level);
          }
          if (data.hasOwnProperty('eq')) {
            if (data.eq.hasOwnProperty('values')) {
              this.EQValuesReceived(
                new EqualizerValues(null).setValues(data.eq.values)
              );
            } else {
              this.setLocalEq(data.eq.type, data.eq.level);
            }
          }
          if (data.hasOwnProperty('ng')) {
            this.setLocalNoiseGate(data.ng.level);
          }
          if (data.hasOwnProperty('audioMute')) {
            this.muteAudioByRemote(data.audioMute);
          }
          if (data.hasOwnProperty('videoMute')) {
            this.muteVideoByRemote(data.videoMute);
          }
          if (data.hasOwnProperty('hold')) {
            this.localHoldByRemote(data.hold);
          }

          if (data.hasOwnProperty('talking')) {
            this.remoteIsTalking(data.talking);
          }

          if (data.hasOwnProperty('videoVisualize')) {
            this.sendVisualizerData(data.videoVisualize);
          }
          if (data.hasOwnProperty('crop')) {
            this.setLocalVideoCrop(data.crop);
          }
        } else if (dataType === 'peerNotification') {
          const destAddress = data.destAddress;
          // const srcAddress = data.srcAddress;
          const nickname = data.nickname;
          this.receivedPeerNotofication(destAddress, nickname);
        } else if (dataType === 'nickname') {
          this.remoteNickName = data.nickname;
        } else if (dataType === 'remoteControlRequest') {
          if (!this.isRemoteControlled) {
            this.service.remoteControlRequested(this, data.message);
          } else {
            this.remoteControlRequestSendAnswer(true);
          }
        } else if (dataType === 'remoteControlResponse') {
          this.areRemoteControlling = data.message;

          this.remoteControlAnswerFunction(data.message);
        } else if (dataType === 'listDevicesRequest') {
          this.remoteListDevicesRequest(data.message);
        } else if (dataType === 'listDevicesResponse') {
          this.listDevicesAnswerFunction(data.message);
        } else if (dataType === 'setDeviceSettings') {
          this.processDeviceSettingsRequest(data.message);
        } else if (dataType === 'anlz') {
          if (this.visualizer) {
            const d = Uint8Array.from(data.data);
            this.visualizer.visualize(d);
          }
        } else if (dataType === 'videoMute') {
          let muted = <boolean>data.value;
          console.log('Video mute ' + muted);
          this.notifyListeners('track:video:' + (muted ? 'ended' : 'added'));
        } else if (dataType === 'audioMute') {
          let muted = <boolean>data.value;
          console.log('Audio mute ' + muted);
          this.notifyListeners('track:audio:' + (muted ? 'ended' : 'added'));
        } else {
          console.error('Invalid message type ', packet);
        }
      } else if (messageType === 'socket') {
        const data = JSON.parse(packet['data']);
        this.service.rtcSocketMessage(data);
      } else {
        console.error('Rtc: DataMessage: Unknown origin ' + messageType);
      }
    } catch (err) {
      console.error('RtcConnect: receivedDataMessage: error ', err);
    }
  }

  public sendChatMessage(message: string) {
    this.sendRawMessage(
      JSON.stringify({
        type: 'message',
        id: this.socket.connectionID,
        message: message
      }),
      'messages'
    );
  }

  // used by the AudioAnalyser
  sendRawMessage(message: any, origin: string) {
    const channel = this.channels.get(origin);
    if (channel) {
      try {
        channel.send(message);
      } catch (error) {
        let array = this.dataMessageQueue.get(origin);
        if (!array) {
          array = new Array();
          array.push(message);
          this.dataMessageQueue.set(origin, array);
        } else {
          array.push(message);
        }
      }
    } else {
      console.log('Rtc: sendRawMessage: Queuing message: No Channel');
      let array = this.dataMessageQueue.get(origin);
      if (!array) {
        array = new Array();
        array.push(message);
        this.dataMessageQueue.set(origin, array);
      } else {
        array.push(message);
      }
    }
  }

  private resendMessages(origin: string) {
    // console.log('Rtc: resendMessages ' + origin);
    const array = this.dataMessageQueue.get(origin);
    //  console.log(':Rtc: resendMessages: array ' + JSON.stringify(array));
    if (array) {
      array.forEach((message) => {
        this.sendRawMessage(message, origin);
      });
      array.length = 0;
      this.dataMessageQueue.delete(origin);
    }
  }

  public async requestRemoteControl(message: string) {
    const data = {
      type: 'remoteControlRequest',
      message: message,
      id: this.socket.connectionID
    };
    let p = new Promise((resolve) => {
      this.remoteControlAnswerFunction = resolve;
    });
    this.sendRawMessage(JSON.stringify(data), 'messages');
    return p;
  }

  public remoteControlRequestSendAnswer(answer: boolean) {
    const data = {
      type: 'remoteControlResponse',
      message: answer,
      id: this.socket.connectionID
    };
    this.sendRawMessage(JSON.stringify(data), 'messages');
  }

  public async listDevices() {
    const data = {
      type: 'listDevicesRequest',
      message: 'none',
      id: this.socket.connectionID
    };
    let p = new Promise((resolve) => {
      this.listDevicesAnswerFunction = resolve;
    });
    this.sendRawMessage(JSON.stringify(data), 'messages');
    return p;
  }

  private remoteListDevicesRequest(_message: string) {
    if (this.isRemoteControlled) {
      navigator.mediaDevices
        .enumerateDevices()
        .then((deviceInfos: Array<MediaDeviceInfo>) => {
          let ids = this.service.streamHandler.getDeviceIDs();
          ids.devicesInfos = deviceInfos;
          this.listDevicesSendAnswer(ids);
        })
        .catch((error) => {
          console.error(error);
          this.listDevicesSendAnswer({ error: error });
        });
    } else {
      console.warn('Remote control missing');
      this.listDevicesSendAnswer({ error: 'Remote control missing' });
    }
  }

  private listDevicesSendAnswer(deviceInfo: any) {
    const data = {
      type: 'listDevicesResponse',
      message: deviceInfo,
      id: this.socket.connectionID
    };
    this.sendRawMessage(JSON.stringify(data), 'messages');
  }

  public setRemoteDeviceSettings(settings: any) {
    const data = {
      type: 'setDeviceSettings',
      message: settings,
      id: this.socket.connectionID
    };
    this.sendRawMessage(JSON.stringify(data), 'messages');
  }

  private processDeviceSettingsRequest(message: any) {
    if (this.isRemoteControlled) {
      this.service.streamHandler.setDeviceIDs(message);
    } else {
      console.error('Not under Remote control');
    }
  }

  public remoteCtlDataMessage(packet: any, messageType: string) {
    this.receivedDataMessage(packet, messageType);
  }

  public setRemoteVolume(level: number) {
    if (level > 200 || level < 0) {
      console.error('Invalid Remote Volume ' + level);
      return;
    }
    const data = {
      type: 'levels',
      id: this.socket.connectionID,
      volume: {
        level: level
      }
    };
    this.sendRawMessage(JSON.stringify(data), 'messages');
  }

  private setLocalVolume(level: number) {
    this.analyser.setVolume(level);
    this.sendEQValues(this.analyser.getLocalLevels());
  }

  public setRemoteEq(type: string, level: number) {
    if (level > 40 || level < -40) {
      console.error('Invalid Remote EQ setting ' + type + ':' + level);
      return;
    }
    const data = {
      type: 'levels',
      id: this.socket.connectionID,
      eq: {
        level: level,
        type: type
      }
    };
    this.sendRawMessage(JSON.stringify(data), 'messages');
  }

  private setLocalEq(type: string, level: number) {
    if (level > 40 || level < -40) {
      console.error('Invalid Local EQ setting ' + type + ':' + level);
      return;
    }
    this.analyser.setEqValue(type, level);
    this.sendEQValues(this.analyser.getLocalLevels());
  }

  public setRemoteNoiseGate(level: number) {
    const data = {
      type: 'levels',
      id: this.socket.connectionID,
      ng: {
        level: level
      }
    };
    this.visualizer.noiseGateRemoteLevel(level);
    this.sendRawMessage(JSON.stringify(data), 'messages');
  }

  private setLocalNoiseGate(data: number) {
    this.analyser.noiseGateLevel(data);
    this.sendEQValues(this.analyser.getLocalLevels());
  }

  public setRemoteVideoCrop(cropSettings: CropSettings) {
    console.log('Set cropping ', cropSettings);
    const data = {
      type: 'levels',
      id: this.socket.connectionID,
      crop: cropSettings
    };
    this.sendRawMessage(JSON.stringify(data), 'messages');
    this.isRemoteVideoCropping = cropSettings;
  }

  private setLocalVideoCrop(cropSettings: CropSettings) {
    console.log('Set cropping ', cropSettings);
    if (this.isRemoteControlled) {
      this.rtc.setLocalVideoCrop(cropSettings);
    } else {
      console.error('Not under Remote control');
    }
  }

  public hold(hold: boolean) {
    this.onHold = hold;
    if (this.isHoldByRemote) {
      console.log('RtcConnection: localHold: remotely on hold');
      this.remoteHold(hold);
      return;
    } else {
      this.remoteHold(hold);
      this.localHold(hold);
    }
    this.isTalking = false;
  }

  private localHold(hold: boolean) {
    this.analyser.localHold(hold);
    if (this.inputStream && this.inputStream.getVideoTracks().length > 0) {
      console.log(this.inputStream);
      this.inputStream.getVideoTracks()[0].enabled = !hold;
    }
  }

  private localHoldByRemote(hold: boolean) {
    this.isHoldByRemote = hold;
    if (!hold && this.onHold) {
      console.log(
        'RtcConnection: localHoldByRemote: locally on hold: ignoring'
      );
      return;
    }
    this.localHold(hold);
  }

  public muteRemoteAudio(mute: boolean) {
    const data = {
      type: 'levels',
      id: this.socket.connectionID,
      audioMute: mute
    };
    this.sendRawMessage(JSON.stringify(data), 'messages');
    this.isTalking = false;
  }

  private remoteHold(mute: boolean) {
    const data = { type: 'levels', id: this.socket.connectionID, hold: mute };
    this.sendRawMessage(JSON.stringify(data), 'messages');
  }

  private muteAudioByRemote(muted: boolean) {
    if (this.audioMutedByRemote === muted) {
      return;
    }
    this.audioMutedByRemote = muted;
    if (this.streamHandler.masterAudioMute || this.localAudioMuted) {
      return;
    }
    this.doAudioMute(muted);
  }

  private async doAudioMute(mute: boolean) {
    console.log('RtcConnection: doAudioute:' + mute);
    if (!this.inputStream || !this.peerConnection) {
      return;
    }
    try {
      const atrans = this.getTransceiverByKind('audio');
      if (!atrans) {
        console.error('no audio tranceiver');
        return;
      }
      let sender = atrans.sender;
      console.log('sender', sender);
      if (mute) {
        sender.replaceTrack(null);
      } else {
        let track = this.inputStream.getAudioTracks()[0];
        if (!track) {
          console.error('No audio track to unmute', new Error());
        }

        if (!sender) {
          this.peerConnection.addTrack(track);
        } else {
          sender.replaceTrack(track);
        }
      }
    } catch (error) {
      console.error(error, error.stack);
    }
    this.isTalking = false;
    const data = {
      type: 'levels',
      id: this.socket.connectionID,
      talking: false
    };
    this.sendRawMessage(JSON.stringify(data), 'messages');
    if (this.iOS) {
      this.negotiationneeded();
    }
    this.sendRawMessage(
      JSON.stringify({
        type: 'audioMute',
        value: mute,
        id: this.socket.connectionID
      }),
      'messages'
    );
  }

  public muteRemoteVideo(mute: boolean) {
    const data = {
      type: 'levels',
      id: this.socket.connectionID,
      videoMute: mute
    };
    this.sendRawMessage(JSON.stringify(data), 'messages');
  }

  private muteVideoByRemote(muted: boolean) {
    if (this.videoMutedByRemote === muted) {
      return;
    }
    this.videoMutedByRemote = muted;
    if (this.streamHandler.masterVideoMute || this.localVideoMuted) {
      return;
    }
    this.doVideoMute(muted);
  }

  masterMuteLocalAudio(mute: boolean) {
    if (!mute) {
      this.localAudioMuted = false;
    }
    if (this.localAudioMuted || this.audioMutedByRemote) {
      console.log('Master audio mute, local or remote mute');
      return;
    } else {
      this.doAudioMute(mute);
    }
    this.isTalking = false;
  }

  masterMuteLocalVideo(mute: boolean) {
    console.log('RTCConnectionImpl: masterMuteLocalVideo ', mute);
    if (!mute) {
      this.localVideoMuted = false;
    }
    if (this.localVideoMuted || this.videoMutedByRemote) {
      return;
    } else {
      this.doVideoMute(mute);
    }
  }

  private async doVideoMute(mute: boolean) {
    console.log('RtcConnection: doVideoMute:' + mute);
    if (!this.inputStream || !this.peerConnection) {
      return;
    }
    try {
      const trans = this.getTransceiverByKind('video');
      if (!trans) {
        console.warn('No video tranceiver');
        return;
      }
      let sender = trans.sender;
      if (mute) {
        this.sendRawMessage(
          JSON.stringify({
            type: 'videoMute',
            value: mute,
            id: this.socket.connectionID
          }),
          'messages'
        );
        sender.replaceTrack(null);
      } else {
        //    console.log(sender);
        let track = this.inputStream.getVideoTracks()[0];
        if (!sender) {
          this.peerConnection.addTrack(track);
        } else {
          sender.replaceTrack(track);
        }
        this.sendRawMessage(
          JSON.stringify({
            type: 'videoMute',
            value: mute,
            id: this.socket.connectionID
          }),
          'messages'
        );
      }

      if (this.iOS) {
        this.negotiationneeded();
      }
    } catch (error) {
      console.error(error, error.stack);
    }
  }

  public startRemoteVisualizer(start: boolean) {
    const data = {
      type: 'levels',
      id: this.socket.connectionID,
      videoVisualize: start
    };
    this.sendRawMessage(JSON.stringify(data), 'messages');
  }

  private sendVisualizerData(send: boolean) {
    this.analyser.sendLevelData(send);
  }

  private EQValuesReceived(values: EqualizerValues) {
    this.analyser.setRemoteEqValues(values);
  }

  sendEQValues(values: EqualizerValues) {
    const data = {
      type: 'levels',
      id: this.socket.connectionID,
      eq: {
        values: values
      }
    };
    this.sendRawMessage(JSON.stringify(data), 'messages');
  }

  private sendIce(ice: string) {
    const packet = {
      index: this.index,
      type: 'ice',
      data: ice
    };
    this.socket.send(JSON.stringify(packet));
  }

  private sendOffer(offer: RTCSessionDescription) {
    //  console.log('Rtc: sendOffer ', offer.sdp);
    const packet = {
      index: this.index,
      type: 'offer',
      data: {
        offer: offer,
        bw: this.bandwidth
      }
    };
    this.socket.send(JSON.stringify(packet));
  }

  private dispose() {
    if (!this.disposed) {
      this.disposed = true;
      this.analyser = null;
      this.visualizer = null;
      this.listeners.length = 0;
      this.listeners = null;

      if (this.inputStream) {
        try {
          const tracks = this.inputStream.getAudioTracks();
          tracks.forEach((track) => {
            track.stop();
          });
        } catch (error) {
          console.error('Rtc: error disposing ' + error);
        }
        try {
          const vtracks = this.inputStream.getVideoTracks();
          vtracks.forEach((track) => {
            track.stop();
          });
        } catch (error) {
          console.error('Rtc: error disposing ' + error);
        }
        this.inputStream = null;
      }
      if (this.peerConnection) {
        try {
          this.peerConnection.getSenders().forEach((sendr) => {
            this.peerConnection.removeTrack(sendr);
          });
        } catch (err) {
          console.error(
            'RtcConnection: dispose: removing receivers error ',
            err
          );
        }
        try {
          this.peerConnection.close();
        } catch (error) {
          console.error('Rtc: error disposing ' + error);
        }
        this.peerConnection = undefined;
      }
    }
  }

  private error(type: string, message: string) {
    console.error('RTC error: ' + this.index + ' - ' + type + ' : ' + message);
    if (
      message ===
      'OperationError: Failed to set remote answer sdp: Called in wrong state: kStable'
    ) {
      console.warn('RTC error: ignoring');
    }
  }

  /**
   * Called by local user when they accept the call
   */
  public async acceptConnection() {
    if (this.isBeingCalled) {
      await this.service.api.getIceConfiguration().then(async (ret: any) => {
        await this.webRtcInit(ret.data);
      });
      this.socket.send(
        JSON.stringify({
          index: this.index,
          mode: this.rtc.mode,
          type: 'accept'
        })
      );
    } else {
      console.error('Rtc: Do not call connect when isitiating a call');
    }
  }

  /**
   * Called by PC event handler after Ice connection is established
   */
  private connected() {
    this.peerConnection.onnegotiationneeded = (_ev: Event) => {
      this.negotiationneeded();
    };
    if (!this.analyser) {
      this.analyser = this.rtc.getAnalyser();
    }
    this.analyser.startAnalyser();
    if (!this.isBeingCalled && this.joinGroup) {
      this.service.sendGroupPeers(this.rtc);
    }
    this.setBandwidth(this.bandwidth);
    // let stats = (<RTCPeerConnection>this.peerConnection).localDescription;
  }

  public close() {
    if (this.closed) {
      return;
    }
    this.closed = true;
    try {
      this.notifyListeners('closed');
      this.dispose();
    } catch (err) {
      console.error(err);
    }
  }
}

export interface RtcListener {
  rtcMessage(message: string, connection: RtcConnection): void;
  rtcConnectionRequest(address: string, connection: RtcConnection): void;
  rtcClosed(connection: RtcConnection): void;
  rtcStateChange(state: string): void;
  rtcSoundLevelChange(level: number): void;
  rtcTalking(talking: boolean): void;
}

export class RTC
  implements RtcConnection, SocketListener, AudioAnalyserCtlr, RtcListener
{
  public outputStream: MediaStream = new MediaStream();
  private ChMain: RtcConnectionImpl = null;
  private ChB: RtcConnectionImpl = null;
  private dataChannel: RtcConnectionImpl = null;
  private proxy: RtcProxyConnection = null;
  private initialized = false;
  private accepted = false;
  private listeners: RtcListener[] = [];
  remoteMode = 'RXTX';
  destinationID: string;
  mainstream: MediaStream;
  private state = 'connecting';
  private closed: boolean;
  private analyser: AudioAnalyser;
  inputStream: MediaStream; // after analyser
  private connectionTimeoutTimer: NodeJS.Timeout;
  trackState: string;
  visualizer: VisualizerImpl;

  constructor(
    private socket: Socket,
    public service: RtcService,
    public isBeingCalled: boolean,
    public joinGroup: boolean,
    public remoteNickName: string,
    public mode: string,
    private streamHandler: StreamhandlerService,
    private enableAudio: boolean,
    private enableVideo: boolean
  ) {
    this.destinationID = socket.getDestination();
    service.sockets.set(this.destinationID, socket);
    socket.addListener(this);
    this.connectionTimeoutTimer = setTimeout(() => {
      console.log('TimeOut ');
      this.close();
    }, 90000); // one and a half minutes
    // we need to capture the accept to get the remote mode when we are initiating
    // this.tx = new RtcConnectionImpl(socket, service, isBeingCalled, joinGroup, remoteNickName, this, 1, 'TX');
    // this.tx.addListener(this);
  }

  public async init(): Promise<any> {
    this.visualizer = new VisualizerImpl();
    let header = JSON.parse(this.socket.getHeader());
    if (this.isBeingCalled) {
      console.log('RTC: init: header', header);
      this.remoteMode = header.mode;
      if (this.remoteMode === 'proxy') {
        // remote is proxy console.log('New RTC',socket);
        if (this.mode !== 'proxy') {
          // we are not a  proxy so use token passed
          //  this.ChB = new RtcConnectionImpl(this.socket, this.service, this.isBeingCalled,
          //    this.joinGroup, this.remoteNickName, this, 1, 'TX');
          //  this.ChB.addListener(this);

          // await this.getUserMedia('TX');
          try {
            this.proxy = await this.service.proxySvc.getProxy(
              this.socket,
              1,
              this.service,
              this.streamHandler.masterAudioMute,
              this.streamHandler.masterVideoMute,
              header.proxyData
            );
            this.proxy.addListener(this);
            this.mode = 'proxy';
            this.analyser = this.proxy.getAnalyser();
          } catch (err) {
            this.proxyError();
            return this.init();
          }
        } else {
          // we are a proxy
          //   await this.getUserMedia('TX');
          try {
            this.proxy = await this.service.proxySvc.getProxy(
              this.socket,
              1,
              this.service,
              this.streamHandler.masterAudioMute,
              this.streamHandler.masterVideoMute,
              null
            );
            this.proxy.addListener(this);
            this.analyser = this.proxy.getAnalyser();
          } catch (err) {
            this.proxyError();
            return this.init();
          }
        }
        this.ChMain = new RtcConnectionImpl(
          this.socket,
          this.service,
          this.streamHandler,
          this.isBeingCalled,
          this.joinGroup,
          this.remoteNickName,
          this,
          0,
          'RX',
          this.enableAudio,
          this.enableVideo
        );
        this.ChMain.addListener(this);
      } else {
        // remote not proxy
        if (this.mode === 'proxy') {
          // We are proxy, connect proxy TX
          // await this.getUserMedia('TX');

          try {
            this.proxy = await this.service.proxySvc.getProxy(
              this.socket,
              1,
              this.service,
              this.streamHandler.masterAudioMute,
              this.streamHandler.masterVideoMute,
              null
            );
            this.proxy.addListener(this);
            this.analyser = this.proxy.getAnalyser();
          } catch (err) {
            this.proxyError();
            return this.init();
          }

          this.ChMain = new RtcConnectionImpl(
            this.socket,
            this.service,
            this.streamHandler,
            this.isBeingCalled,
            this.joinGroup,
            this.remoteNickName,
            this,
            0,
            'RX',
            this.enableAudio,
            this.enableVideo
          );
          this.ChMain.addListener(this);
        } else {
          // neither proxy
          this.ChMain = new RtcConnectionImpl(
            this.socket,
            this.service,
            this.streamHandler,
            this.isBeingCalled,
            this.joinGroup,
            this.remoteNickName,
            this,
            0,
            'TX',
            this.enableAudio,
            this.enableVideo
          );
          this.ChMain.addListener(this);
        }
      }
    } else {
      // we are calling
      if (this.mode === 'proxy') {
        //  await this.getUserMedia('TX');
        console.log('RTC: init: colling: got media');
        try {
          this.proxy = await this.service.proxySvc.getProxy(
            this.socket,
            0,
            this.service,
            this.streamHandler.masterAudioMute,
            this.streamHandler.masterVideoMute,
            null
          );
          this.proxy.addListener(this);
          this.analyser = this.proxy.getAnalyser();
        } catch (err) {
          console.error(err);
          this.proxyError();
          return this.init();
        }
        console.log('RTC: init: calling: got proxy service');
        this.ChMain = new RtcConnectionImpl(
          this.socket,
          this.service,
          this.streamHandler,
          this.isBeingCalled,
          this.joinGroup,
          this.remoteNickName,
          this,
          1,
          'RX',
          this.enableAudio,
          this.enableVideo
        );
        this.ChMain.addListener(this);
      } else {
        this.ChMain = new RtcConnectionImpl(
          this.socket,
          this.service,
          this.streamHandler,
          this.isBeingCalled,
          this.joinGroup,
          this.remoteNickName,
          this,
          0,
          'TX',
          this.enableAudio,
          this.enableVideo
        );
        this.ChMain.addListener(this);

        // if otherside is proxy we will not know until they accept. main  channel might be B, assume A for now
      }
      console.log('RTC: init: calling socket connect()');
      this.socket.connect();
    }
    if (this.ChB) {
      this.dataChannel = this.ChB;
    } else {
      this.dataChannel = this.ChMain;
    }
    this.initialized = true;
    if (this.accepted) {
      this.acceptConnection();
    }
  }

  private proxyError() {
    this.proxy = null;
    this.mode = 'p2p';
    // setTimeout(() => { this.socket.close(); }, 500);
    const message = {
      type: 'warning',
      message: 'Server Error: Switching to Peer to Peer mode',
      timeOut: 5
    };
    this.service.systemBus.emit(message, 'warning');
    let header = JSON.parse(this.socket.getHeader());
    header.mode = 'p2p';
    this.socket.setHeader(JSON.stringify(header));
  }

  async processProxyDataMessage(event: any, type: string) {
    this.ChMain.receivedDataMessage(event, type);
  }

  async getUserMedia(rtcmode: string) {
    if (rtcmode === 'RX') {
      return new MediaStream();
    }
    if (!this.mainstream) {
      this.mainstream = await this.service.getUserMedia();
      this.initGetUserMediaStreams(this.mainstream);
    }
    return this.inputStream;
  }

  private initGetUserMediaStreams(stream: MediaStream) {
    const vtracks = stream.getVideoTracks();
    if (vtracks.length > 0) {
      this.inputStream = new MediaStream(vtracks);
    }

    const atracks = stream.getAudioTracks();
    if (atracks.length > 0) {
      this.analyser = new AudioAnalyserImpl(
        this.streamHandler.audioContext,
        this,
        this.streamHandler
      );
      let audioLine = this.service.audioContext.createMediaStreamDestination();
      this.analyser.connect(atracks, audioLine);
      if (!this.inputStream) {
        this.inputStream = audioLine.stream;
      } else {
        this.inputStream.addTrack(audioLine.stream.getAudioTracks()[0]);
      }
    } else {
      console.warn('RtcConnection: initGetUserMedia: no audio');
      this.analyser = new NoAudioAnalyserImpl();
    }
    if (!this.inputStream) {
      this.inputStream = stream;
    }
    const canvas = <HTMLCanvasElement>(
      document.getElementById('visualizer-' + this.socket.getDestination())
    );
    if (canvas) {
      this.visualizer.setCanvas(canvas);
    }
  }

  rtcMessage(message: string, _connection: RtcConnection): void {
    this.listeners.forEach((listener) => {
      listener.rtcMessage(message, this);
    });
  }

  rtcConnectionRequest(address: string, _connection: RtcConnection): void {
    this.listeners.forEach((listener) => {
      listener.rtcConnectionRequest(address, this);
    });
  }

  rtcTalking(talking: boolean) {
    this.listeners.forEach((listener) => {
      listener.rtcTalking(talking);
    });
  }

  rtcClosed(_connection: RtcConnection): void {
    try {
      this.listeners.forEach((listener) => {
        listener.rtcClosed(this);
      });
    } catch (err) {
      console.error(err);
    }
    this.close();
  }

  rtcStateChange(state: string): void {
    if (state === 'added') {
      console.error(new Error().stack);
    }
    if (this.closed) {
      console.warn('RTC Allready closed');
      return;
    }
    if (state.startsWith('track:')) {
      if (this.trackState !== state) {
        this.listeners.forEach((listener) => {
          listener.rtcStateChange(state);
        });
        this.trackState = state;
      } else {
        console.warn('State change ignored', state);
      }
    } else if (state !== this.state) {
      if (state === 'failed' || state === 'closed') {
        this.state = state;
        this.listeners.forEach((listener) => {
          listener.rtcStateChange(this.state);
        });
        this.close();
      } else {
        let mstate = this.ChMain.getState();

        let pstate = mstate;
        let bstate = mstate;
        if (this.proxy) {
          pstate = this.proxy.getState();
        }
        if (this.ChB) {
          bstate = this.ChB.getState();
        }
        if (mstate === pstate && mstate === bstate) {
          this.state = mstate;
          this.listeners.forEach((listener) => {
            listener.rtcStateChange(this.state);
          });
          return;
        } else {
          this.state = 'connecting';
        }
        if (this.state === 'connected') {
          this.sendEQValues(this.analyser.getLocalLevels());
        }
        this.listeners.forEach((listener) => {
          listener.rtcStateChange(this.state);
        });
      }
    } else {
      console.warn('State change ignored', state);
    }
  }

  rtcSoundLevelChange(level: number): void {
    this.listeners.forEach((listener) => {
      listener.rtcSoundLevelChange(level);
    });
  }

  socketOnRecieve(data: string, socket: Socket): void {
    const packet = JSON.parse(data);
    //   console.log('RTC: socketOnRecieve: Packet=', packet);
    if (packet.type === 'accept') {
      try {
        clearTimeout(this.connectionTimeoutTimer);
      } catch (error) {
        console.error('Rtc: error disposing ' + error);
      }
      let remoteMode = packet.mode;
      this.remoteMode = remoteMode;
      if (remoteMode === 'proxy' && this.mode !== 'proxy') {
        this.ChB = this.ChMain;
        this.ChMain = new RtcConnectionImpl(
          socket,
          this.service,
          this.streamHandler,
          this.isBeingCalled,
          this.joinGroup,
          this.remoteNickName,
          this,
          1,
          'RX',
          this.enableAudio,
          this.enableVideo
        );
        this.ChMain.addListener(this);
      }
    }
    if (!this.ChMain.disposed) {
      this.ChMain.processRtcReceivedConnectionPacket(packet);
    }
    if (this.ChB && !this.ChB.disposed) {
      this.ChB.processRtcReceivedConnectionPacket(packet);
    }
    if (this.proxy && !this.proxy.disposed) {
      this.proxy.processRtcReceivedConnectionPacket(packet);
    }
  }

  socketRemotelyClosed(): void {
    this.close();
  }

  socketClosed(): void {
    this.close();
  }

  // public setAreRemoteControlling(answer: boolean): void {
  //     this.dataChannel.setAreRemoteControlling(answer);
  // }

  public setIsRemoteControlledAnswer(answer: boolean): void {
    this.dataChannel.setIsRemoteControlledAnswer(answer);
  }

  public getDestinationID(): string {
    return this.destinationID;
  }
  public getIsBeingCalled(): boolean {
    return this.dataChannel.getIsBeingCalled();
  }
  public getState(): string {
    return this.dataChannel.getState();
  }
  public getIsRemoteControlled(): boolean {
    return this.dataChannel.getIsRemoteControlled();
  }
  public getRemoteNickName(): string {
    return this.dataChannel.getRemoteNickName();
  }

  public getIsTalking(): boolean {
    return this.dataChannel.getIsTalking();
  }
  public getAreRemoteControlling(): boolean {
    return this.dataChannel.getAreRemoteControlling();
  }

  setRemoteDeviceSettings(ret: any): void {
    this.dataChannel.setRemoteDeviceSettings(ret);
  }
  getStats(): Promise<any> {
    return this.dataChannel.getStats();
  }
  setRemoteVolume(volume: number): void {
    this.dataChannel.setRemoteVolume(volume);
  }
  setRemoteEq(arg0: string, val: number): void {
    this.dataChannel.setRemoteEq(arg0, val);
  }
  setRemoteNoiseGate(val: number): void {
    this.dataChannel.setRemoteNoiseGate(val);
  }
  public addListener(listener: RtcListener): void {
    this.listeners.push(listener);
  }

  public removeListener(listener: RtcListener) {
    this.listeners.splice(this.listeners.indexOf(listener), 1);
  }

  listDevices(): Promise<unknown> {
    return this.dataChannel.listDevices();
  }
  sendEQValues(arg0: EqualizerValues): void {
    this.dataChannel.sendEQValues(arg0);
  }
  sendRawMessage(dataArray: Uint8Array, arg1: string): void {
    this.dataChannel.sendRawMessage(dataArray, arg1);
  }
  remoteControlRequestSendAnswer(answer: boolean): void {
    this.dataChannel.remoteControlRequestSendAnswer(answer);
  }
  sendPeerNotification(destinationID: string, remoteNickName: string): void {
    this.dataChannel.sendPeerNotification(destinationID, remoteNickName);
  }
  remoteCtlDataMessage(packet: any, messageType: string): void {
    this.dataChannel.remoteCtlDataMessage(packet, messageType);
  }

  sendTalking(talking: boolean): void {
    this.dataChannel.sendTalking(talking);
  }

  streamHandlerOnStreamChangeEvent(stream: MediaStream): void {
    if (!this.proxy) {
      this.dataChannel.streamHandlerOnStreamChangeEvent(stream);
    }
    if (this.analyser) {
      this.analyser.reConnect(stream.getAudioTracks());
    }
  }

  setLocalVideoCrop(cropSettings: CropSettings) {
    this.streamHandler.setVideoCrop(cropSettings);
  }

  public setRemoteVideoCrop(cropSettings: CropSettings) {
    this.dataChannel.setRemoteVideoCrop(cropSettings);
  }

  public getIsRemoteVideoCropping(): CropSettings {
    return this.dataChannel.getIsRemoteVideoCropping();
  }

  hold(hold: boolean): void {
    this.dataChannel.hold(hold);
  }

  close(): void {
    if (this.closed) {
      return;
    }
    this.closed = true;
    this.service.systemBus.emit(this.destinationID, 'rtc/connection/closed');
    try {
      clearTimeout(this.connectionTimeoutTimer);
    } catch (error) {
      console.error('Rtc: error disposing ' + error);
    }

    try {
      this.socket.close();
      this.socket = null;

      if (this.ChMain) {
        this.ChMain.close();
        this.ChMain = null;
      }
      if (this.proxy) {
        this.proxy.close();
        this.proxy = null;
      }
      if (this.ChB) {
        this.ChB.close();
        this.ChB = null;
      }
      if (this.analyser) {
        this.analyser.dispose();
        this.analyser = null;
      }
      this.visualizer = null;
    } finally {
      try {
        this.service.rtcConnectionClosed(this, this.mainstream);
      } catch (error) {
        console.error('Rtc: error disposing ' + error);
      }
      try {
        this.listeners.forEach((listener) => {
          listener.rtcClosed(this);
        });
      } catch (error) {
        console.error('Rtc: error disposing ' + error);
      }
    }
  }
  requestRemoteControl(message: string): Promise<any> {
    return this.dataChannel.requestRemoteControl(message);
  }

  sendChatMessage(message: string): void {
    this.dataChannel.sendChatMessage(message);
  }

  masterMuteLocalAudio(mute: boolean): void {
    if (this.proxy) {
      this.dataChannel.sendRawMessage(
        JSON.stringify({
          type: 'audioMute',
          value: mute,
          id: this.socket.connectionID
        }),
        'messages'
      );
    } else {
      this.dataChannel.masterMuteLocalAudio(mute);
    }
  }

  masterMuteLocalVideo(mute: boolean): void {
    console.log('RTC: masterMuteLocalVideo ', mute);
    if (this.proxy) {
      this.dataChannel.sendRawMessage(
        JSON.stringify({
          type: 'videoMute',
          value: mute,
          id: this.socket.connectionID
        }),
        'messages'
      );
    } else {
      this.dataChannel.masterMuteLocalVideo(mute);
    }
  }

  muteRemoteVideo(mute: boolean): void {
    this.dataChannel.muteRemoteVideo(mute);
  }

  muteRemoteAudio(mute: boolean): void {
    this.dataChannel.muteRemoteAudio(mute);
  }

  setBandwidth(bw: number): void {
    if (this.ChMain) {
      this.ChMain.setBandwidth(bw);
    }

    if (this.ChB) {
      this.ChB.setBandwidth(bw);
    }
  }

  public acceptConnection(): void {
    if (this.accepted) {
      return;
    }
    try {
      clearTimeout(this.connectionTimeoutTimer);
    } catch (error) {
      console.error('Rtc: error disposing ' + error);
    }

    this.accepted = true;
    if (this.initialized) {
      this.socket.accept();
      if (this.ChMain) {
        this.ChMain.acceptConnection();
      }
      if (this.ChB) {
        this.ChB.acceptConnection();
      }
    } else {
      console.error('RTC: not initialized yet');
    }
  }
  getOutputStream(): MediaStream {
    let stream = this.outputStream;
    return stream;
  }
  getAnalyser(): AudioAnalyser {
    return this.analyser;
  }
  getVisualizer(): Visualizer {
    return this.visualizer;
  }
  startRemoteVisualizer(start: boolean): void {
    this.ChMain.startRemoteVisualizer(start);
  }
}

export class RtcSocketListenerCallback implements SocketListenerCallback {
  constructor(public service: RtcService) {}

  /**
   * Called when we are being called. To accept, something has to pass this socket to connectSocket
   */
  socketListenCallback(socket: Socket) {
    let autoConnect = false;
    let header: any;
    try {
      header = JSON.parse(socket.getHeader());
      autoConnect = header.autoConnect;
    } catch (err) {
      console.error(
        'RtcService: socketListerCallback: header parse error ',
        err
      );
      console.error(
        'RtcService: socketListerCallback:  header parse error ',
        socket
      );
      return;
    }

    console.log('Recieved RTC connection: AutoConnect ' + autoConnect);
    if (autoConnect) {
      let nickname = header.nickname;
      // TODO should have a secret that we check before connecting
      let videoEnabled = !this.service.streamHandler.masterVideoMute;
      this.service.connectSocket(socket, false, nickname, videoEnabled);
    } else {
      this.service.systemBus.emit(socket, 'rtc/connection/request');
    }
  }
}

@Injectable()
export class RtcService implements StreamChangeEventListener, MessageObserver {
  public masterHold = false;
  private mode = 'p2p';
  private setBWTimer: NodeJS.Timer;
  public bandwidthKb = 1024; // 2000 = unlimited
  private shareBandwidth = true;
  public audioContext: AudioContext;
  public rtcConnections: RtcConnection[] = [];
  public rtcChatMessages: RtcChatMessage[] = [];
  public sockets: Map<string, Socket> = new Map(); // key is address
  public masterVolume = 0.5;
  public contacts: any[];
  public contactSvc: any;
  public ourNickname = 'rtcunknown'; // value set by contact service

  constructor(
    public network: NetworkService,
    public systemBus: SystemBusService,
    public api: EqcallapiService,
    public ngZone: NgZone,
    public proxySvc: RtcproxyService,
    public streamHandler: StreamhandlerService,
    public config: ConfigService
  ) {
    systemBus.subscribe(this);
  }

  onBusMessage(message: any, type: string): void {
    if (type === 'rtc/masterAudioMute') {
      this.setMasterAudioMute(message);
    } else if (type === 'rtc/masterVideoMute') {
      this.setMasterVideoMute(message);
    } else if (type === 'streamHandler/Initialized') {
      this.audioContext = this.streamHandler.audioContext;
      this.streamHandler.webAudioUnlock();
      this.network.listen('Rtc', new RtcSocketListenerCallback(this));
      this.updateSavedValues();
      this.streamHandler.getVuMeter().setTalkingListener(this);
      this.streamHandler.addStreamChangeEventListener(this);
    } else {
      console.error('Unhandeled messagetype ', type);
    }
  }
  busMessageFilter(messageType: string): boolean {
    return (
      messageType === 'rtc/masterAudioMute' ||
      messageType === 'rtc/masterVideoMute' ||
      messageType === 'streamHandler/Initialized'
    );
  }

  public async getMode() {
    if (this.proxySvc.isProxying()) {
      console.warn('Proxy Service is running');
      this.mode = 'proxy';
      return this.mode;
    }
    await this.api
      .getPermissions()
      .then((data: any) => {
        let perms = <Array<string>>data.data;
        if (perms.includes('PROXY')) {
          this.mode = 'proxy';
        } else {
          this.mode = 'p2p';
        }
      })
      .catch((_err) => {
        this.mode = 'p2p';
      });
    console.log('RtcService: getMode ' + this.mode);
    return this.mode;
  }

  public remoteControlRequested(connection: RtcConnection, message: string) {
    this.systemBus.emit(
      { message: message, connection: connection },
      'rtc/remoteControl/request'
    );
  }

  public answerRemoteControlRequest(
    answer: boolean,
    connection: RtcConnection
  ) {
    connection.setIsRemoteControlledAnswer(answer);
    this.rtcConnections.forEach((c) => {
      if (c.getDestinationID() !== connection.getDestinationID()) {
        c.setIsRemoteControlledAnswer(false); // only one remote controller
      }
    });
    connection.remoteControlRequestSendAnswer(answer);
  }

  public async remoteCtlDataMessage(packet: any, messageType: string) {
    const data = JSON.parse(packet['data']);
    const dataType = data.type;
    if (messageType === 'messages' && dataType === 'levels') {
      if (
        data.hasOwnProperty('volume') ||
        data.hasOwnProperty('eq') ||
        data.hasOwnProperty('ng') ||
        data.hasOwnProperty('audioMute') ||
        data.hasOwnProperty('videoMute') ||
        data.hasOwnProperty('hold')
      ) {
        this.rtcConnections.forEach((connection) => {
          if (!connection.getIsRemoteControlled()) {
            connection.remoteCtlDataMessage(packet, messageType);
          }
        });
      }
    }
  }

  public async talking(talking: boolean) {
    // console.log('talking ' + talking);
    try {
      if (!this.streamHandler.masterAudioMute && !this.masterHold) {
        for (let rtc of this.rtcConnections) {
          rtc.sendTalking(talking);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  public setShareBandwidth(shareBW: boolean) {
    this.shareBandwidth = shareBW;
    this.fixBandwidth();
  }

  public streamHandlerOnStreamChangeEvent(stream: MediaStream) {
    console.log('RTCService: StreamHAndlerOnStreamChangeEvent');
    this.proxySvc.streamHandlerOnStreamChangeEvent(stream);
    for (let rtc of this.rtcConnections) {
      rtc.streamHandlerOnStreamChangeEvent(stream);
    }
  }

  public getMasterHold(): boolean {
    return this.masterHold;
  }

  public isSharedBandWidth(): boolean {
    return this.shareBandwidth;
  }

  public setMasterHold(hold: boolean) {
    this.masterHold = hold;
    for (let connection of this.rtcConnections) {
      connection.hold(hold);
    }
  }

  public async getUserMedia(): Promise<MediaStream> {
    return this.streamHandler.getMediaStream();
  }

  setContacts(contacts: any) {
    this.contacts = contacts;
  }

  public endAllCalls() {
    for (let connection of this.rtcConnections) {
      setTimeout(() => {
        connection.close();
      }, 1000);
    }
  }

  rtcConnectionClosed(rtcConnection: RtcConnection, mediaStream: MediaStream) {
    this.rtcConnections.splice(this.rtcConnections.indexOf(rtcConnection), 1);
    this.systemBus.emit(rtcConnection, 'rtc/connection/removed');
    this.sockets.delete(rtcConnection.getDestinationID());
    this.streamHandler.returnMediaStream(mediaStream);
    this.fixBandwidth();
    if (this.rtcConnections.length === 0) {
      this.setMasterVideoMute(true);
    }
  }

  public sendChatMessageToAll(message: string) {
    const rtcMessage = new RtcChatMessage('You', message, true);
    this.rtcChatMessages.push(rtcMessage);
    for (let connection of this.rtcConnections) {
      connection.sendChatMessage(message);
    }
  }

  newRtcChatRecieved(message: string, connection: RtcConnection) {
    const rtcMessage = new RtcChatMessage(
      connection.getRemoteNickName(),
      message,
      false
    );
    this.rtcChatMessages.push(rtcMessage);
    this.systemBus.emit(this, 'rtc/chatMessage/new');
  }

  public setAudioInDevice(deviceID: string) {
    this.streamHandler.setAudioInDevice(deviceID);
  }

  public setAudioOutDevice(deviceID: string) {
    this.streamHandler.setAudioOutDevice(deviceID);
  }

  public setVideoInDevice(deviceID: string) {
    this.streamHandler.setVideoInDevice(deviceID);
  }

  public shareDesktop(share: boolean, audio: boolean) {
    this.streamHandler.shareDesktop(share, audio);
  }

  public setVideoResolution(width: number, height: number) {
    this.streamHandler.setVideoResolution(width, height);
  }

  private updateSavedValues() {
    if (typeof Storage !== 'undefined') {
      const volume = this.config.getItem('masterVolume');
      if (volume) {
        this.setMasterVolume(Number(volume) * 100);
      }
      let val = this.config.getItem('masterBandwidth');
      if (val) {
        this.setBandwidth(Number(val));
      }
    }
  }

  public setMasterVolume(level: number) {
    this.masterVolume = level / 100.0;
    const allVids = document.querySelectorAll('video');
    for (let i = 0; i < allVids.length; ++i) {
      allVids[i].volume = this.masterVolume;
    }
    if (typeof Storage !== 'undefined') {
      this.config.setItem('masterVolume', String(this.masterVolume));
    }
  }

  public getMasterVolume(): number {
    return this.masterVolume * 100;
  }

  private setMasterAudioMute(mute: boolean) {
    this.proxySvc.muteAudio(mute);
    if (this.streamHandler.shareingDesktop && this.streamHandler.micShareGain) {
      if (mute) {
        this.streamHandler.desktopShareGain.gain.linearRampToValueAtTime(
          1.0,
          this.audioContext.currentTime + 1
        );
        this.streamHandler.micShareGain.gain.linearRampToValueAtTime(
          0.0,
          this.audioContext.currentTime + 1
        );
      } else {
        this.streamHandler.micShareGain.gain.linearRampToValueAtTime(
          1.0,
          this.audioContext.currentTime + 1
        );
        this.streamHandler.desktopShareGain.gain.linearRampToValueAtTime(
          0.2,
          this.audioContext.currentTime + 1
        );

        if (this.streamHandler.masterAudioMute) {
          // start sending audio if we unmute
          // ya fix later
          // console.log('Rtc: Master mute ' + false);
          this.rtcConnections.forEach((rtc) => {
            rtc.masterMuteLocalAudio(false);
          });
        }
      }
    } else {
      //  console.log('Rtc: Master mute ' + mute);
      this.rtcConnections.forEach((rtc) => {
        rtc.masterMuteLocalAudio(mute);
      });
    }
  }

  private setMasterVideoMute(mute: boolean) {
    // this.streamHandler.setMasterVideoMute(mute);
    this.proxySvc.muteVideo(mute);
    this.rtcConnections.forEach((rtc) => {
      rtc.masterMuteLocalVideo(mute);
    });
  }

  public setBandwidth(bandwidthKb: number) {
    if (bandwidthKb > 2000) {
      bandwidthKb = 2000;
    }
    this.bandwidthKb = bandwidthKb;

    if (typeof Storage !== 'undefined') {
      this.config.setItem('masterBandwidth', String(bandwidthKb));
    }
    clearTimeout(this.setBWTimer);
    this.setBWTimer = setTimeout(() => {
      this.fixBandwidth();
    }, 5000);
  }

  private fixBandwidth() {
    if (this.mode === 'p2p' && this.bandwidthKb < 1900) {
      let bw = this.bandwidthKb;
      if (this.shareBandwidth) {
        let len = this.rtcConnections.length;
        if (len > 1) {
          bw = bw / this.rtcConnections.length;
        }
      }
      for (let connection of this.rtcConnections) {
        connection.setBandwidth(bw);
      }
    } else {
      for (let connection of this.rtcConnections) {
        connection.setBandwidth(this.bandwidthKb);
      }
    }
    this.proxySvc.setBandwidth(this.bandwidthKb);
  }

  private haveConnection(destAddr: string): boolean {
    for (let connection of this.rtcConnections) {
      if (connection.getDestinationID() === destAddr) {
        return true;
      }
    }
    return false;
  }

  /**
   * Called to initiate a connection
   */
  public async connect(
    destAddress: string,
    srcAddress: string,
    joinGroup: boolean,
    remoteNickname: string,
    keyCode: string,
    enableVideo: boolean
  ): Promise<RtcConnection> {
    if (enableVideo) {
      if (this.rtcConnections.length === 0) {
        this.streamHandler.setMasterVideoMute(false);
      }
    } else {
      if (this.rtcConnections.length === 0) {
        this.streamHandler.setMasterVideoMute(true);
      }
    }

    const currentSocket = this.sockets.get(destAddress);
    if (!currentSocket) {
      let mode = await this.getMode();
      let header = {
        mode: mode,
        joinGroup: joinGroup,
        nickname: this.ourNickname,
        keyCode: keyCode,
        proxyData: <any>undefined
      };
      if (mode === 'proxy') {
        // get a proxy token for this contact
        let pdata = await this.api.getcontactProxy(
          destAddress,
          srcAddress,
          remoteNickname
        );
        header.proxyData = pdata;
      }
      console.warn('Proxy header =', header);

      const socket = this.network.connect(
        destAddress,
        srcAddress,
        'Rtc',
        JSON.stringify(header)
      );
      if (mode === 'proxy') {
        socket.setTimeout(30000);
      }
      const connection = new RTC(
        socket,
        this,
        false,
        joinGroup,
        remoteNickname,
        mode,
        this.streamHandler,
        true,
        enableVideo
      );
      await connection.init();
      this.rtcConnections.push(connection);
      this.fixBandwidth();
      this.systemBus.emit(connection, 'rtc/connection/new');
      // client will send an accept packet which will cause webRtcInit to be called on the RtcConnection
      return connection;
    } else {
      console.error('RTCService: connect: Already connected');
      this.rtcConnections.forEach((connection) => {
        if (connection.getDestinationID() === destAddress) {
          return connection;
        }
      });
    }
  }

  /*
   * Called to initialize connection after receiving connection request.
   * Still have to call acceptConnection on rtcConnection after videoElements have been created
   */
  public async connectSocket(
    socket: Socket,
    joinGroup: boolean,
    remoteNickname: string,
    enableVideo: boolean
  ) {
    if (this.haveConnection(socket.getDestination())) {
      socket.close();
      // TODO HAndle this better !!
      return;
    }
    if (enableVideo) {
      if (this.rtcConnections.length === 0) {
        this.streamHandler.setMasterVideoMute(false);
      }
    } else {
      if (this.rtcConnections.length === 0) {
        this.streamHandler.setMasterVideoMute(true);
      }
    }
    let mode = await this.getMode();
    const connection = new RTC(
      socket,
      this,
      true,
      joinGroup,
      remoteNickname,
      mode,
      this.streamHandler,
      true,
      enableVideo
    );
    await connection.init();
    this.rtcConnections.push(connection);
    this.fixBandwidth();
    this.systemBus.emit(connection, 'rtc/connection/new');
    if (connection.joinGroup) {
      this.sendGroupPeers(connection);
    }
  }

  public connectSocketDefaultVideo(
    socket: Socket,
    joinGroup: boolean,
    remoteNickname: string
  ) {
    this.connectSocket(
      socket,
      joinGroup,
      remoteNickname,
      !this.streamHandler.masterVideoMute
    );
  }

  public sendGroupPeers(connection: RtcConnection) {
    // send connection id to other connections
    const thiscogid = connection.getDestinationID();
    this.rtcConnections.forEach((rtcConnection) => {
      const id = rtcConnection.getDestinationID();
      if (id !== thiscogid) {
        connection.sendPeerNotification(id, rtcConnection.getRemoteNickName());
      }
    });
  }

  async autoConnectPeer(
    destAddress: string,
    remoteNickname: string
  ): Promise<RtcConnection> {
    console.log(
      'Rtc: autoConnectPeer: destAddress=' +
        destAddress +
        ' nickname=' +
        remoteNickname
    );

    if (this.haveConnection(destAddress)) {
      return null;
    }

    const currentSocket = this.sockets.get(destAddress);
    if (currentSocket == null) {
      let srcAddress: string;
      let cnt: any;
      // search contacts for this desination;
      for (let contact of this.contacts) {
        if (contact.destAddress === destAddress) {
          cnt = contact;
          srcAddress = contact.srcAddress;
          remoteNickname = contact.nickname;
          break;
        }
      }
      if (!srcAddress) {
        console.warn(
          'RtcService: autoConnectPeer: unknown contact/ todo add anon contact'
        );
        srcAddress = this.network.address;
      }
      let mode = await this.getMode();
      let header = {
        mode: mode,
        joinGroup: false,
        autoConnect: true,
        nickname: this.ourNickname,
        proxyData: <any>undefined
      };
      if (mode === 'proxy') {
        let pdata = await this.api.getcontactProxy(
          destAddress,
          srcAddress,
          remoteNickname
        );
        header.proxyData = pdata;
        console.log('Sharing Proxy mode with ' + remoteNickname);
      }
      const socket = this.network.connect(
        destAddress,
        srcAddress,
        'Rtc',
        JSON.stringify(header)
      );
      if (mode === 'proxy') {
        socket.setTimeout(30000);
      }

      const connection = new RTC(
        socket,
        this,
        false,
        false,
        remoteNickname,
        mode,
        this.streamHandler,
        true,
        this.streamHandler.masterVideoMute
      );
      if (!cnt) {
        cnt = this.contactSvc.createContactWithSocket(socket, remoteNickname);
      }
      await connection.init();
      cnt.addRtcConnection(connection);
      this.rtcConnections.push(connection);
      this.systemBus.emit(connection, 'rtc/connection/new');
      this.fixBandwidth();
      // client will send an accept packet which will cause webRtcInit to be called on the RtcConnection
      return connection;
    } else {
      this.rtcConnections.forEach((connection) => {
        if (connection.getDestinationID() === destAddress) {
          return connection;
        }
      });
    }
  }

  rtcSocketMessage(payload: any) {
    const address = payload.address;
    const socket = this.sockets.get(address);
    if (socket) {
      socket.send(payload);
    } else {
      console.error('Rtc: rtcSocketMessage: no socket for received payload');
    }
  }
}
