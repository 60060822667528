import { Injectable, NgZone } from '@angular/core';
import { SystemBusService, MessageObserver } from './system-bus.service';
import { ConfigService } from './config.service';

export class CropSettings {
  constructor(
    public startx: number,
    public starty: number,
    public width: number,
    public height: number
  ) {}
}

export interface AudioAnalyserCtlr {
  sendRawMessage(data: any, arg1: string): void;
  getDestinationID(): string;
  sendEQValues(values: EqualizerValues): void;
}

export class EqualizerValues {
  public bass = 0;
  public middle = 0;
  public treble = 0;
  public presence = 0;
  public noiseGate = 0;
  public volume = 100.0;
  public master = 0;

  constructor(eq: Equalizer) {
    if (!eq) {
    } else {
      this.bass = eq.bass.gain.value;
      this.middle = eq.middle.gain.value;
      this.treble = eq.treble.gain.value;
      this.presence = eq.presence.gain.value;
      this.calculateMaster();
    }
  }

  private calculateMaster() {
    // {
    //     const negative = (level < 0);
    //     let x = Math.pow(level, 2)
    //     let y = 0.01 * x;
    //     z = Math.pow( y, 1.34);
    //     if (level > 40) {
    //         level = 40;
    //     }
    //     if (negative) {
    //         level = -level;
    //     }
    //         console.log('Rtc: adjusted level ' + level);
    //     bass = 0 - level;
    //     middle = bass * .75;
    //     presence = 0 + level;
    //     treble = presence * .75;
    // }
    let level = 0;

    let z = this.presence;
    if (z < 0) {
      z = -z;
    }
    console.log('Presence ' + this.presence);
    if (z !== 0) {
      let y = Math.pow(z, 1.0 / 1.34);
      let x = y / 0.01;
      console.log(x, y, z);
      level = Math.sqrt(x);
      level = Math.round(level);
      if (this.presence < 0) {
        level = -level;
      }
    }

    this.master = level;
    console.log('EqualizerValues: calculateMaster: ' + level);
  }

  public setValues(values: any): EqualizerValues {
    this.bass = values.bass;
    this.middle = values.middle;
    this.treble = values.treble;
    this.presence = values.presence;
    this.noiseGate = values.noiseGate;
    this.volume = values.volume;
    this.calculateMaster();
    return this;
  }
}

export interface Visualizer {
  setCanvas(canvas: HTMLCanvasElement): void;
  visualize(dataArray: Uint8Array): void;
  noiseGateRemoteLevel(level: number): void;
}

export class VisualizerImpl implements Visualizer {
  private canvas: HTMLCanvasElement;
  private canvasCtx: CanvasRenderingContext2D;
  private rdataArray: Uint8Array;
  private ngRemoteLevel = 0;
  private ravg = 50.0;
  private N = 10;
  //  private autoHideLevel = false; //  TODO make this a programatic option
  //   private ngChangeTimer: any;
  //  private lastNgChange = 0;
  // private showLevelMarks = false;

  public noiseGateRemoteLevel(level: number) {
    console.log('Rtc: Analyser: setGateRmoteLevel ' + level);
    this.ngRemoteLevel = level;
    this.noiseGateLevelChanged();
  }
  private noiseGateLevelChanged() {
    // if (this.autoHideLevel) {
    //     const now = Date.now();
    //     const diff = now - this.lastNgChange;
    //     if (diff > 2000) { // don't create and destroy timers for every click
    //         this.showLevelMarks = true;
    //         this.lastNgChange = now;
    //         if (this.ngChangeTimer !== undefined) {
    //             clearTimeout(this.ngChangeTimer);
    //             this.ngChangeTimer = undefined;
    //         }
    //         this.ngChangeTimer = setTimeout(() => { this.showLevelMarks = false }, 30000);
    //     }
    // } else {
    //     this.showLevelMarks = true;
    // }
  }

  public setCanvas(canvas: HTMLCanvasElement): void {
    this.canvas = canvas;
    this.canvasCtx = canvas.getContext('2d');
  }

  public async visualize(dataArray: Uint8Array) {
    if (!this.canvas) {
      // console.warn('Visualize called without canvas set ', this);
      return;
    }
    try {
      this.rdataArray = dataArray;
      requestAnimationFrame(() => {
        this.draw();
      });
    } catch (error) {
      console.log('Rtc: Analyser: visualizer error ' + error);
    }
  }

  private draw() {
    const rdata = this.rdataArray;

    const canvasCtx = this.canvasCtx;
    const WIDTH = this.canvas.width;
    const HEIGHT = this.canvas.height;

    const bufferLength = rdata.length;
    canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
    let peek = 0;
    const barWidth = WIDTH / bufferLength;
    let barHeight;
    let x = 0;
    let color: string;
    for (let i = 0; i < bufferLength; i++) {
      barHeight = rdata[i];
      if (barHeight > 0) {
        color = 'rgb(50,75,' + (barHeight + 100) + ')';

        canvasCtx.fillStyle = color;
        canvasCtx.fillRect(x, HEIGHT - barHeight / 2, barWidth, barHeight / 2);

        x += barWidth + 1;

        // noiseGate
        if (barHeight > peek) {
          peek = barHeight;
        }
      }
    }
    this.remoteRollingAverage(peek / 2);
    peek = 0;
    // draw noiseGate line
    if (this.ngRemoteLevel > 5) {
      canvasCtx.beginPath();
      canvasCtx.moveTo(0, HEIGHT - this.ngRemoteLevel);
      canvasCtx.lineTo(WIDTH, HEIGHT - this.ngRemoteLevel);
      canvasCtx.strokeStyle = 'white';
      canvasCtx.stroke();
      // draw average

      canvasCtx.beginPath();
      canvasCtx.moveTo(0, HEIGHT - this.ravg);
      canvasCtx.lineTo(WIDTH, HEIGHT - this.ravg);
      canvasCtx.strokeStyle = 'blue';
      canvasCtx.stroke();
    }
  }
  private remoteRollingAverage(new_sample: number) {
    const N = this.N;
    if (new_sample > this.ravg) {
      this.ravg = new_sample; // peak faster
    }
    this.ravg -= this.ravg / N;
    this.ravg += new_sample / N;

    return this.ravg;
  }
}

export interface AudioAnalyser {
  dispose(): void;
  connect(input: MediaStreamTrack[], output: AudioNode): void;
  reConnect(input: MediaStreamTrack[]): void;
  getLocalLevels(): EqualizerValues;
  setVolume(volume: number): void;
  setRemoteEqValues(values: EqualizerValues): void;
  getRemoteEqValues(): EqualizerValues;
  setEqValue(type: string, level: number): void;
  localMute(mute: boolean): void;
  localHold(hold: boolean): void;
  noiseGateLevel(level: number): void;
  startAnalyser(): void;
  stopAnalyser(): void;
  sendLevelData(send: boolean): void;
}

class Equalizer {
  // private state: boolean;
  private input: AudioNode;
  private output: AudioNode;
  bass: BiquadFilterNode;
  middle: BiquadFilterNode;
  treble: BiquadFilterNode;
  presence: BiquadFilterNode;

  constructor(context: AudioContext) {
    this.bass = context.createBiquadFilter();
    this.middle = context.createBiquadFilter();
    this.treble = context.createBiquadFilter();
    this.presence = context.createBiquadFilter();

    // Set filter type
    this.bass.type = 'lowshelf';
    this.middle.type = 'peaking';
    this.treble.type = 'peaking';
    this.presence.type = 'highshelf';

    // Set cutoff frequency
    this.bass.frequency.value = 500; // 500 Hz
    this.middle.frequency.value = 1000; // 1 kHz
    this.treble.frequency.value = 2000; // 2 kHz
    this.presence.frequency.value = 4000; // 4 kHz

    // Set Q
    // this.bass.Q.value     = Math.SQRT1_2;  // Not used
    this.middle.Q.value = Math.SQRT1_2;
    this.treble.Q.value = Math.SQRT1_2;
    // this.presence.Q.value = Math.SQRT1_2;  // Not used

    // Set Gain
    this.bass.gain.value = 0;
    this.middle.gain.value = 0;
    this.treble.gain.value = 0;
    this.presence.gain.value = 0;

    // Equalizer is not connected by default
    // this.state = false;
  }

  setEqValue(k: string, value: number): EqualizerValues {
    // console.log('Rtc: Equalizer: setting ' + k + ' to ' + value);
    try {
      switch (k) {
        case 'bass':
        case 'middle':
        case 'treble':
        case 'presence':
          if (!value) {
            return this.getValues();
          } else {
            const min = (<any>this[k].gain).minValue || -40;
            const max = (<any>this[k].gain).maxValue || 40;

            if (value >= min && value <= max) {
              this[k].gain.value = value;
            }
          }
          break;
        case 'master':
          this.setMaster(value);
          break;
        default:
          console.error(
            'Rtc: Equalizer: invalid band ' +
              k +
              '\n Must be one of base, middle, treble, presence or master'
          );
          break;
      }
    } catch (error) {
      console.error('Equalizer error ' + error);
    }
    return this.getValues();
  }

  /**
   * -40 full base
   * +40 full treble
   */
  public setMaster(level: number) {
    const orig = level;
    if (level > 40 || level < -40) {
      console.error('Equalizer: setMaster: invalid level ' + level);
      return;
    }

    let bass = 0;
    let middle = 0;
    let treble = 0;
    let presence = 0;
    if (level !== 0) {
      const negative = level < 0;
      level = Math.pow(0.01 * Math.pow(level, 2), 1.34);
      if (level > 40) {
        level = 40;
      }
      if (negative) {
        level = -level;
      }
      console.log('Rtc: adjusted level ' + level);
      bass = 0 - level;
      middle = bass * 0.75;
      presence = 0 + level;
      treble = presence * 0.75;
    }
    this.setEqValue('bass', bass);
    this.setEqValue('middle', middle);
    this.setEqValue('treble', treble);
    this.setEqValue('presence', presence);
    //  console.error('SetMAster ' + orig + ' = ', this.getValues().master);
  }

  public getValues(): EqualizerValues {
    return new EqualizerValues(this);
  }

  setEqValues(v: EqualizerValues) {
    this.setEqValue('bass', v.bass);
    this.setEqValue('middle', v.middle);
    this.setEqValue('treble', v.treble);
    this.setEqValue('presence', v.presence);
  }

  connect(input: AudioNode, output: AudioNode) {
    this.input = input;
    this.output = output;

    this.input.disconnect(0);
    this.bass.disconnect(0);
    this.middle.disconnect(0);
    this.treble.disconnect(0);
    this.presence.disconnect(0);

    this.input.connect(this.bass);
    this.bass.connect(this.middle);
    this.middle.connect(this.treble);
    this.treble.connect(this.presence);
    this.presence.connect(this.output);
  }

  public close() {
    try {
      this.input.disconnect(0);
      this.bass.disconnect(0);
      this.middle.disconnect(0);
      this.treble.disconnect(0);
      this.presence.disconnect(0);
    } catch (error) {
      console.error('Rtc: error disposing ' + error);
    }
  }

  public dispose() {
    this.close();
    this.input = null;
    this.bass = null;
    this.middle = null;
    this.treble = null;
    this.presence = null;
  }
}

export class NoAudioAnalyserImpl implements AudioAnalyser {
  private blanckValues = new EqualizerValues(null);
  private remoteValues: EqualizerValues = new EqualizerValues(null);

  constructor() {}

  dispose() {}

  connect(_input: MediaStreamTrack[], _output: AudioNode) {}
  reConnect(_input: MediaStreamTrack[]): void {}

  getLocalLevels(): EqualizerValues {
    return this.blanckValues;
  }

  setVolume(_volume: number) {}

  setRemoteEqValues(values: EqualizerValues) {
    this.remoteValues = values;
  }

  getRemoteEqValues(): EqualizerValues {
    return this.remoteValues;
  }

  setEqValue(_type: string, _level: number) {}

  localMute(_mute: boolean) {}

  localHold(_hold: boolean) {}

  noiseGateLevel(_level: number) {}

  startAnalyser() {}

  stopAnalyser() {}

  sendLevelData(_send: boolean) {}
}

export class AudioAnalyserImpl implements AudioAnalyser {
  private analyserNode: AnalyserNode;
  private bufferLength: number;
  private dataArray: Uint8Array;

  private volumeGain: GainNode;
  private eq: Equalizer;

  private hold = false;
  private ngmute = false;
  private ngLevel = 0;
  private volume = 1.0;
  private timer: any;
  private count = 0;
  private avg = 50.0;
  private N = 10;
  private send = false;
  private remoteEqValues: EqualizerValues = new EqualizerValues(null);
  private destinationID: string;
  private disposed: boolean;
  private visualizer: VisualizerImpl;
  private lastMessage: string;

  constructor(
    private audioCtx: AudioContext,
    private controller: AudioAnalyserCtlr,
    private streamHandler: StreamhandlerService
  ) {
    console.log('New Audio Analyzer');
    this.volumeGain = audioCtx.createGain();

    this.eq = new Equalizer(audioCtx);

    this.analyserNode = audioCtx.createAnalyser();
    this.analyserNode.minDecibels = -90;
    this.analyserNode.maxDecibels = -10;
    this.analyserNode.smoothingTimeConstant = 0.85;
    this.analyserNode.fftSize = 64;
    this.bufferLength = this.analyserNode.frequencyBinCount;

    this.dataArray = new Uint8Array(this.bufferLength);

    if (streamHandler.masterAudioMute) {
      this.localMute(true);
    }
    this.destinationID = this.controller.getDestinationID();
    this.getSavedValues();
    this.visualizer = new VisualizerImpl();
  }

  private getSavedValues() {
    try {
      const destId = this.destinationID;
      const eqVals = this.streamHandler.config.getItem('EQ' + destId);
      if (eqVals && eqVals.length > 0) {
        const eqV = new EqualizerValues(null);
        eqV.setValues(JSON.parse(eqVals));
        this.eq.setEqValues(eqV);
        this.setVolume(eqV.volume);
        this.noiseGateLevel(eqV.noiseGate);
      } else {
        console.log('Rtc: Analyser: no saved values');
      }
    } catch (error) {
      console.error('Rtc: Analyser: error getting saved Values ' + error);
    }
  }

  private saveValues() {
    const destId = this.destinationID;
    this.streamHandler.config.setItem(
      'EQ' + destId,
      JSON.stringify(this.getLocalLevels())
    );
  }

  public setCanvas(canvas: HTMLCanvasElement) {
    this.visualizer.setCanvas(canvas);
  }

  public dispose() {
    console.log('Analizer: dospose');
    if (this.disposed) {
      return;
    }
    this.disposed = true;
    this.saveValues();

    try {
      this.stopAnalyser();
    } catch (error) {
      console.error('Rtc: error disposing ' + error);
    }
    try {
      this.eq.dispose();
    } catch (error) {
      console.error('Rtc: error disposing ' + error);
    }
    try {
      this.analyserNode.disconnect(0);
    } catch (error) {
      console.error('Rtc: error disposing ' + error);
    }
    this.analyserNode = null;
    try {
      this.volumeGain.disconnect(0);
    } catch (error) {
      console.error('Rtc: error disposing ' + error);
    }
    this.volumeGain = null;
    this.visualizer = null;
    this.controller = null;
    this.dataArray = null;
    this.eq = null;
  }

  public connect(input: MediaStreamTrack[], output: AudioNode) {
    console.log('Analizer: connect', input, output);
    const srcnode = this.audioCtx.createMediaStreamSource(
      new MediaStream(input)
    );
    this.eq.connect(srcnode, this.analyserNode);
    this.analyserNode.connect(this.volumeGain);
    this.volumeGain.connect(output);
  }

  reConnect(input: MediaStreamTrack[]) {
    console.log('AudioAnalyzer: reConnect', input);
    const srcnode = this.audioCtx.createMediaStreamSource(
      new MediaStream(input)
    );
    this.eq.connect(srcnode, this.analyserNode);
  }

  public getLocalLevels(): EqualizerValues {
    const values = this.eq.getValues();
    values.noiseGate = this.ngLevel;
    values.volume = this.volume * 100.0;
    return values;
  }

  public setVolume(volume: number) {
    this.volume = volume / 100;
    if (!(this.hold || this.ngmute)) {
      this.volumeGain.gain.value = this.volume;
    }
  }

  public setRemoteEqValues(values: EqualizerValues) {
    this.remoteEqValues = values;
    this.noiseGateRemoteLevel(values.noiseGate);
  }

  public getRemoteEqValues(): EqualizerValues {
    const vals = this.remoteEqValues;
    return vals;
  }

  public setEqValue(type: string, level: number) {
    this.eq.setEqValue(type, level);
  }

  public localMute(mute: boolean) {
    if (mute) {
      this.volumeGain.gain.value = 0;
    } else if (
      !this.ngmute &&
      !this.hold &&
      !this.streamHandler.masterAudioMute
    ) {
      this.volumeGain.gain.value = this.volume;
    }
  }

  private ngMute(mute: boolean) {
    if (mute !== this.ngmute) {
      this.ngmute = mute;
      if (mute) {
        this.volumeGain.gain.value = 0;
      } else if (!this.hold && !this.streamHandler.masterAudioMute) {
        this.volumeGain.gain.value = this.volume;
      }
    }
  }

  public localHold(hold: boolean) {
    if (hold !== this.hold) {
      this.hold = hold;
      if (hold) {
        this.volumeGain.gain.value = 0;
      } else if (!this.ngmute && !this.streamHandler.masterAudioMute) {
        this.volumeGain.gain.value = this.volume;
      }
    }
  }

  public noiseGateLevel(level: number) {
    this.ngLevel = level;
    console.log('Analyser: NoiseGate level ' + level);
  }

  public noiseGateRemoteLevel(level: number) {
    this.visualizer.noiseGateRemoteLevel(level);
  }

  private checkNgMute(dataArray: Uint8Array) {
    let peek = 0;
    for (let i = 0; i < dataArray.length; i++) {
      const val = dataArray[i];
      if (val > peek) {
        peek = val;
      }
    }
    this.rollingAverage(peek / 2);
  }

  public startAnalyser() {
    if (this.timer) {
      return;
    }
    this.timer = setInterval(() => {
      try {
        this.analyserNode.getByteFrequencyData(this.dataArray);
        this.checkNgMute(this.dataArray);
      } catch (error) {
        console.error('Rtc: Analyser: startAnylizerError ' + error);
        this.stopAnalyser();
      }
      if (this.send) {
        if (++this.count % 5 === 0) {
          // send every 5th
          let msg = JSON.stringify({
            type: 'anlz',
            data: Array.from(this.dataArray)
          });
          if (msg !== this.lastMessage) {
            this.lastMessage = msg;

            this.controller.sendRawMessage(msg, 'messages');
          }
        }
      }
    }, 32);
  }

  public stopAnalyser() {
    clearInterval(this.timer);
    this.timer = null;
  }

  public sendLevelData(send: boolean) {
    this.send = send;
    this.controller.sendEQValues(this.getLocalLevels());
  }

  private rollingAverage(new_sample: number) {
    const N = this.N;
    if (new_sample > this.avg) {
      this.avg = new_sample; // peak faster
    }
    this.avg -= this.avg / N;
    this.avg += new_sample / N;
    if (this.ngLevel > 5) {
      this.ngMute(this.avg < this.ngLevel);
    }
    return this.avg;
  }
}

export interface StreamChangeEventListener {
  streamHandlerOnStreamChangeEvent(stream: MediaStream): void;
}

export class Dim {
  public width = 0;
  public height = 0;
  constructor(width: number, height: number) {
    this.set(width, height);
  }
  public set(width: number, height: number) {
    this.width = Math.round(width);
    this.height = Math.round(height);
  }
  public getScale(): number {
    return this.width / this.height;
  }
}

export class VuMeter {
  private static count = 0;
  private inst = 0;
  private tlistener: any;
  private vlisteners: any[] = [];
  public volumeLevel = 0;

  private talking = false;
  private audioContext: AudioContext;
  private out: AnalyserNode;
  // private name: string;

  private srcNode: AudioNode;
  private buffer: Uint8Array;
  private volumeUpdateTimer: NodeJS.Timeout;
  private stopTalkingTimer: NodeJS.Timeout;
  constructor(audioContext: AudioContext) {
    this.audioContext = audioContext;
    this.inst = ++VuMeter.count;
  }

  public getOutputAudioNode(srcNode: any) {
    if (!this.out) {
      this.build();
    }

    if (srcNode) {
      if (this.srcNode) {
        this.srcNode.disconnect();
      }
      this.srcNode = srcNode;
      srcNode.connect(this.out);
    }
    return this.out;
  }

  private build() {
    this.out = this.audioContext.createAnalyser();
    this.out.fftSize = 32;
    this.out.smoothingTimeConstant = 0.3;
    let bufferLength = this.out.frequencyBinCount;
    this.buffer = new Uint8Array(bufferLength);
  }

  public destroy() {
    console.log('VUMeter: Destroy', this.inst);
    this.setIncall(false);
    this.vlisteners.length = 0;
    this.tlistener = undefined;
    if (this.out) {
      this.out.disconnect();
    }
    if (this.srcNode) {
      this.srcNode.disconnect();
    }
    this.srcNode = undefined;
    this.out = undefined;
    this.audioContext = undefined;
  }

  private async setVolumeLevel() {
    try {
      this.out.getByteFrequencyData(this.buffer);
      this.volumeLevel =
        this.buffer.reduce(function (t, v) {
          return t + v;
        }) /
        (this.buffer.length * 128);
      // The frequency data is composed of integers on a scale from 0 to 255.
      if (this.vlisteners.length > 0) {
        // console.log('StreamHandler: SetVolumeLeve: ' + this.volumeLevel);
        this.vlisteners.forEach((listener) =>
          listener.setSoundLevel(this.volumeLevel)
        );
      }
      // console.log('StreamHAndler: setVolume:')
      if (!this.talking && this.volumeLevel > 0.28) {
        this.updateTalking(true);
      } else if (this.talking && this.volumeLevel < 0.01) {
        this.updateTalking(false);
      }
    } catch (err) {
      console.error(err);
    }
  }

  private updateTalking(talking: boolean) {
    if (talking && !this.talking) {
      if (!this.stopTalkingTimer) {
        clearTimeout(this.stopTalkingTimer);
      }
      this.stopTalkingTimer = setTimeout(() => {
        this.stopTalkingTimer = undefined;
        //  console.error('VUMeter: Stop talking timeout ' + this.inst);
        this.updateTalking(false);
      }, 2000);
      this.talking = true;
      this.updateTalkListeners(true);
    } else if (!talking && this.talking) {
      this.talking = false;
      this.updateTalkListeners(false);
    }
  }

  private updateTalkListeners(talking: boolean) {
    // console.log('VUMeter: talking ' + talking + this.inst);
    if (this.tlistener) {
      this.tlistener.talking(talking);
    } else {
      // console.log('No VU talking listeners ' + this.inst);
    }
  }

  public setIncall(incall: boolean) {
    // console.error('StreamHandler: setIncall:' + incall + this.inst);
    if (incall) {
      if (!this.volumeUpdateTimer) {
        this.volumeUpdateTimer = setInterval(() => {
          this.setVolumeLevel();
        }, 250);
      }
    } else {
      if (this.volumeUpdateTimer) {
        clearInterval(this.volumeUpdateTimer);
        this.volumeUpdateTimer = undefined;
      }
    }
  }

  public setTalkingListener(listener: any) {
    // console.log('VuMeter: setTalkingListener: ', listener);
    this.tlistener = listener;
    setTimeout(() => {
      this.tlistener.talking(this.talking);
    }, 1000);
  }

  public addSoundLevelListener(listener: any) {
    this.vlisteners.push(listener);
  }

  public removeSoundLevelListener(listener: any) {
    let idx = this.vlisteners.indexOf(listener);
    if (idx !== -1) {
      this.vlisteners.splice(idx, 1);
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class StreamhandlerService implements MessageObserver {
  public audioContext = new AudioContext({ latencyHint: 'balanced' });
  private streamChangeListeners: StreamChangeEventListener[] = [];
  private ready: boolean;
  private drawVideoFlag = false;
  private isAway: boolean;
  private vuMeter: VuMeter;
  private speakerGain: GainNode;
  private mediaStreamSource: AudioNode;
  private mediaStreamDestination: MediaStreamAudioDestinationNode;

  private audioDeviceError: any;
  private videoDeviceError: any;
  private videoDeviceID: string;
  public videoEnabled = false;
  private videoMuted = false;
  private videoInputElemnt: HTMLVideoElement;
  private desktopShareElement: HTMLVideoElement;
  public shareHiRes = false;
  public shareHiRate = false;
  // private drawCanvasRan = true;
  private videoCanvas: HTMLCanvasElement;
  private videoCanvasContext: CanvasRenderingContext2D;
  private needsInput = true;
  private videoCanvasStream: MediaStream;
  private currentVideoDim: Dim;

  private canvasDim = new Dim(0, 0);
  private videoScaledDim = new Dim(0, 0);
  private videoScaledOffsetDim = new Dim(0, 0);
  private cropping: boolean;
  private cropyWidth: number;
  private cropyStart: number;
  private cropxWidth: number;
  private cropxStart: number;

  private audioOutDeviceID: string;
  public constraints: any = {};
  public videoDeviceWidth = 0;
  public videoDeviceHeight = 0;
  private audioDeviceID: string;
  public audioEnabled = false;
  private micAndCamMediaStream: MediaStream;
  private mainStream: MediaStream;
  private streams = new Set<MediaStream>();
  private drawTimer: number;
  private desktopWidth: number;
  private desktopHeight: number;
  private useHQAudio = false;
  private useCanvas = false;
  desktopShareGain: GainNode;
  micShareGain: GainNode;
  shareingDesktop: boolean;
  private getMediaStreamResolveQueue: any[] = [];
  private initializing = false;
  public masterVideoMute: boolean;
  public masterAudioMute: boolean;
  private loggedin = false;
  constructor(
    public ngZone: NgZone,
    private systemBus: SystemBusService,
    public config: ConfigService
  ) {
    systemBus.subscribe(this);
  }
  onBusMessage(_message: any, type: string): void {
    if (type === 'user/loggedIn' && !this.loggedin) {
      this.loggedin = true;
      this.webAudioUnlock();
      this.vuMeter = new VuMeter(this.audioContext);
      this.getConfig();
      this.init();
    }
  }
  busMessageFilter(messageType: string): boolean {
    return messageType === 'user/loggedIn';
  }

  public webAudioUnlock() {
    if (!this.audioContext) {
      this.audioContext = new AudioContext();
    }
    if (this.audioContext.state === 'suspended') {
      if ('ontouchstart' in window) {
        let unlock = () => {
          this.audioContext.resume().then(function () {
            document.body.removeEventListener('touchstart', unlock);
            document.body.removeEventListener('touchend', unlock);
          });
        };

        document.body.addEventListener('touchstart', unlock, false);
        document.body.addEventListener('touchend', unlock, false);
      } else {
        this.audioContext.resume();
      }
    }
  }

  public addStreamChangeEventListener(listener: StreamChangeEventListener) {
    this.streamChangeListeners.push(listener);
  }

  public removeStreamChangeEventListener(listener: StreamChangeEventListener) {
    const idx = this.streamChangeListeners.indexOf(listener);
    if (idx !== -1) {
      this.streamChangeListeners.splice(idx, 1);
    } else {
      console.warn('Listener not registered');
    }
  }

  private async notifyStreamChangeListeners(stream: MediaStream) {
    this.streamChangeListeners.forEach((l) => {
      l.streamHandlerOnStreamChangeEvent(stream);
    });
  }

  public getUseHQAudioSetting(): boolean {
    return this.useHQAudio;
  }

  public getUseCanvasSetting(): boolean {
    return this.useCanvas;
  }

  public getDeviceIDs(): any {
    return {
      videoDeviceID: this.videoDeviceID,
      audioDeviceID: this.audioDeviceID,
      audioOutDeviceID: this.audioOutDeviceID,
      hqAudio: this.useHQAudio,
      canvas: this.useCanvas,
      videoHeight: this.videoDeviceHeight
    };
  }

  public async setDeviceIDs(devInfo: any) {
    if (devInfo.videoDeviceID !== this.videoDeviceID) {
      this.setVideoInDevice(devInfo.videoDeviceID);
    }
    if (devInfo.audioDeviceID !== this.audioDeviceID) {
      this.setAudioInDevice(devInfo.audioDeviceID);
    }
    if (devInfo.audioOutDeviceID !== this.audioOutDeviceID) {
      this.setAudioOutDevice(devInfo.audioOutDeviceID);
    }
    if (devInfo.hqAudio !== this.useHQAudio) {
      this.setUseHQAudioSettings(devInfo.hqAudio);
    }
    if (devInfo.videoRes.height !== this.videoDeviceHeight) {
      this.setVideoResolution(devInfo.videoRes.width, devInfo.videoRes.height);
    }
  }

  public getVuMeter(): VuMeter {
    return this.vuMeter;
  }

  private init() {
    //  console.error('StreamHandler: init called');
    this.mediaStreamDestination = (<any>(
      this.audioContext
    )).createMediaStreamDestination();
    this.mainStream = this.mediaStreamDestination.stream;

    this.vuMeter.getOutputAudioNode(null).connect(this.mediaStreamDestination);
    this.speakerGain = this.audioContext.createGain();

    // must have an output for data to flow
    let outgain = this.audioContext.createGain();
    outgain.gain.value = 0;
    outgain.connect(this.audioContext.destination);
    this.speakerGain.connect(outgain);
    this.ready = true;
    this.systemBus.emit(this, 'streamHandler/Initialized');
  }

  public async getMediaStream(): Promise<MediaStream> {
    if (this.needsInput) {
      if (!this.initializing) {
        let p = await this.initMediaStreams();
        this.initializing = false;
        this.getMediaStreamResolveQueue.forEach((resolve: any) => {
          resolve(this.getStream());
        });
        this.getMediaStreamResolveQueue.length = 0;
        return p;
      } else {
        return new Promise((resolve, _reject) => {
          this.getMediaStreamResolveQueue.push(resolve);
        });
      }
    } else {
      return this.getStream();
    }
  }

  private async isReady() {
    return new Promise((resolve) => {
      const wt = () => {
        setTimeout(() => {
          if (this.ready) {
            resolve(null);
          } else {
            console.warn('StreamHandler: isReady: wait');
            wt();
          }
        }, 500);
      };

      wt();
    });
  }

  private async initMediaStreams(): Promise<MediaStream> {
    //  console.error('StreamHandler: initMediaStreams called');
    this.initializing = true;
    await this.getConstraints();
    if (this.videoDeviceError) {
      this.videoEnabled = false;
    }
    if (this.audioDeviceError) {
      this.audioEnabled = false;
    }
    let p: Promise<MediaStream>;
    if (this.audioEnabled || this.videoEnabled) {
      p = (<Promise<MediaStream>>(
        navigator.mediaDevices.getUserMedia(this.constraints)
      ))
        .then((stream) => {
          this.addUserMediaStream(stream);
          return this.getStream();
        })
        .catch((error) => {
          console.warn('StreamHandler: initMediaStreams: error=', error);
          if (this.videoEnabled) {
            this.videoDeviceError = true;
            const message = {
              type: 'warning',
              message: 'Error getting input device, will try without video',
              timeOut: 15
            };
            this.systemBus.emit(message, 'warning');
            return this.initMediaStreams();
          } else if (this.audioEnabled) {
            this.audioDeviceError = true;
            const message_1 = {
              type: 'warning',
              message: 'Error getting audio microphone, will try it ',
              timeOut: 15
            };
            this.systemBus.emit(message_1, 'warning');
            return this.initMediaStreams();
          } else {
            const message_2 = {
              type: 'error',
              message: 'Error getting any devices',
              timeOut: 15
            };
            this.systemBus.emit(message_2, 'warning');
            return error;
          }
        });
    } else {
      p = new Promise((resolve) => {
        resolve(this.getStream());
      });
    }
    return p;
  }

  private getStream(): MediaStream {
    let stream = this.mainStream.clone();
    this.streams.add(stream);
    if (!this.masterVideoMute) {
      this.drawVideo(true);
    }
    return stream;
  }

  public returnMediaStream(stream: MediaStream) {
    if (!stream) {
      return;
    }
    stream.getTracks().forEach((track) => {
      track.stop();
    });
    this.streams.delete(stream);
    console.error('Streams', this.streams);
    if (this.streams.size === 0) {
      this.unInit();
    }
  }

  public async addToMic(audioNode: AudioNode) {
    await this.isReady();
    this.webAudioUnlock();
    audioNode.connect(this.mediaStreamSource);
  }

  public removeFromMic(audioNode: AudioNode) {
    audioNode.disconnect(this.mediaStreamSource);
  }

  public addToSpeaker(audioNode: AudioNode) {
    this.webAudioUnlock();
    audioNode.connect(this.speakerGain);
  }

  public removeFromSpeaker(audioNode: AudioNode) {
    audioNode.disconnect(this.speakerGain);
  }

  public async getSpeakerTap(): Promise<MediaStream> {
    await this.isReady();
    let stream: MediaStream;

    this.webAudioUnlock();
    const dest = <MediaStreamAudioDestinationNode>(
      this.audioContext.createMediaStreamDestination()
    );
    stream = dest.stream;
    this.speakerGain.connect(dest);
    return stream;
  }

  public getRawStreamClone(): MediaStream {
    return this.micAndCamMediaStream.clone();
  }

  private addUserMediaStream(stream: MediaStream) {
    // console.error('StreamHandler: addUSerMEdiaStream called');
    this.micAndCamMediaStream = stream;
    this.webAudioUnlock();

    if (this.mediaStreamSource) {
      this.mediaStreamSource.disconnect();
    }
    if (this.desktopShareGain) {
      this.desktopShareGain.disconnect();
      this.desktopShareGain = undefined;
    }
    if (this.micShareGain) {
      this.micShareGain.disconnect();
      this.micShareGain = undefined;
    }
    const atracks = stream.getAudioTracks();
    if (atracks.length > 0) {
      if (atracks.length > 1) {
        /**
         * If two tracks
         * track 0 is desktop
         * track 1 is mic
         */
        this.mediaStreamSource = this.audioContext.createGain();

        let desk = this.audioContext.createMediaStreamSource(
          new MediaStream([atracks[0]])
        );
        this.desktopShareGain = this.audioContext.createGain();
        desk.connect(this.desktopShareGain);
        this.desktopShareGain.connect(this.mediaStreamSource);

        let mic = this.audioContext.createMediaStreamSource(
          new MediaStream([atracks[1]])
        );
        this.micShareGain = this.audioContext.createGain();
        mic.connect(this.micShareGain);
        this.micShareGain.connect(this.mediaStreamSource);
        if (this.masterAudioMute) {
          this.micShareGain.gain.value = 0;
          this.desktopShareGain.gain.value = 1;
        } else {
          this.desktopShareGain.gain.value = 0;
          this.micShareGain.gain.value = 1;
        }
      } else {
        this.mediaStreamSource =
          this.audioContext.createMediaStreamSource(stream);
      }
    } else {
      console.warn('No audio stream');
      this.mediaStreamSource = this.audioContext.createOscillator();
    }

    let out = this.vuMeter.getOutputAudioNode(this.mediaStreamSource); // it was allready connected

    this.mediaStreamDestination = (<any>(
      this.audioContext
    )).createMediaStreamDestination();
    this.mainStream = this.mediaStreamDestination.stream;

    out.connect(this.mediaStreamDestination);

    let videoTrack = stream.getVideoTracks()[0];
    if (videoTrack) {
      this.initInputVideo();
      this.videoInputElemnt.muted = true;
      this.videoInputElemnt.srcObject = stream;
      this.videoInputElemnt.pause();
      this.videoInputElemnt.play().catch((err) => {
        console.error('Error playing', err);
      });
      if (this.useCanvas) {
        this.videoCanvasContext.clearRect(
          0,
          0,
          this.videoCanvas.width,
          this.videoCanvas.height
        ); // clear poster
      }
    } else {
      console.log('StreamHandler: addUserMdeiaStream: No video track');
    }
    this.needsInput = false;
    this.vuMeter.setIncall(true);
  }

  private initInputVideo() {
    // console.error('StreamHandler: initInputVideo called', this.useCanvas, this.videoCanvasContext);
    this.videoInputElemnt = <HTMLVideoElement>(
      document.getElementById('videoInput')
    );

    if (this.useCanvas && !this.videoCanvasContext) {
      console.log('StreamHandler: initVideo: setting up Canvas');
      try {
        // this.videoCanvas = <HTMLCanvasElement>document.getElementById('videoCanvas');
        if (!this.videoCanvas) {
          console.error('StreamHandler: initInputVideo: videoCanvas not found');
        }
        this.videoCanvasContext = this.videoCanvas.getContext('2d', {
          alpha: false
        });
        this.videoCanvasContext.imageSmoothingEnabled = false;
        this.videoCanvasStream = (<any>this.videoCanvas).captureStream();

        const caps = { frameRate: 15 };
        this.videoCanvasStream.getVideoTracks()[0].applyConstraints(caps);
        this.mainStream.addTrack(this.videoCanvasStream.getVideoTracks()[0]);
        this.drawVideo(true);
      } catch (err) {
        console.warn('Error setting up canvas, disableing cropping ', err);
        this.useCanvas = false;
        this.initInputVideo();
      }
    } else if (!this.useCanvas) {
      // everytime if we are not using the canvas
      const cur = this.mainStream.getVideoTracks();
      this.mainStream.addTrack(this.micAndCamMediaStream.getVideoTracks()[0]);
      cur.forEach((track) => {
        this.mainStream.removeTrack(track);
      });
    }
  }

  public muteVideo(mute: boolean) {
    if (mute !== this.videoMuted) {
      this.videoMuted = mute;
      this.resetUserMedia();
    }
  }

  public drawVideo(draw: boolean) {
    console.log('StreamHandler: drawVideo', draw, this.useCanvas);
    if (this.useCanvas || !draw) {
      if (this.videoInputElemnt) {
        if (this.drawVideoFlag !== draw) {
          this.drawVideoFlag = draw;
          if (draw) {
            if (!this.drawTimer) {
              const d = () => {
                this.drawToCanvas();
              };
              this.ngZone.runOutsideAngular(() => {
                this.drawTimer = setInterval(
                  () => {
                    // window.requestAnimationFrame(d);
                    this.drawToCanvas();
                  },
                  66,
                  0,
                  false
                );
              });
            }
          } else {
            if (this.drawTimer) {
              clearInterval(this.drawTimer);
              this.drawTimer = undefined;
            }
          }
        }
      }
    }
  }

  private drawToCanvas() {
    if (this.isAway) {
      this.isAway = false;
      this.videoCanvasContext.clearRect(
        0,
        0,
        this.videoCanvas.width,
        this.videoCanvas.height
      );
    }

    this.checkVideoDimentions();
    if (this.currentVideoDim) {
      if (this.desktopShareElement) {
        this.videoCanvasContext.drawImage(
          this.desktopShareElement,
          0,
          0,
          this.videoCanvas.width,
          this.videoCanvas.height
        );
      } else {
        if (!this.cropping) {
          this.videoCanvasContext.drawImage(
            this.videoInputElemnt,
            0,
            0,
            this.currentVideoDim.width,
            this.currentVideoDim.height,
            this.videoScaledOffsetDim.width,
            this.videoScaledOffsetDim.height,
            this.videoScaledDim.width,
            this.videoScaledDim.height
          );
        } else {
          this.videoCanvasContext.drawImage(
            this.videoInputElemnt,
            this.cropxStart,
            this.cropyStart,
            this.cropxWidth,
            this.cropyWidth,
            this.videoScaledOffsetDim.width,
            this.videoScaledOffsetDim.height,
            this.videoScaledDim.width,
            this.videoScaledDim.height
          );
        }
      }
    }
  }

  private checkVideoDimentions() {
    if (this.desktopShareElement) {
      if (!this.currentVideoDim) {
        this.currentVideoDim = new Dim(this.desktopWidth, this.desktopHeight);
      }
      if (
        this.videoCanvas.height !== this.desktopHeight &&
        this.videoCanvas.width !== this.desktopWidth
      ) {
        this.videoCanvas.width = this.desktopWidth;
        this.videoCanvas.height = this.desktopHeight;
        this.videoCanvasContext.clearRect(
          0,
          0,
          this.desktopWidth,
          this.desktopHeight
        );
        this.currentVideoDim.set(this.desktopWidth, this.desktopHeight);
      }
    } else {
      let vw = this.videoInputElemnt.videoWidth;
      let vh = this.videoInputElemnt.videoHeight;
      if (!this.currentVideoDim) {
        if (vw > 0 && vh > 0) {
          // set canvas resolution to video resolution
          if (this.videoDeviceHeight === 0) {
            this.currentVideoDim = new Dim(0, 0);
            this.videoCanvas.width = vw;
            this.videoCanvas.height = vh;
            this.videoCanvasContext.clearRect(0, 0, vw, vh);
          } else {
            this.currentVideoDim = new Dim(0, 0);
            this.videoCanvas.width = this.videoDeviceWidth;
            this.videoCanvas.height = this.videoDeviceHeight;
            this.videoCanvasContext.clearRect(
              0,
              0,
              this.videoDeviceWidth,
              this.videoDeviceHeight
            );
          }
        } else {
          return;
        }
      }
      if (this.cropping) {
        vw = this.cropxWidth;
        vh = this.cropyWidth;
      }
      if (
        vw !== this.currentVideoDim.width ||
        vh !== this.currentVideoDim.height
      ) {
        // video dimensions changed recalculate draw info
        this.currentVideoDim.set(vw, vh);

        if (!this.canvasDim) {
          this.canvasDim = new Dim(
            this.videoCanvas.width,
            this.videoCanvas.height
          );
        } else {
          this.canvasDim.set(this.videoCanvas.width, this.videoCanvas.height);
        }
        const vidDim = this.currentVideoDim;
        const canDim = this.canvasDim;
        let scale = canDim.width / vidDim.width;
        if (vidDim.height * scale > canDim.height) {
          scale = canDim.height / vidDim.height;
        }
        const w = (vidDim.width * scale) | 0;
        const h = (vidDim.height * scale) | 0;
        this.videoScaledDim.set(w, h);
        const woff = ((canDim.width - w) / 2) | 0;
        const hoff = ((canDim.height - h) / 2) | 0;
        this.videoScaledOffsetDim.set(woff, hoff);
        this.videoCanvasContext.clearRect(0, 0, canDim.width, canDim.height);
      }
    }
  }

  public setVideoCrop(cropSettings: CropSettings) {
    console.log('Set cropping ', cropSettings);

    this.cropxStart = cropSettings.startx;
    this.cropxWidth = cropSettings.width;
    this.cropyStart = cropSettings.starty;
    this.cropyWidth = cropSettings.height;

    if (cropSettings && cropSettings.height + cropSettings.width > 0) {
      if (!this.cropping) {
        this.cropping = true;
        this.setUseCanvasSettings(true, false);
      }
    } else {
      this.cropping = false;
    }

    if (this.videoCanvasContext) {
      this.videoCanvasContext.clearRect(
        0,
        0,
        this.canvasDim.width,
        this.canvasDim.height
      );
    } else {
      console.error('Canvas not set');
    }
  }

  private unInit() {
    // console.error('StreamHandler: unInit called');
    this.vuMeter.setIncall(false);
    this.videoDeviceError = false;
    this.audioDeviceError = false;
    this.currentVideoDim = undefined;
    try {
      if (this.mainStream) {
        this.mainStream.getTracks().forEach((track) => {
          track.stop();
          this.mainStream.removeTrack(track);
        });
      }
      if (this.micAndCamMediaStream) {
        this.micAndCamMediaStream.getTracks().forEach((track) => {
          track.stop();
          this.micAndCamMediaStream.removeTrack(track);
        });
        this.micAndCamMediaStream = undefined;
      }
      if (this.videoEnabled) {
        let videoElement = <HTMLMediaElement>(
          document.getElementById('videoInput')
        );
        videoElement.srcObject = null;
      }
      if (this.mediaStreamSource) {
        this.mediaStreamSource.disconnect();
        this.mediaStreamSource = undefined;
      }
      if (this.desktopShareGain) {
        this.desktopShareGain.disconnect();
        this.desktopShareGain = undefined;
      }
      if (this.micShareGain) {
        this.micShareGain.disconnect();
        this.micShareGain = undefined;
      }

      if (this.useCanvas && this.videoCanvasContext && this.videoCanvas) {
        this.videoCanvasContext.clearRect(
          0,
          0,
          this.videoCanvas.width,
          this.videoCanvas.height
        );
        const img = new Image();
        img.onload = () => {
          this.videoCanvasContext.drawImage(img, 0, 0);
          this.videoCanvasContext = undefined;
        };
        img.src = 'assets/img/noVideo.png';
        this.drawVideo(false);
        this.videoCanvasStream.getTracks().forEach((track) => {
          track.stop();
          this.videoCanvasStream.removeTrack(track);
        });
        this.videoCanvasStream = undefined;
        //  this.videoCanvas = undefined;
      }
      if (this.mediaStreamDestination) {
        this.vuMeter.getOutputAudioNode(null).disconnect();
        this.mediaStreamDestination.disconnect();
        this.mediaStreamDestination = undefined;
      }
      this.mainStream = undefined;
    } catch (err) {
      console.error('StreamHandler: unInit: error ', err);
    } finally {
      this.needsInput = true;
    }
  }

  private async resetUserMedia() {
    //  console.log('StreamHandler: resetUSerMedia');
    if (!this.micAndCamMediaStream) {
      return;
    }
    this.unInit();

    this.videoDeviceError = false;
    this.audioDeviceError = false;

    await this.getConstraints();
    let p: Promise<MediaStream>;
    if (this.audioEnabled || this.videoEnabled) {
      console.log('Audio enabled ' + this.audioEnabled);
      p = await (<Promise<MediaStream>>(
        navigator.mediaDevices.getUserMedia(this.constraints)
      ))
        .then((stream) => {
          this.addUserMediaStream(stream);
        })
        .then(() => {
          this.notifyStreamChangeListeners(this.mainStream);
        })
        .catch((error) => {
          console.error('StreamHandler: initMediaStreams: error=', error);
          return error;
        });
    }
    return p;
  }

  private async getConstraints() {
    await this.updateDevices();

    let supportedConstraints: any =
      navigator.mediaDevices.getSupportedConstraints();
    console.log(
      'StreamHandler: getContraints: supported=',
      supportedConstraints
    );

    if (this.audioEnabled && !this.audioDeviceError) {
      if (this.audioDeviceID && supportedConstraints.deviceId) {
        this.constraints = {
          audio: {
            deviceId: { ideal: this.audioDeviceID }
          }
        };
      } else {
        this.constraints = {
          audio: {}
        };
      }
      if (supportedConstraints.channelCount) {
        this.constraints.audio.channelCount = 1;
      }
      if (supportedConstraints.noiseSuppression) {
        this.constraints.audio.noiseSuppression = !this.useHQAudio;
      }
      if (supportedConstraints.autoGainControl) {
        this.constraints.audio.autoGainControl = !this.useHQAudio;
      }
      if (supportedConstraints.echoCancellation) {
        this.constraints.audio.echoCancellation = !this.useHQAudio;
      }
    } else {
      this.constraints.audio = false;
    }
    if (this.videoEnabled && !this.videoDeviceError && !this.videoMuted) {
      const vw = this.videoDeviceWidth;
      const vh = this.videoDeviceHeight;
      if (this.videoDeviceID && supportedConstraints.deviceId) {
        if (vh > 0) {
          this.constraints.video = {
            deviceId: { ideal: this.videoDeviceID },
            width: { ideal: vw },
            height: { ideal: vh }
          };
        } else {
          this.constraints.video = {
            deviceId: { ideal: this.videoDeviceID },
            height: { ideal: 360 },
            width: { ideal: 640 }
          };
        }
      } else {
        if (vh > 0) {
          this.constraints.video = {
            width: { ideal: vw },
            height: { ideal: vh }
          };
        } else {
          this.constraints.video = {
            height: { ideal: 360 },
            width: { ideal: 640 }
          };
        }
      }
      if (supportedConstraints.aspectRatio) {
        this.constraints.video.aspectRatio = { ideal: 1.7777777778 };
      }
      if (supportedConstraints.frameRate) {
        this.constraints.video.frameRate = { ideal: 20 };
      }
    }
    console.warn('StreamHandler: getConstraints: ', this.constraints);
  }

  public setMasterAudioMute(mute: boolean) {
    if (this.masterAudioMute !== mute) {
      this.masterAudioMute = mute;
      //  console.log('Rtc: Master mute ' + mute);
      this.systemBus.emit(mute, 'rtc/masterAudioMute');
      if (this.shareingDesktop && this.micShareGain) {
        if (mute) {
          this.desktopShareGain.gain.linearRampToValueAtTime(
            1.0,
            this.audioContext.currentTime + 1
          );
          this.micShareGain.gain.linearRampToValueAtTime(
            0.0,
            this.audioContext.currentTime + 1
          );
        } else {
          this.micShareGain.gain.linearRampToValueAtTime(
            1.0,
            this.audioContext.currentTime + 1
          );
          this.desktopShareGain.gain.linearRampToValueAtTime(
            0.2,
            this.audioContext.currentTime + 1
          );
        }
      }
    }
  }

  public setMasterVideoMute(mute: boolean) {
    if (this.masterVideoMute !== mute) {
      this.masterVideoMute = mute;
      this.systemBus.emit(mute, 'rtc/masterVideoMute');
      this.drawVideo(!mute);
      this.muteVideo(mute);
    }
  }

  public async setAudioInDevice(deviceID: string) {
    console.log('setAudioInDevice ' + deviceID);
    this.audioDeviceID = deviceID;
    if (typeof Storage !== 'undefined') {
      this.config.setItem('audioInDeviceID', deviceID);
    }
    if (!this.micAndCamMediaStream) {
      await this.updateDevices();
    } else {
      await this.resetUserMedia();
    }
    this.systemBus.emit(this, 'rtc/audioDevice/in/changed');
  }

  public async setAudioOutDevice(deviceID: string) {
    console.log('setAudioOutDevice ' + deviceID);
    this.audioOutDeviceID = deviceID;
    if (typeof Storage !== 'undefined') {
      this.config.setItem('audioOutDeviceID', deviceID);
    }
    // if (!this.micAndCamMediaStream) {
    //    await this.updateDevices();
    // } else {
    //    await this.resetUserMedia();
    // }
    this.systemBus.emit(deviceID, 'rtc/audioDevice/Out/changed');
  }

  public async setVideoInDevice(deviceID: string) {
    this.videoDeviceID = deviceID;
    if (typeof Storage !== 'undefined') {
      this.config.setItem('videoInDeviceID', deviceID);
    }
    if (!this.micAndCamMediaStream) {
      await this.updateDevices();
    } else {
      await this.resetUserMedia();
    }
    this.systemBus.emit(this, 'rtc/videoDevice/in/changed');
  }

  public async shareDesktop(share: boolean, audio: boolean) {
    if (!share) {
      if (this.desktopShareElement) {
        (<MediaStream>this.desktopShareElement.srcObject)
          .getTracks()
          .forEach((track) => {
            track.stop();
          });
      }
      this.desktopShareElement = undefined;
      this.currentVideoDim = undefined;
      this.systemBus.emit(this, 'rtc/desktopShare/unshared');

      this.shareingDesktop = false;
      if (this.micShareGain) {
        this.micShareGain.disconnect();
        this.micShareGain = undefined;
      }
      if (this.desktopShareGain) {
        this.desktopShareGain.disconnect();
        this.desktopShareGain = undefined;
      }
      if (!this.useCanvas) {
        this.resetUserMedia();
      }
    } else {
      this.setMasterVideoMute(false);

      let video: any = {};

      if (!this.shareHiRate) {
        video.maxFrameRate = 5;
      } else {
        video.maxFrameRate = 30;
      }
      if (!this.shareHiRes) {
        video.maxWidth = 1280;
        video.maxHeight = 720;
      } else {
        video.maxWidth = 0;
        video.maxHeight = 0;
      }

      if ((<any>navigator).getDisplayMedia) {
        (<any>navigator)
          .getDisplayMedia({
            video: video,
            audio: audio
          })
          .then((screenStream: any) => {
            this.processDesktopStream(screenStream);
          });
      } else if ((<any>navigator.mediaDevices).getDisplayMedia) {
        (<any>navigator.mediaDevices)
          .getDisplayMedia({
            video: video,
            audio: audio
          })
          .then((screenStream: MediaStream) => {
            this.processDesktopStream(screenStream);
          });
      }
    }
  }

  private processDesktopStream(stream: MediaStream) {
    if (this.useCanvas) {
      if (!this.desktopShareElement) {
        this.desktopShareElement = document.createElement('video'); // create a video element
      }
      this.desktopShareElement.autoplay = true;

      this.desktopShareElement.srcObject = stream;
      this.desktopShareElement.addEventListener('play', () => {
        let vheight = this.desktopShareElement.videoHeight;
        let vwidth = this.desktopShareElement.videoWidth;
        this.desktopWidth = vwidth;
        this.desktopHeight = vheight;
      });
    } else {
      stream.getVideoTracks()[0].onended = () => {
        this.shareDesktop(false, false);
      };
      // get audio track
      //  console.log(' Share Desktop: adding mic audio to stream');
      const audio_constraints = this.constraints;
      audio_constraints.video = false;
      navigator.mediaDevices
        .getUserMedia(audio_constraints)
        .then((astream) => {
          stream.addTrack(astream.getAudioTracks()[0]);
          this.unInit();
          this.addUserMediaStream(stream);
          this.notifyStreamChangeListeners(this.mainStream);
        })
        .catch((err) => {
          console.error('Error getting audio stream', err);
        });
    }
    this.shareingDesktop = true;
    stream.getVideoTracks()[0].addEventListener('ended', () => {
      this.desktopShareElement = undefined;
      this.shareingDesktop = false;
      if (this.micShareGain) {
        this.micShareGain.disconnect();
        this.micShareGain = undefined;
      }
      if (this.desktopShareGain) {
        this.desktopShareGain.disconnect();
        this.desktopShareGain = undefined;
      }
      this.currentVideoDim = undefined;
      this.systemBus.emit(this, 'rtc/desktopShare/unshared');
    });
    this.systemBus.emit(this, 'rtc/desktopShare/shared');
    return stream;
  }

  public setVideoResolution(width: number, height: number) {
    this.cropping = false;
    this.videoDeviceHeight = height;
    this.videoDeviceWidth = width;
    if (typeof Storage !== 'undefined') {
      let dev = this.videoDeviceID;
      if (!dev) {
        dev = 'defaultVideoInDevice';
      }
      this.config.setItem(dev + '-resolution', width + 'x' + height);
    }

    if (!this.micAndCamMediaStream) {
      this.updateDevices();
    } else {
      this.resetUserMedia();
    }
    this.systemBus.emit(this, 'rtc/videoDevice/in/changed');
  }

  public setShareSettings(hiRate: boolean, hiRes: boolean) {
    this.cropping = false;
    this.shareHiRate = hiRate;
    this.shareHiRes = hiRes;
    if (typeof Storage !== 'undefined') {
      this.config.setItem('shareHiRate', hiRate ? 'true' : 'false');
      this.config.setItem('shareHiRes', hiRes ? 'true' : 'false');
    }
    this.systemBus.emit(this, 'rtc/setting/screenShare');
  }

  public setUseCanvasSettings(useCanvas: boolean, save?: boolean) {
    this.useCanvas = useCanvas; // must be set first because the vudeocanvas element will read it after the systembud message
    //  console.error('StreamHandler: setUseCanvaseSettings', useCanvas, save);
    this.systemBus.emit(this, 'rtc/setting/useCanvas');
    if (save) {
      this.config.setItem('useCanvas', useCanvas ? 'true' : 'false');
    }
    if (!useCanvas) {
      this.drawVideo(false);
    }
  }

  public setCropCanvas(canvas: HTMLCanvasElement) {
    //  console.error('setting crop canvas', canvas);
    this.videoCanvas = canvas;
    if (!this.micAndCamMediaStream) {
      this.updateDevices();
    } else {
      this.resetUserMedia();
    }
    // else {
    //     this.resetUserMedia();
    // }
  }

  public setUseHQAudioSettings(hqAudio: boolean) {
    this.useHQAudio = hqAudio;

    if (typeof Storage !== 'undefined') {
      this.config.setItem('useHQAudio', hqAudio ? 'true' : 'false');
    }
    if (!this.micAndCamMediaStream) {
      this.updateDevices();
    } else {
      this.resetUserMedia();
    }
    this.systemBus.emit(this, 'rtc/setting/HQAudio');
  }

  private async updateDevices() {
    console.log('StreamHandler: UpdateDevices');
    // this.getConfig();

    await (<Promise<MediaDeviceInfo[]>>(
      navigator.mediaDevices.enumerateDevices()
    ))
      .then(async (infos) => {
        if (this.checkNullIDs(infos)) {
          let stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
          });
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
        this.checkDeviceIDs(infos);
      })
      .catch((error) => {
        console.error('Error getting devices', error);
      });
  }

  private getConfig() {
    // console.error('StreamHandler: getConfig called');
    this.useHQAudio = this.config.getItem('useHQAudio') === 'true';
    this.useCanvas = this.config.getItem('useCanvas') === 'true';
    this.shareHiRate = this.config.getItem('shareHiRate') === 'true';
    this.shareHiRes = this.config.getItem('shareHiRes') === 'true';
    this.audioOutDeviceID = this.config.getItem('audioOutDeviceID');
    this.audioDeviceID = this.config.getItem('audioInDeviceID');
    this.videoDeviceID = this.config.getItem('videoInDeviceID');

    let dev = this.videoDeviceID;
    if (!dev) {
      dev = 'defaultVideoInDevice';
    }
    let res = this.config.getItem(dev + '-resolution');
    if (!res) {
      this.videoDeviceWidth = 0;
      this.videoDeviceHeight = 0;
    } else {
      const resarray = res.split('x');
      this.videoDeviceWidth = parseInt(resarray[0], 10);
      this.videoDeviceHeight = parseInt(resarray[1], 10);
    }
  }

  private checkNullIDs(deviceInfos: MediaDeviceInfo[]): boolean {
    let nill = false;
    for (let devinfo of deviceInfos) {
      if (!devinfo.deviceId || devinfo.deviceId === '') {
        nill = true;
        break;
      }
    }
    return nill;
  }

  private checkDeviceIDs(deviceInfos: MediaDeviceInfo[]) {
    console.log('Devices=', deviceInfos);
    let foundaout = false;
    let devaout: string;
    let foundain = false;
    let devain: string;
    let foundvin = false;
    let devvin: string;
    for (let info of deviceInfos) {
      const id = info.deviceId;
      if (info.kind === 'videoinput') {
        devvin = id;
        this.videoEnabled = true;
        if (id === this.videoDeviceID) {
          foundvin = true;
        }
      } else if (info.kind === 'audioinput') {
        devain = id;
        this.audioEnabled = true;
        if (id === this.audioDeviceID) {
          foundain = true;
        }
      } else if (info.kind === 'audiooutput') {
        devaout = id;
        if (id === this.audioOutDeviceID) {
          foundaout = true;
        }
      } else {
        console.error(
          'RtcService: checkDeviceIDs: invalid device kind ' + info.kind
        );
      }
    }
    if (!foundaout) {
      if (this.audioOutDeviceID) {
        console.warn(
          'RtcService: checkDeviceIDs: did not find audio output device "' +
            this.audioOutDeviceID +
            '" in ',
          deviceInfos
        );
      }
      if (devaout) {
        this.audioOutDeviceID = devaout;
        console.log(
          'RtcService: checkDeviceIDs: using speaker device found: ' + devaout
        );
        this.setAudioOutDevice(devaout);
      } else {
        this.audioOutDeviceID = null;
      }
    }
    if (!foundain) {
      if (this.audioDeviceID) {
        console.warn(
          'RtcService: checkDeviceIDs: did not find audio inut device "' +
            this.audioDeviceID +
            '" in ',
          deviceInfos
        );
      }
      if (devain) {
        this.audioDeviceID = devain;
        console.log(
          'RtcService: checkDeviceIDs: using mic device found: ' + devain
        );
        this.setAudioInDevice(devain);
      } else {
        this.audioDeviceID = null;
      }
    }
    if (!foundvin) {
      if (this.videoDeviceID) {
        console.warn(
          'RtcService: checkDeviceIDs: did not find video input device "' +
            this.videoDeviceID +
            '" in ',
          deviceInfos
        );
      }
      if (devvin) {
        this.videoDeviceID = devvin;
        console.log(
          'RtcService: checkDeviceIDs: using video device found: ' + devvin
        );
        this.setVideoInDevice(devvin);
      } else {
        this.videoDeviceID = null;
      }
    }
  }
}
