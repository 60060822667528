<ul
  class="nav nav-tabs justify-content-center"
  id="accountTab"
  role="tablist"
  style="
    border-bottom: none;
    position: relative;
    top: 15px;
    border-radius: 0px;
    background-color: transparent;
  "
>
  <li class="nav-item">
    <a
      class="nav-link active"
      id="profile-tab"
      data-bs-toggle="tab"
      href="#profile"
      role="tab"
      aria-controls="profile"
      aria-selected="true"
      ><i class="material-icons">account_box</i></a
    >
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      id="addons-tab"
      data-bs-toggle="tab"
      href="#addons"
      role="tab"
      aria-controls="addons"
      aria-selected="false"
      ><i class="material-icons">add_shopping_cart</i></a
    >
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      id="history-tab"
      data-bs-toggle="tab"
      href="#history"
      role="tab"
      aria-controls="history"
      aria-selected="false"
      ><i class="material-icons">history</i></a
    >
  </li>
  <li class="nav-item">
    <a
      class="nav-link"
      id="methods-tab"
      data-bs-toggle="tab"
      href="#methods"
      role="tab"
      aria-controls="methods"
      aria-selected="false"
      ><i class="material-icons">credit_card</i></a
    >
  </li>
</ul>
<div class="tab-content" id="accountTab" style="position: relative; top: 30px">
  <div
    class="tab-pane fade show active"
    id="profile"
    role="tabpanel"
    aria-labelledby="profile-tab"
  >
    <div class="heading">
      <p>Profile Information</p>
    </div>
    <app-profile [show]="next"></app-profile>
  </div>
  <div
    class="tab-pane fade show"
    id="addons"
    role="tabpanel"
    aria-labelledby="addons-tab"
  >
    <div class="heading">
      <p>Purchase Addons</p>
    </div>
    <app-upgrades [show]="next"></app-upgrades>
  </div>
  <div
    class="tab-pane fade show"
    id="history"
    role="tabpanel"
    aria-labelledby="history-tab"
  >
    <div class="heading">
      <p>Payments | Billing History</p>
    </div>
    <app-billing [show]="next"></app-billing>
  </div>
  <div
    class="tab-pane fade show"
    id="methods"
    role="tabpanel"
    aria-labelledby="methods-tab"
  >
    <div class="heading">
      <p>Payment Methods</p>
    </div>
    <app-credit [show]="next"></app-credit>
  </div>
</div>
