<div *ngIf="!restricted">
  <div class="private-chat-container" [ngClass]="{ incall: inCall === true }">
    <div class="row private-chat-row">
      <div class="col-12">
        <span class="private-chat-span">
          <div class="private-chat-header">
            <div class="d-flex">
              <div class="me-auto">
                <button
                  type="button"
                  (click)="close()"
                  class="btn-rtc btn-outline-light btn-lg pc-exit"
                  [title]="tr.get('contact', 'close')"
                  [ngClass]="{
                    'btn-outline-danger': contact.isBlocked,
                    'btn-outline-light': !contact.isBlocked
                  }"
                  style="box-shadow: none; outline: none"
                >
                  <span class="d-flex align-items-center">
                    <span
                      *ngIf="contact.state[0] === 'connected'"
                      class="p-2 d-none d-sm-block"
                      style="color: #3498db"
                      >{{ contact.nickname }}</span
                    >
                    <span
                      *ngIf="contact.state[0] !== 'connected'"
                      class="p-2 d-sm-block"
                      >{{ contact.nickname }}</span
                    >
                    <i class="fas fa-long-arrow-alt-left"></i>
                  </span>
                </button>
              </div>
              <div *ngIf="!inCall" class="p-2 d-sm-block">
                <span *ngIf="!contact.isBlocked">
                  <button
                    *ngIf="contact.state[0] === 'connected'"
                    type="button"
                    class="btn-rtc btn-outline-light btn-lg pc-right-menu"
                    (click)="connectRtc(false)"
                    data-bs-placement="top"
                    [title]="tr.get('contact', 'p.call')"
                  >
                    <i class="fa fa-microphone"></i>
                  </button>
                  <button
                    *ngIf="contact.state[0] === 'connected'"
                    type="button"
                    class="btn-rtc btn-outline-light btn-lg pc-right-menu"
                    (click)="connectRtc(true)"
                    data-bs-placement="top"
                    [title]="tr.get('contact', 'v.call')"
                  >
                    <i class="fa fa-video"></i>
                  </button>
                </span>
              </div>
              <div class="p-2">
                <div class="dropdown mr-2">
                  <button
                    class="btn-rtc btn-outline-light btn-lg pc-right-menu"
                    type="button"
                    id="dropdownChat"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fa fa-ellipsis-h"></i>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownChat">
                    <span *ngIf="!contact.isBlocked" class="full-width">
                      <div *ngIf="contact.state[0] === 'connected'">
                        <button
                          *ngIf="contact.state[0] === 'connected'"
                          class="dropdown-item"
                          type="button"
                          (click)="connectRtc(false)"
                          id="call"
                        >
                          {{ tr.get('contact', 'p.call') }}
                        </button>
                        <button
                          *ngIf="contact.state[0] === 'connected'"
                          class="dropdown-item"
                          type="button"
                          (click)="connectRtc(true)"
                          id="vcall"
                        >
                          {{ tr.get('contact', 'v.call') }}
                        </button>
                      </div>

                      <div *ngIf="contact.state[0] !== 'connected'">
                        <div *ngIf="!contact.awayable">
                          <span class="dropdown-item">User is Offline</span>
                        </div>
                        <div *ngIf="contact.awayable">
                          <span class="dropdown-item">User is Away</span>
                          <button
                            class="dropdown-item"
                            type="button"
                            (click)="requestConnectRtc(false)"
                            id="callme"
                          >
                            {{ tr.get('contact', 'p.request') }}
                          </button>
                        </div>
                      </div>
                    </span>
                    <span *ngIf="!isAnon && !weAreAnon" class="full-width">
                      <button
                        class="dropdown-item"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal"
                        id="delete"
                      >
                        {{ tr.get('contact', 'delete') }}
                      </button>
                      <button
                        *ngIf="!contact.isBlocked"
                        class="dropdown-item"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#blockModal"
                        id="block"
                      >
                        {{ tr.get('contact', 'block') }}
                      </button>
                      <button
                        *ngIf="contact.isBlocked"
                        class="dropdown-item"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#unblockModal"
                        id="unblock"
                      >
                        {{ tr.get('contact', 'unblock') }}
                      </button>
                      <button
                        class="dropdown-item"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#editModal"
                        id="edit"
                      >
                        {{ tr.get('contact', 'edit') }}
                      </button>
                    </span>
                    <button
                      (click)="openDevices()"
                      class="dropdown-item"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#sdModal"
                      id="td"
                    >
                      Device Settings
                    </button>
                    <div class="dropdown-divider"></div>
                    <button
                      (click)="clearChat()"
                      class="dropdown-item"
                      type="button"
                    >
                      {{ tr.get('contact', 'clear') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="!inCall"
            class="d-none d-sm-block private-chat-large-menu"
            style="width: 100%; height: 100%"
          >
            <div
              class="d-flex justify-content-center align-items-center bd-highlight mb-3"
              style="height: calc(100% - 70px)"
            >
              <div class="p-2 bd-highlight">
                <span class="close-menu" *ngIf="!contact.isBlocked">
                  <div class="container">
                    <div *ngIf="contact.state[0] === 'connected'">
                      <div class="row">
                        <div class="col-sm">
                          <button
                            type="button"
                            class="btn-rtc btn-outline-light btn-lg pc-right-menu"
                            (click)="connectRtc(false)"
                            data-bs-placement="top"
                            [title]="tr.get('contact', 'p.call')"
                            style="
                              border: 3px solid #2980b9;
                              box-shadow: none;
                              outline: none;
                            "
                          >
                            <i
                              class="material-icons pt-1"
                              style="font-size: 110px; color: #2c3e50"
                              >mic</i
                            >
                          </button>
                        </div>
                        <div class="col-sm">
                          <button
                            type="button"
                            class="btn-rtc btn-outline-light btn-lg pc-right-menu"
                            (click)="connectRtc(true)"
                            data-bs-placement="top"
                            [title]="tr.get('contact', 'v.call')"
                            style="
                              border: 3px solid #2980b9;
                              box-shadow: none;
                              outline: none;
                            "
                          >
                            <i
                              class="material-icons pt-1"
                              style="font-size: 110px; color: #2c3e50"
                              >voice_chat</i
                            >
                          </button>
                        </div>
                        <div class="col-sm">
                          <button
                            (click)="openDevices()"
                            type="button"
                            class="btn-rtc btn-outline-light btn-lg pc-right-menu"
                            data-bs-toggle="modal"
                            data-bs-target="#sdModal"
                            id="td"
                            data-bs-placement="top"
                            title="Test Devices"
                            style="
                              border: 3px solid #2980b9;
                              box-shadow: none;
                              outline: none;
                            "
                          >
                            <i
                              class="material-icons pt-1"
                              style="font-size: 110px; color: #2c3e50"
                              >settings_applications</i
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="
                        contact.state[0] !== 'connected' && contact.awayable
                      "
                    >
                      <div class="row">
                        <div class="col-sm">
                          <button
                            type="button"
                            class="btn-rtc btn-outline-light btn-lg pc-right-menu"
                            (click)="connectRtc(false)"
                            data-bs-placement="top"
                            [title]="tr.get('contact', 'p.call')"
                            style="
                              border: 3px solid #2980b9;
                              box-shadow: none;
                              outline: none;
                            "
                          >
                            <i
                              class="material-icons pt-1"
                              style="font-size: 110px; color: #2c3e50"
                              >mic</i
                            >
                          </button>
                        </div>
                        <div class="col-sm">
                          <button
                            type="button"
                            class="btn-rtc btn-outline-light btn-lg pc-right-menu"
                            (click)="connectRtc(true)"
                            data-bs-placement="top"
                            [title]="tr.get('contact', 'v.call')"
                            style="
                              border: 3px solid #2980b9;
                              box-shadow: none;
                              outline: none;
                            "
                          >
                            <i
                              class="material-icons pt-1"
                              style="font-size: 110px; color: #2c3e50"
                              >voice_chat</i
                            >
                          </button>
                        </div>
                        <div class="col-sm">
                          <button
                            (click)="openDevices()"
                            type="button"
                            class="btn-rtc btn-outline-light btn-lg pc-right-menu"
                            data-bs-toggle="modal"
                            data-bs-target="#sdModal"
                            id="td"
                            data-bs-placement="top"
                            title="Test Devices"
                            style="
                              border: 3px solid #2980b9;
                              box-shadow: none;
                              outline: none;
                            "
                          >
                            <i
                              class="material-icons pt-1"
                              style="font-size: 110px; color: #2c3e50"
                              >settings_applications</i
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
    <app-chat-window
      [messenger]="contact"
      [rtcButtons]="rtcButtons"
      [inCall]="inCall"
    ></app-chat-window>
  </div>
</div>
<!-- Delete Modal -->
<div
  class="modal fade"
  id="deleteModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ tr.get('contact', 'manage') }}{{ contact.nickname }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="No"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          {{ tr.get('contact', 'deleteq') }} {{ tr.get('contact', 'deletey') }}
        </p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-warning"
          data-bs-dismiss="modal"
          data-bs-placement="top"
          [title]="tr.get('contact', 'deleten')"
        >
          {{ tr.get('button', 'no') }}
        </button>
        <button
          (click)="removeContact()"
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="modal"
          data-bs-placement="top"
          [title]="tr.get('contact', 'deletey')"
        >
          {{ tr.get('button', 'yes') }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Block Modal -->
<div
  class="modal fade"
  id="blockModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ tr.get('contact', 'manage') }}{{ contact.nickname }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="No"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          {{ tr.get('contact', 'blockq') }} {{ tr.get('contact', 'blocky') }}
        </p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-warning"
          data-bs-dismiss="modal"
          data-bs-placement="top"
          [title]="tr.get('contact', 'blockn')"
        >
          {{ tr.get('button', 'no') }}
        </button>
        <button
          (click)="blockContact(true)"
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="modal"
          data-bs-placement="top"
          [title]="tr.get('contact', 'blocky')"
        >
          {{ tr.get('button', 'yes') }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- UnBlock Modal -->
<div
  class="modal fade"
  id="unblockModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ tr.get('contact', 'manage') }}{{ contact.nickname }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="No"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          {{ tr.get('contact', 'unblockq') }}
          {{ tr.get('contact', 'unblocky') }}
        </p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-warning"
          data-bs-dismiss="modal"
          data-bs-placement="top"
          [title]="tr.get('contact', 'unblockn')"
        >
          {{ tr.get('button', 'no') }}
        </button>
        <button
          (click)="blockContact(false)"
          type="button"
          class="btn btn-primary"
          data-bs-dismiss="modal"
          data-bs-placement="top"
          [title]="tr.get('contact', 'unblocky')"
        >
          {{ tr.get('button', 'yes') }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Edit Modal -->
<div
  class="modal fade"
  id="editModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ tr.get('contact', 'manage') }}{{ contact.nickname }}
        </h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="No"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ tr.get('contact', 'edit') }}</p>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <br />
        <div class="container-fluid">
          <div class="row no-gutters mb-4">
            <div class="col-sm-10">
              <input
                attr.id="{{ 'changeName' + cid }}"
                class="form-control"
                type="text"
                name="name"
                [placeholder]="tr.get('contact', 'editt')"
              />
            </div>
            <div class="col-sm-2">
              <button
                (click)="rename()"
                class="btn-send btn-warning btn-lg btn-block save-edit"
                data-bs-dismiss="modal"
              >
                {{ tr.get('button', 'save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
