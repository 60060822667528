import {
  MessageObserver,
  SystemBusService
} from '../../../service/system-bus.service';
import {
  Component,
  OnDestroy,
  ComponentFactoryResolver,
  Injector,
  Inject
} from '@angular/core';
import { TranslationService } from '../../../service/translation.service';
import { DOCUMENT } from '@angular/common';
import { ToastComponent } from './toast.component';
import { AudioService } from '../../../service/audio.service';

@Component({
  selector: 'app-warning',
  templateUrl: './notification.component.html',
  styleUrls: ['../../../../assets/css/custom.css']
})
export class NotificationComponent implements OnDestroy, MessageObserver {
  private messagecount = 1;
  private openIds = new Set<number>();
  private ready = false;

  private keyMap = new Map<string, number>();
  constructor(
    private systemBus: SystemBusService,
    public tr: TranslationService,
    private resolver: ComponentFactoryResolver,
    private injector: Injector,
    private audioSvc: AudioService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // this.rootElement = document.getElementById('warningComponent');
    this.systemBus.subscribe(this);
    setTimeout(() => {
      this.ready = true;
    }, 10000);
  }

  onBusMessage(message: any, type: string): void {
    console.log('Notification: onBuxMessage: ', type, message);
    this.processMessage(message, type);
  }

  private processMessage(message: any, type: string) {
    if (type.startsWith('warning')) {
      //    {
      //    type: success|info|warning|danger, cancel to cancel previous message
      //      messageID: message id used to cancel later, no id, no cancel
      //      message: message text,
      //      timeOut: timeout in seconds,
      //    }
      const messageID = message.messageID;
      if (message.type === 'cancel') {
        let cnt = this.keyMap.get(messageID);
        if (cnt) {
          this.closeNotice(cnt);
          this.keyMap.delete(messageID);
        } else {
          console.log('Notification svc: cancel id not found ', messageID);
        }
      } else {
        (<any>$('#warningPopup')).modal('show');
        const cnt = this.messagecount++;
        this.openIds.add(cnt);
        $('#warningComponent').append(
          '<div id=warn' +
            cnt +
            ' class="alert alert-' +
            message.type +
            ' alert-dismissible fade show" role="alert" style="border-radius: 0px">' +
            message.message +
            '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"> ' +
            ' <span aria-hidden="true">&times;</span>' +
            '</button></div>'
        );
        $('#warn' + cnt).on('closed.bs.alert', () => {
          this.closeNotice(cnt);
        });

        if (messageID) {
          this.keyMap.set(messageID, cnt);
        }
        if (message.timeOut) {
          setTimeout(() => {
            // this will automatically close the alert
            this.closeNotice(cnt);
            if (messageID) {
              this.keyMap.delete(messageID);
            }
          }, message.timeOut * 1000);
        }
      }
    } else {
      this.addToast(message);
    }
  }

  private addToast(message: string) {
    console.log('Notification: Toast ', message);
    const factory = this.resolver.resolveComponentFactory(ToastComponent);
    const componentRef = factory.create(this.injector);

    componentRef.instance.message = message;
    componentRef.hostView.detectChanges();
    const { nativeElement } = componentRef.location;
    componentRef.instance.afterClose.subscribe(() => {
      this.document.body.removeChild(nativeElement);
      componentRef.destroy();
    });

    this.document.body.appendChild(nativeElement);

    if (!this.document.hasFocus()) {
      this.sendNotofocation(message);
    }
  }

  private sendNotofocation(message: string) {
    // don't send notifications if we just started
    if (this.ready) {
      if ('Notification' in window && Notification.permission === 'granted') {
        let cfg = {
          body: message,
          icon: '/assets/img/favicon.png',
          tag: 'Toast',
          silent: true,
          renotify: true,
          requireInteraction: false
        };
        navigator.serviceWorker.ready.then((registration) => {
          console.log('Notification: sendNotification: ' + message);
          registration.showNotification('Message', cfg);
        });
      } else {
        console.log('Notifications not granted');
        this.audioSvc.playText(message);
      }
    }
  }

  public closeNotice(cnt: number) {
    if (this.openIds.delete(cnt)) {
      (<any>$('#warn' + cnt)).alert('close');
    }
    if (this.openIds.size === 0) {
      (<any>$('#warningPopup')).modal('hide');
    }
  }

  public closeAll() {
    this.openIds.forEach((cnt: number) => {
      this.closeNotice(cnt);
    });
    if (this.openIds.size === 0) {
      (<any>$('#warningPopup')).modal('hide');
    }
  }

  busMessageFilter(messageType: string): boolean {
    return messageType.startsWith('warning') || messageType.startsWith('toast');
  }

  ngOnDestroy(): void {
    this.systemBus.unSubscribe(this);
  }
}
