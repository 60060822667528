<button
  (click)="toggleKeys()"
  style="white-space: normal"
  type="button"
  class="collapsed btn-outline-contact btn-lg btn-block"
  data-bs-toggle="collapse"
  data-bs-parent="#accordion"
  data-bs-target="#collapseKeys"
  aria-expanded="false"
  data-bs-placement="top"
  [title]="tr.get('account-addons', 'keysp')"
  [ngClass]="{ 'btn-primary active': showkeys, 'btn-default': !showkeys }"
>
  {{ tr.get('account-addons', 'keyst') }}
</button>
<div id="collapseKeys" class="panel-collapse collapse" aria-expanded="false">
  <div class="modal-dialog modal-dialog-centered eqmodal mt-1" role="document">
    <div class="modal-content">
      <div class="modal-body ta-center">
        <h3>{{ tr.get('account-addons', 'keys-desc') }}</h3>
        <p>
          {{ tr.get('account-addons', 'addons-price-info') }} $5.00
          <small>{{ tr.get('account-addons', 'addons-price') }}</small>
        </p>
        {{ KEYS ? 'Enabled' : 'Disabled'
        }}<input
          (click)="keysClick()"
          type="checkbox"
          id="cmn-toggle-1"
          class="checkbox-template ml-2"
          data-bs-placement="top"
          [checked]="KEYS"
        />
        <div *ngIf="KEYSChanged">
          <input
            value="Authorize"
            type="button"
            class="btn-lg btn-info btn-block mt-1"
            (click)="authorize()"
          />
        </div>
      </div>
    </div>
  </div>
  <br />
</div>

<button
  (click)="toggleProxy()"
  style="margin-top: 4px; white-space: normal"
  type="button"
  class="collapsed btn-outline-contact btn-lg btn-block"
  data-bs-toggle="collapse"
  data-bs-parent="#accordion"
  data-bs-target="#collapseProxy"
  aria-expanded="false"
  data-bs-placement="top"
  [title]="tr.get('account-addons', 'dialinp')"
  [ngClass]="{ 'btn-primary active': showlocal, 'btn-default': !showlocal }"
>
  {{ tr.get('account-addons', 'proxy') }}
</button>
<div id="collapseProxy" class="panel-collapse collapse" aria-expanded="false">
  <div class="modal-dialog modal-dialog-centered eqmodal mt-1" role="document">
    <div class="modal-content">
      <div class="modal-body ta-center">
        <h3>{{ tr.get('account-addons', 'proxy-desc') }}</h3>
        <p>
          {{ tr.get('account-addons', 'addons-price-info') }} $25.00
          <small>{{ tr.get('account-addons', 'addons-price') }}</small>
        </p>
        {{ PROXY ? 'Enabled' : 'Disabled'
        }}<input
          (click)="proxyClick()"
          type="checkbox"
          id="cmn-toggle-2"
          class="checkbox-template ml-2"
          data-bs-placement="top"
          [checked]="PROXY"
        />
        <div *ngIf="PROXYChanged">
          <input
            value="Authorize"
            type="button"
            class="btn-lg btn-info btn-block"
            (click)="authorize()"
          />
        </div>
      </div>
    </div>
  </div>
  <br />
</div>

<button
  (click)="toggleLocalPhone()"
  style="margin-top: 4px; white-space: normal"
  type="button"
  class="collapsed btn-outline-contact btn-lg btn-block"
  data-bs-toggle="collapse"
  data-bs-parent="#accordion"
  data-bs-target="#collapseNumbers"
  aria-expanded="false"
  data-bs-placement="top"
  [title]="tr.get('account-addons', 'dialinp')"
  [ngClass]="{ 'btn-primary active': showlocal, 'btn-default': !showlocal }"
>
  {{ tr.get('account-addons', 'dialint') }}
</button>
<div id="collapseNumbers" class="panel-collapse collapse" aria-expanded="false">
  <div class="modal-dialog modal-dialog-centered eqmodal mt-1" role="document">
    <div class="modal-content">
      <div class="modal-body ta-center">
        <h3>{{ tr.get('account-addons', 'dialin-desc') }}</h3>
        <p>
          {{ tr.get('account-addons', 'addons-price-info') }} $25.00
          <small>{{ tr.get('account-addons', 'addons-price') }}</small>
        </p>
        {{ LOCALPHONE ? 'Enabled' : 'Disabled'
        }}<input
          (click)="localPhoneClick()"
          type="checkbox"
          id="cmn-toggle-2"
          class="checkbox-template ml-2"
          data-bs-placement="top"
          [checked]="LOCALPHONE"
        />
        <div *ngIf="LOCALPHONEChanged">
          <input
            value="Authorize"
            type="button"
            class="btn-lg btn-info btn-block"
            (click)="authorize()"
          />
        </div>
      </div>
    </div>
  </div>
  <br />
</div>
<button
  (click)="toggleUnbranded()"
  style="margin-top: 4px; white-space: normal"
  type="button"
  class="collapsed btn-outline-contact btn-lg btn-block"
  data-bs-toggle="collapse"
  data-bs-parent="#accordion"
  data-bs-target="#collapseUnbranded"
  aria-expanded="false"
  data-bs-placement="top"
  [title]="tr.get('account-addons', 'custom-urlp')"
  [ngClass]="{
    'btn-primary active': showunbranded,
    'btn-default': !showunbranded
  }"
>
  {{ tr.get('account-addons', 'custom-urlt') }}
</button>
<div
  id="collapseUnbranded"
  class="panel-collapse collapse"
  aria-expanded="false"
>
  <div class="modal-dialog modal-dialog-centered eqmodal mt-1" role="document">
    <div class="modal-content">
      <div class="modal-body ta-center">
        <h3>{{ tr.get('account-addons', 'custom-url-desc') }}</h3>
        <p>
          {{ tr.get('account-addons', 'addons-price-info') }}$25.00
          <small>{{ tr.get('account-addons', 'addons-price') }}</small>
        </p>
        {{ UNBRANDEDLINK ? 'Enabled' : 'Disabled'
        }}<input
          (click)="unbrandedlinkClick()"
          type="checkbox"
          id="cmn-toggle-3"
          class="checkbox-template ml-2"
          data-bs-placement="top"
          [checked]="UNBRANDEDLINK"
        />
        <div *ngIf="UNBRANDEDLINKChanged">
          <form class="mt-2" role="form" action="">
            <input
              class="form-control mb-2"
              type="text"
              [(ngModel)]="domainNames[0]"
              name="hostname"
              [placeholder]="tr.get('account-addons', 'custom-url-p')"
            />
            <!-- <input n value="&nbsp;&nbsp;Delete&nbsp;&nbsp;" type="button" class="btn-lg btn-info btn-block mb-2" (click)="deleteDomain()" data-bs-toggle="collapse" data-bs-target="#collapseUnbranded" /> -->
            <input
              value="&nbsp;&nbsp;Authorize&nbsp;&nbsp;"
              type="button"
              class="btn-lg btn-info btn-block"
              (click)="authorize()"
              data-bs-toggle="collapse"
              data-bs-target="#collapseUnbranded"
            />
          </form>
        </div>
        <div *ngIf="UNBRANDEDLINK && !UNBRANDEDLINKChanged">
          <form class="mt-2" role="form" action="">
            <input
              class="form-control mb-2"
              type="text"
              [(ngModel)]="domainNames[0]"
              name="hostname"
              [placeholder]="tr.get('account-addons', 'custom-url-p')"
            />
            <input
              n
              value="&nbsp;&nbsp;Delete&nbsp;&nbsp;"
              type="button"
              class="btn-lg btn-info btn-block mb-2"
              (click)="deleteDomain()"
              data-bs-toggle="collapse"
              data-bs-target="#collapseUnbranded"
            />
            <input
              value="&nbsp;&nbsp;Create&nbsp;&nbsp;"
              type="button"
              class="btn-lg btn-info btn-block"
              (click)="saveDomain()"
              data-bs-toggle="collapse"
              data-bs-target="#collapseUnbranded"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
  <br />
</div>
<!-- <button style="margin-bottom: 4px; white-space: normal;" type="button" class="collapsed btn btn-light btn-lg btn-block" data-bs-toggle="collapse"
  data-bs-parent="#accordion" data-bs-target="#collapseTotals" aria-expanded="false" data-bs-placement="top" title="View Outgoing Billable Calls">Use Your Twilio Account</button>
<div id="collapseTotals" class="panel-collapse collapse" aria-expanded="false" style="background-color: #2c3e50; color: #bdc3c7; text-align: center; border: 1px solid #2980b9; padding-bottom: 4px; height: 0px;">
  <br />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm">
        <h5>Need full control of your calls and numbers? Connect your Twilio API Keys to eqCall!</h5>
      </div>
      <div class="col-sm">
        Deducted from your credit @
        <h3>$25.00
          <small> / month</small>
        </h3>
      </div>
      <div class="col-sm-auto">
        <div class="row">
          <div class="col-sm-auto">
            <h3>{{TWILIOAPI? 'Enabled': 'Disabled'}}</h3>
          </div>
          <div class="col-sm-auto">
            <div class="switch">
              <input id="cmn-toggle-4" class="cmn-toggle cmn-toggle-round-flat" type="checkbox" [checked]="TWILIOAPI">
              <label (click)="twilioClick()" for="cmn-toggle-4"></label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br />
</div> -->
<button
  (click)="toggleDialOut()"
  style="margin-top: 4px; white-space: normal"
  type="button"
  class="collapsed btn-outline-contact btn-lg btn-block"
  data-bs-toggle="collapse"
  data-bs-parent="#accordion"
  data-bs-target="#collapsedialout"
  aria-expanded="false"
  data-bs-placement="top"
  [title]="tr.get('account-addons', 'dialoutp')"
  [ngClass]="{ 'btn-primary active': showdialout, 'btn-default': !showdialout }"
>
  {{ tr.get('account-addons', 'dialoutt') }}
</button>
<div id="collapsedialout" class="panel-collapse collapse" aria-expanded="false">
  <div class="modal-dialog modal-dialog-centered eqmodal mt-1" role="document">
    <div class="modal-content">
      <div class="modal-body ta-center">
        <h3>Dial out</h3>
        <p>Deducted from your credit @ $5.00 <small> / month</small></p>
        {{ DIALOUT ? 'Enabled' : 'Disabled'
        }}<input
          (click)="dialoutClick()"
          id="cmn-toggle-5"
          class="checkbox-template ml-2"
          type="checkbox"
          [checked]="DIALOUT"
        />
        <div *ngIf="DIALOUTChanged">
          <input
            value="Authorize"
            type="button"
            class="btn-lg btn-info btn-block mt-2"
            (click)="authorize()"
          />
        </div>
      </div>
    </div>
  </div>
  <br />
</div>
<button
  (click)="toggleHostedCalls()"
  style="margin-top: 4px; white-space: normal"
  type="button"
  class="collapsed btn-outline-contact btn-lg btn-block"
  data-bs-toggle="collapse"
  data-bs-parent="#accordion"
  data-bs-target="#collapseHosted"
  aria-expanded="false"
  data-bs-placement="top"
  [title]="tr.get('account-addons', 'custom-urlp')"
  [ngClass]="{ 'btn-primary active': showhosted, 'btn-default': !showhosted }"
>
  Allow phone calls into your meetings (What is this?)
</button>
<div id="collapseHosted" class="panel-collapse collapse" aria-expanded="false">
  <div class="modal-dialog modal-dialog-centered eqmodal mt-1" role="document">
    <div class="modal-content">
      <div class="modal-body ta-center">
        <h3>Hosted Calls</h3>
        <p>Deducted from your credit @ $10.00 <small> / month</small></p>
        {{ HOSTEDCALLS ? 'Enabled' : 'Disabled'
        }}<input
          (click)="hostedCallsClick()"
          for="cmn-toggle-6"
          class="checkbox-template ml-2"
          type="checkbox"
          [checked]="HOSTEDCALLS"
        />
        <div *ngIf="HOSTEDCALLSChanged">
          <input
            value="Authorize"
            type="button"
            class="btn-lg btn-info btn-block mt-2"
            (click)="authorize()"
          />
        </div>
      </div>
    </div>
  </div>
  <br />
</div>
