<div
  class="modal fade"
  id="tdModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <!-- Redacted Modal Markup criticall.html-->
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Test Selected Devices</h5>
        <button
          (click)="cancel()"
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="No"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="d-flex justify-content-center">
          <video
            id="audiotestplayer"
            width="280"
            style="border: 0px solid #fff; background: black"
          ></video>
        </div>
        <div class="d-flex justify-content-center">
          <canvas
            id="audiotestcanvas"
            width="10px"
            height="280px"
            style="
              transform: rotate(90deg);
              position: absolute;
              top: 22px;
              border: 0px solid #fff;
            "
          ></canvas>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button
          (click)="go()"
          id="audiotestrecord"
          type="button"
          class="btn btn-primary"
        >
          {{ buttonMSG }}
        </button>
      </div>
    </div>
  </div>
</div>
