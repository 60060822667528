<div *ngIf="infoPad">
  <div class="span4">
    <div
      style="
        position: absolute;
        width: 100%;
        z-index: 1;
        left: 0;
        top: 0;
        height: 100%;
        background: #192833;
        border: 1px solid rgb(49, 176, 213);
        overflow-x: hidden;
      "
    >
      <!-- Change to in call View Overlay -->
      <section>
        <img
          src="../assets/img/home-inner.jpg"
          class="bg"
          alt=""
          style="position: absolute; top: 0; left: 0; height: 100%"
        />
        <div class="callout-dialer">
          <br />
          <h1 *ngIf="incall">Number</h1>
          <!-- <h1 *ngIf="state === ''">Voice Call</h1> -->
          <span *ngIf="inCallConnecting"
            >Calling <span class="d">&nbsp;.</span><span class="d d-2">.</span
            ><span class="d d-3">.</span>
          </span>
          <div *ngIf="incall && dialPad" class="phone-incall"></div>
          <div class="row no-gutters mt-1 mb-1">
            <div class="col-sm">
              <p *ngIf="!incall">Enter a number to dial!</p>
              <p *ngIf="incall">Call Information</p>
              <div *ngIf="incall">
                <p *ngFor="let msg of connectionInfo">{{ msg }}</p>
                <div
                  *ngIf="
                    incall &&
                    hosted &&
                    keys.length > 0 &&
                    !simple &&
                    connections.length > 0
                  "
                >
                  <input
                    type="text"
                    name="name"
                    class="form-control form-control-lg input-sm"
                    [placeholder]="tr.get('dialer', 'name')"
                    [(ngModel)]="nameToCall"
                  />
                  <!--  <label for="keys">Key</label> -->
                  <div
                    *ngIf="connections.length > 0"
                    class="col-sm"
                    style="min-width: 230px"
                  >
                    <select
                      class="form-control"
                      (change)="setKey($event.target.value)"
                    >
                      <option value="" selected disabled hidden="true">
                        Choose Key
                      </option>
                      <option *ngFor="let k of keys">{{ k.keyCode }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <p [hidden]="!showCalling">{{ connectionInfo }}</p>
          </div>
        </div>
        <div
          class="support-dialer"
          style="
            position: absolute;
            bottom: 0px;
            right: 0px;
            padding: 0px;
            width: 100%;
          "
        >
          <div class="phone">
            <div class="num-pad">
              <div class="d-flex align-items-start">
                <div class="d-flex flex-row" style="margin-left: -14px">
                  <div class="p-3">
                    <button
                      *ngIf="infoPad"
                      class="num-call"
                      [ngStyle]="{
                        'pointer-events': !dialPad ? 'auto' : 'none',
                        color: !dialPad ? '#2ecc71' : '#2c3e50'
                      }"
                      data-bs-placement="top"
                      [title]="tr.get('dialer', 'call')"
                      title="Call the Entered Number"
                      (click)="dialpad()"
                      style="
                        position: relative;
                        margin-left: 10px;
                        margin-top: 0px;
                        background-color: transparent;
                        font-size: 20px;
                        font-weight: normal;
                      "
                    >
                      <i class="material-icons">dialpad</i>
                    </button>
                    <button
                      *ngIf="incall && dialPad"
                      class="num-call"
                      [ngStyle]="{
                        'pointer-events': incall ? 'auto' : 'none',
                        color: incall ? '#2ecc71' : '#2c3e50'
                      }"
                      data-bs-placement="top"
                      [title]="tr.get('dialer', 'call')"
                      title="Call the Entered Number"
                      (click)="infopad()"
                    >
                      <i class="fa fa-info"></i>
                    </button>
                  </div>
                  <div class="p-3">
                    <button
                      class="num-add"
                      data-bs-placement="top"
                      [title]="tr.get('dialer', 'add')"
                      [ngStyle]="{
                        'pointer-events': !incall ? 'none' : 'auto',
                        color: !incall ? '#2c3e50' : '#3498db'
                      }"
                      (click)="addToConference()"
                      style="
                        position: relative;
                        margin-left: 0px;
                        margin-top: 0px;
                        background-color: transparent;
                        font-size: 20px;
                        font-weight: normal;
                      "
                    >
                      <i class="material-icons">person_add</i>
                    </button>
                  </div>
                  <div class="p-3">
                    <button
                      class="num-pause"
                      data-bs-placement="top"
                      [title]="tr.get('dialer', 'pause')"
                      [ngStyle]="{
                        'pointer-events': !incall ? 'none' : 'auto',
                        color: !incall ? '#2c3e50' : '#bdc3c7'
                      }"
                      (click)="disconnect()"
                      style="
                        position: relative;
                        margin-left: 0px;
                        margin-top: 0px;
                        background-color: transparent;
                        font-size: 20px;
                        font-weight: normal;
                      "
                    >
                      <i class="material-icons">phone_paused</i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-end">
            <button
              class="btn btn-danger btn-lg btn-block"
              data-bs-placement="top"
              [title]="tr.get('dialer', 'end')"
              (click)="disconnect()"
              [ngStyle]="{
                'pointer-events': !incall ? 'none' : 'auto',
                color: !incall ? '#2c3e50' : '#e74c3c'
              }"
            >
              <i class="fa fa-phone call-end"></i>
            </button>
          </div>
          <!--
          <button class="btn btn-danger btn-lg btn-block" data-bs-placement="top" [title]="tr.get('dialer','end')" [ngStyle]="{'pointer-events':!incall?'none':'auto', 'color':!incall?'#2c3e50':'#e74c3c'}" (click)="disconnect()" ><i class="fa fa-phone call-end"></i>
          </button>
          -->
        </div>
      </section>
    </div>
  </div>
</div>
<div *ngIf="!infoPad" class="phone">
  <div class="num-pad">
    <input
      type="text"
      class="form-control form-control-lg input-sm input-dialpad"
      [ngStyle]="{ 'border-color': !validNumber ? '#31b0d5' : 'green' }"
      appTelInput
      (isValidNumber)="isValidNumber($event)"
      (telNumberOutput)="setNumber($event)"
      (intlTelInputObject)="telInputObject($event)"
      (countryChange)="onCountryChange($event)"
    />
    <button
      *ngIf="dialPad"
      class="num-call"
      aria-controls="collapseAdd"
      aria-expanded="true"
      [ngStyle]="{
        'pointer-events': !incall ? 'auto' : 'none',
        color: !incall ? '#2ecc71' : '#2c3e50',
        'border-color': !validNumber ? '#31b0d5' : 'green'
      }"
      data-bs-placement="top"
      [title]="tr.get('dialer', 'call')"
      title="Call the Entered Number"
      (click)="call()"
    >
      <i class="fa fa-phone"></i>
    </button>
    <button
      *ngIf="infoPad"
      class="num-call"
      [ngStyle]="{
        'pointer-events': !dialPad ? 'auto' : 'none',
        color: !dialPad ? '#2ecc71' : '#2c3e50'
      }"
      data-bs-placement="top"
      [title]="tr.get('dialer', 'call')"
      title="Call the Entered Number"
      (click)="dialpad()"
    >
      <i class="fa fa-th"></i>
    </button>
    <button
      *ngIf="incall && dialPad"
      class="num-call"
      [ngStyle]="{
        'pointer-events': incall ? 'auto' : 'none',
        color: incall ? '#2ecc71' : '#2c3e50'
      }"
      data-bs-placement="top"
      [title]="tr.get('dialer', 'call')"
      title="Call the Entered Number"
      (click)="infopad()"
    >
      <i class="fa fa-info"></i>
    </button>
    <button
      class="num-add"
      data-bs-placement="top"
      [title]="tr.get('dialer', 'add')"
      style="
        position: absolute;
        margin-top: -51px;
        background-color: #141f28;
        box-shadow: none;
        outline: none;
        border: none;
        height: 50px;
        border-right: 1px solid #3498db;
        width: 60px;
      "
    >
      <i class="fa fa-times" (click)="buttonPress('DEL')"></i>
    </button>
    <button
      class="num-end"
      data-bs-placement="top"
      [title]="tr.get('dialer', 'end')"
      [ngStyle]="{
        'pointer-events': !incall ? 'none' : 'auto',
        color: !incall ? '#2c3e50' : '#e74c3c'
      }"
      (click)="disconnect()"
    >
      <i class="fa fa-phone call-end"></i>
    </button>
    <button
      class="num-add"
      data-bs-placement="top"
      [title]="tr.get('dialer', 'add')"
      [ngStyle]="{
        'pointer-events': !incall ? 'none' : 'auto',
        color: !incall ? '#2c3e50' : '#3498db'
      }"
      (click)="addToConference()"
    >
      <i class="fa fa-plus"></i>
    </button>
    <button
      class="num-pause"
      data-bs-placement="top"
      [title]="tr.get('dialer', 'pause')"
      [ngStyle]="{
        'pointer-events': !incall ? 'none' : 'auto',
        color: !incall ? '#2c3e50' : '#bdc3c7'
      }"
      (click)="disconnect()"
    >
      <i class="fa fa-pause"></i>
    </button>
    <div class="span4" (click)="buttonPress('1')">
      <div class="num">
        <div class="txt">1</div>
      </div>
    </div>
    <div class="span4" (click)="buttonPress('2')">
      <div class="num">
        <div class="txt">
          2
          <span class="small">
            <p>ABC</p>
          </span>
        </div>
      </div>
    </div>
    <div class="span4" (click)="buttonPress('3')">
      <div class="num">
        <div class="txt">
          3
          <span class="small">
            <p>DEF</p>
          </span>
        </div>
      </div>
    </div>
    <div class="span4" (click)="buttonPress('4')">
      <div class="num">
        <div class="txt">
          4
          <span class="small">
            <p>GHI</p>
          </span>
        </div>
      </div>
    </div>
    <div class="span4" (click)="buttonPress('5')">
      <div class="num">
        <div class="txt">
          5
          <span class="small">
            <p>JKL</p>
          </span>
        </div>
      </div>
    </div>
    <div class="span4" (click)="buttonPress('6')">
      <div class="num">
        <div class="txt">
          6
          <span class="small">
            <p>MNO</p>
          </span>
        </div>
      </div>
    </div>
    <div class="span4" (click)="buttonPress('7')">
      <div class="num">
        <div class="txt">
          &nbsp;7
          <span class="small">
            <p>PQRS</p>
          </span>
        </div>
      </div>
    </div>
    <div class="span4" (click)="buttonPress('8')">
      <div class="num">
        <div class="txt">
          8
          <span class="small">
            <p>TUV</p>
          </span>
        </div>
      </div>
    </div>
    <div class="span4" (click)="buttonPress('9')">
      <div class="num">
        <div class="txt">
          9
          <span class="small">
            <p>WXYZ</p>
          </span>
        </div>
      </div>
    </div>
    <div class="span4" (click)="buttonPress('*')">
      <div class="num">
        <div class="txt">*</div>
      </div>
    </div>
    <div class="span4" (click)="buttonPress('0')">
      <div class="num">
        <div class="txt">
          0
          <span class="small">
            <p></p>
          </span>
        </div>
      </div>
    </div>
    <div class="span4" (click)="buttonPress('#')">
      <div class="num">
        <div class="txt">#</div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
