<button
  (click)="toggleActivity()"
  style="margin-bottom: 4px; white-space: normal"
  type="button"
  class="collapsed btn-outline-contact btn-lg btn-block"
  data-bs-toggle="collapse"
  data-bs-parent="#accordion"
  data-bs-target="#collapseCalllog"
  aria-expanded="false"
  data-bs-placement="top"
  [title]="tr.get('account-billing', 'activityt')"
  [ngClass]="{
    'btn-primary active': showactivity,
    'btn-default': !showactivity
  }"
>
  {{ tr.get('account-billing', 'activity') }}
</button>
<div
  id="collapseCalllog"
  class="panel-collapse collapse"
  aria-expanded="false"
  style="
    text-align: left;
    padding-top: 5px;
    border: 0px solid #2980b9;
    padding-bottom: 4px;
    height: 0px;
  "
>
  <input
    class="form-control form-control-lg input-sm chat-window-input"
    type="month"
    #callmonth
    id="callMonth"
    name="month"
    [value]="thisMonth"
    [max]="thisMonth"
    pattern="[0-9]{4}-[0-9]{2}"
    (change)="callLogMonthSet(callmonth.value)"
    style="background: rgb(62 184 239/ 25%); cursor: pointer"
  />
  <div *ngIf="calllog; then callThenBlock; else callElseBlock"></div>
  <ng-template #callThenBlock>
    <div
      class="block"
      style="overflow-y: scroll; max-height: 62vh; border: 1px solid #7f8c8d"
    >
      <app-calllog [logs]="calllog"></app-calllog>
    </div>
  </ng-template>
  <ng-template #callElseBlock>
    <p>No Calls Recorded</p>
  </ng-template>
</div>
<button
  (click)="toggleBilling()"
  style="margin-bottom: 4px; white-space: normal"
  type="button"
  class="collapsed btn-outline-contact btn-lg btn-block"
  data-bs-toggle="collapse"
  data-bs-parent="#accordion"
  data-bs-target="#collapseStatement"
  aria-expanded="false"
  data-bs-placement="top"
  [title]="tr.get('account-billing', 'current-billt')"
  [ngClass]="{ 'btn-primary active': showbilling, 'btn-default': !showbilling }"
>
  {{ tr.get('account-billing', 'current-bill') }}
</button>
<div
  id="collapseStatement"
  class="panel-collapse collapse"
  aria-expanded="false"
  style="
    background-color: transparent;
    color: #bdc3c7;
    text-align: left;
    padding-top: 5px;
    border: 0px solid #2980b9;
    padding-bottom: 4px;
    height: 0px;
  "
>
  <div *ngIf="invoice; then thenBlock; else elseBlock"></div>
  <ng-template #thenBlock>
    <div
      class="block"
      style="overflow-y: scroll; max-height: 62vh; border: 1px solid #7f8c8d"
    >
      <app-invoice [invoice]="invoice"></app-invoice>
    </div>
    <!-- Order Details <div class="pull-right">Total amount due {{invoice.amount_due /
			100 | currency}}{{invoice.currency}} in Cents. Date {{invoice.date *
			1000| date}}. Billing {{invoice.billing}}. Tax {{invoice.tax}}.</div>-->
  </ng-template>
  <ng-template #elseBlock>
    <p>No Subscriptions</p>
  </ng-template>
  <br />
  <br />
</div>
<button
  (click)="toggleBills()"
  style="margin-bottom: 4px; white-space: normal"
  type="button"
  class="collapsed btn-outline-contact btn-lg btn-block"
  data-bs-toggle="collapse"
  data-bs-parent="#accordion"
  data-bs-target="#collapseHistory"
  aria-expanded="false"
  data-bs-placement="top"
  [title]="tr.get('account-billing', 'past-billst')"
  [ngClass]="{ 'btn-primary active': showbills, 'btn-default': !showbills }"
>
  {{ tr.get('account-billing', 'past-bills') }}
</button>
<div
  id="collapseHistory"
  class="panel-collapse collapse"
  aria-expanded="false"
  style="
    background-color: #2c3e50;
    color: #bdc3c7;
    text-align: left;
    padding-top: 5px;
    border: 0px solid #2980b9;
    padding-bottom: 4px;
    height: 0px;
  "
>
  <div
    *ngIf="invoices"
    class="block"
    style="overflow-y: scroll; max-height: 62vh; border: 1px solid #7f8c8d"
  >
    <div *ngFor="let inv of invoices.data">
      <app-invoice [invoice]="inv"></app-invoice>
    </div>
  </div>
</div>
