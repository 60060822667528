<div (click)="control()">
  <div class="heading">
    <p>{{ rtcConnection.getRemoteNickName() }}</p>
  </div>
  <div class="wrench-background">
    <!-- <button (click)="channel(connection)" class="button-container mute control-wrench" type="button" data-bs-placement="top" [ngStyle]="{'background-color':selectedConnection.destinationID===connection.destinationID ?  '#e74c3c' : 'auto', 'color':selectedConnection.destinationID===connection.destinationID ?  'white' : 'auto'}"><i class="fa fa-wrench"></i>
                </button> -->
    <button
      (click)="toggleMonitor(); control()"
      class="button-container mute control-wrench"
      type="button"
      data-bs-placement="top"
    >
      <i class="fa fa-wrench"></i>
    </button>
  </div>
  <div class="channel">
    <div class="d-flex justify-content-center mt-1">
      <div class="p-2">
        <div
          class="button-container mute mr-3"
          (click)="soloClick($event)"
          style="margin-top: -14px"
        >
          S
          <div class="button-control"></div>
        </div>
      </div>
      <div class="p-2">
        <div
          class="button-container mute"
          (click)="muteClick($event)"
          style="margin-top: -14px"
        >
          <div class="button-control">M</div>
        </div>
      </div>
    </div>
    <div class="dial-container mt-1">
      <p class="label">{{ tr.get('mixer', 'gate') }}</p>
      <div class="notches">
        <div
          class="minutes"
          (pan)="dialNgPan($event)"
          (click)="dialNGClick(180)"
          style="transform: rotate(1deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialNgPan($event)"
          (click)="dialNGClick(204)"
          style="transform: rotate(24deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialNgPan($event)"
          (click)="dialNGClick(228)"
          style="transform: rotate(48deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialNgPan($event)"
          (click)="dialNGClick(252)"
          style="transform: rotate(72deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialNgPan($event)"
          (click)="dialNGClick(278)"
          style="transform: rotate(96deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialNgPan($event)"
          (click)="dialNGClick(300)"
          style="transform: rotate(120deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialNgPan($event)"
          (click)="dialNGClick(60)"
          style="transform: rotate(240deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialNgPan($event)"
          (click)="dialNGClick(84)"
          style="transform: rotate(264deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialNgPan($event)"
          (click)="dialNGClick(108)"
          style="transform: rotate(288deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialNgPan($event)"
          (click)="dialNGClick(132)"
          style="transform: rotate(312deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialNgPan($event)"
          (click)="dialNGClick(156)"
          style="transform: rotate(336deg)"
        ></div>
      </div>
      <div
        [attr.id]="ngID"
        class="dial"
        (pan)="dialNgPan($event)"
        (dblclick)="dialDblClick($event, 'gate')"
      >
        <div class="dial-inner"></div>
      </div>
    </div>
    <div class="dial-container eq-dial">
      <p class="label">{{ tr.get('mixer', 'eq') }}</p>
      <div class="notches">
        <div
          class="minutes zero"
          (pan)="dialEQPan($event)"
          (click)="dialEQClick(180)"
          style="transform: rotate(1deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialEQPan($event)"
          (click)="dialEQClick(204)"
          style="transform: rotate(24deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialEQPan($event)"
          (click)="dialEQClick(228)"
          style="transform: rotate(48deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialEQPan($event)"
          (click)="dialEQClick(252)"
          style="transform: rotate(72deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialEQPan($event)"
          (click)="dialEQClick(278)"
          style="transform: rotate(96deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialEQPan($event)"
          (click)="dialEQClick(300)"
          style="transform: rotate(120deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialEQPan($event)"
          (click)="dialEQClick(60)"
          style="transform: rotate(240deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialEQPan($event)"
          (click)="dialEQClick(84)"
          style="transform: rotate(264deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialEQPan($event)"
          (click)="dialEQClick(108)"
          style="transform: rotate(288deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialEQPan($event)"
          (click)="dialEQClick(132)"
          style="transform: rotate(312deg)"
        ></div>
        <div
          class="minutes"
          (pan)="dialEQPan($event)"
          (click)="dialEQClick(156)"
          style="transform: rotate(336deg)"
        ></div>
      </div>
      <div
        [attr.id]="eqID"
        class="dial"
        (pan)="dialEQPan($event)"
        (dblclick)="dialDblClick($event, 'eq')"
      >
        <div class="dial-inner"></div>
      </div>
    </div>
    <div
      style="
        position: relative;
        margin-top: -18px;
        margin-bottom: 20px;
        width: 100%;
        height: 4px;
        background: rgb(44, 62, 80);
      "
    ></div>
    <div
      style="
        position: absolute;
        margin-top: -19px;
        margin-left: 0px;
        margin-bottom: 20px;
        width: 100px;
        height: 294px;
        background: rgba(68, 104, 135, 0.45);
      "
    ></div>

    <div class="fader-container">
      <div class="channel-notches">
        <div class="channel-notch" style="display: none"></div>
        <div class="channel-notch"></div>
        <div class="channel-notch zeroed">0</div>
        <div class="channel-notch"></div>
        <div class="channel-notch"></div>
        <div class="channel-notch"></div>
        <div class="channel-notch"></div>
        <div class="channel-notch"></div>
        <div class="channel-notch"></div>
        <div class="channel-notch"></div>
      </div>

      <div class="fader-track">
        <!-- -32 to 246 negative top -->
        <div
          [attr.id]="volID"
          class="fader"
          style="top: 30px"
          (pan)="faderPan($event)"
          (dblclick)="faderDblClick($event)"
        >
          <div></div>
        </div>
      </div>
      <canvas
        [attr.id]="volLevelID"
        width="10px"
        height="318px"
        class="channel-meter"
      >
      </canvas>
    </div>
  </div>
</div>
