import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { UserRegistrationService, UserLoginService, CognitoUtil, AwsUtil } from './service/cognito.service';
import { routing } from './app.routes';
import { HomeComponent, HomeLandingComponent } from './public/home.component';
import { EqCallHomeComponent } from './eqcall/landing/eqcall-home.component';
import { CriticallComponent } from './eqcall/criticall/criticall.component';
import { NetworkService } from './service/network.service';
import { PushService } from './service/push.service';
//import { RtcService } from './service/rtc.service';
import { LivekitService } from './service/livekit.service'
import { KeyService } from './service/key.service';
import { TwilioService } from './service/twilio.service';
import { AudioService } from './service/audio.service';
import { EqcallapiService } from './service/eqcallapi.service';
import { ContactsService } from './service/contacts.service';
import { SystemBusService } from './service/system-bus.service';
import { CordovaService } from './service/cordova.service';
import { LoginComponent } from './public/auth/login/login.component';
import { RegisterComponent } from './public/auth/register/registration.component';
import { ForgotPasswordStep1Component, ForgotPassword2Component } from './public/auth/forgot/forgotPassword.component';
import { RegistrationConfirmationComponent } from './public/auth/confirm/confirmRegistration.component';
import { ResendCodeComponent } from './public/auth/resend/resendCode.component';
import { ChatMessageComponent } from './eqcall/criticall/chat-message/chat-message.component';
import { RtcConnectionComponent } from './eqcall/criticall/rtccontainer/rtc-connection/rtc-connection.component';
import { MediaSelectorComponent } from './eqcall/criticall/media-selector/media-selector.component';
import { ContactComponent } from './eqcall/criticall/messenger/contact/contact.component';
import { MastercontrolComponent } from './eqcall/criticall/mastercontrol/mastercontrol.component';
import { AnonentryComponent } from './public/auth/anonentry/anonentry.component';
import { KeylinksComponent } from './eqcall/criticall/keylinks/keylinks.component';
import { DialerComponent } from './eqcall/criticall/dialer/dialer.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ChatWindowComponent } from './eqcall/criticall/chat-window/chat-window.component';
import { AccountComponent } from './eqcall/criticall/account/account.component';
import { KeyComponent } from './eqcall/criticall/keylinks/key/key.component';
import { GlobalchatComponent } from './eqcall/criticall/globalchat/globalchat.component';
import { GlobalchatMessageComponent } from './eqcall/criticall/globalchat/globalchat-message/globalchat-message.component';
import { NotificationComponent } from './eqcall/criticall/notification/notification.component';
import { CreditComponent } from './eqcall/criticall/account/credit/credit.component';
import { BillingComponent } from './eqcall/criticall/account/billing/billing.component';
import { ProfileComponent } from './eqcall/criticall/account/profile/profile.component';
import { TelInputDirective } from './eqcall/criticall/dialer/telinput';
import { UpgradesComponent } from './eqcall/criticall/account/upgrades/upgrades.component';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CompareDirective } from './compareDirective';
import { VideocanvasComponent } from './eqcall/criticall/videocanvas/videocanvas.component';
import { MessengerListComponent } from './eqcall/criticall/messenger-list/messenger-list.component';
import { ConfigService } from './service/config.service';
import { APP_INITIALIZER } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MixerComponent } from './eqcall/criticall/mixer/mixer.component';
import { ChannelElementComponent } from './eqcall/criticall/mixer/channel-element/channel-element.component';
import { MainChannelComponent } from './eqcall/criticall/mixer/main-channel/main-channel.component';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { ChannelControllerComponent } from './eqcall/criticall/mixer/channel-controller/channel-controller.component';
import { PopupControllerComponent } from './eqcall/criticall/popup-controller/popup-controller.component';
import { MessengerListElementComponent } from './eqcall/criticall/messenger-list/messenger-list-element/messenger-list-element.component';
import { DevicePanelComponent } from './eqcall/criticall/mixer/channel-controller/device-panel/device-panel.component';
import { LanguageComponent } from './eqcall/criticall/language/language.component';
import { RingerComponent } from './eqcall/criticall/popup-controller/ringer/ringer.component';
import { AudiotestComponent } from './eqcall/criticall/media-selector/audiotest/audiotest.component';
import { NgxEmojModule } from 'ngx-emoj';
import { MessageService } from './service/message.service';
import { GroupComponent } from './eqcall/criticall/messenger/group/group.component';
import { MessengerComponent } from './eqcall/criticall/messenger/messenger.component';
import { GroupMembersComponent } from './eqcall/criticall/messenger/group/group-members/group-members.component';
// eslint-disable-next-line max-len
import { GroupMembersElementComponent } from './eqcall/criticall/messenger/group/group-members/group-members-element/group-members-element.component';
import { RtccontainerComponent } from './eqcall/criticall/rtccontainer/rtccontainer.component';
import { RtcgridComponent } from './eqcall/criticall/rtccontainer/rtcgrid/rtcgrid.component';
import { RtcstripeComponent } from './eqcall/criticall/rtccontainer/rtcstripe/rtcstripe.component';
import { RtcuniComponent } from './eqcall/criticall/rtccontainer/rtcuni/rtcuni.component';
import { FixedaspectDirective } from './eqcall/criticall/rtccontainer/fixedaspect.directive';
import { TimerPipe } from './eqcall/criticall/rtccontainer/timer.pipe';
import { InvoiceComponent } from './eqcall/criticall/account/billing/invoice/invoice.component';
import { CalllogComponent } from './eqcall/criticall/account/billing/calllog/calllog.component';
import { VideocropperctlDirective } from './eqcall/criticall/videocropperctl.directive';
import { ToastComponent } from './eqcall/criticall/notification/toast.component';
import { CountryregionComponent } from './eqcall/criticall/account/countryregion/countryregion.component';

export class HammerConfig extends HammerGestureConfig {
	overrides = <any>{
		'pan': { direction: Hammer.DIRECTION_ALL }
	};
}

export function load(http: HttpClient, config: ConfigService) {
	return async (): Promise<boolean> => {
		let data = null;
		try {
			data = await http.get('./config.json').toPromise();
		} catch (err) {
			console.error(err);
		}
		await config.init(data);
		return true
	}

}

@NgModule({
	declarations: [
		LoginComponent,
		RegistrationConfirmationComponent,
		ResendCodeComponent,
		ForgotPasswordStep1Component,
		ForgotPassword2Component,
		RegisterComponent,
		HomeLandingComponent,
		HomeComponent,
		EqCallHomeComponent,
		CriticallComponent,
		AppComponent,
		ChatMessageComponent,
		RtcConnectionComponent,
		MediaSelectorComponent,
		ContactComponent,
		MastercontrolComponent,
		AnonentryComponent,
		KeylinksComponent,
		DialerComponent,
		ChatWindowComponent,
		AccountComponent,
		KeyComponent,
		GlobalchatComponent,
		GlobalchatMessageComponent,
		NotificationComponent,
		CreditComponent,
		BillingComponent,
		ProfileComponent,
		TelInputDirective,
		UpgradesComponent,
		CompareDirective,
		VideocanvasComponent,
		MessengerListComponent,
		MixerComponent,
		ChannelElementComponent,
		MainChannelComponent,
		ChannelControllerComponent,
		PopupControllerComponent,
		MessengerListElementComponent,
		DevicePanelComponent,
		LanguageComponent,
		RingerComponent,
		AudiotestComponent,
		GroupComponent,
		MessengerComponent,
		GroupMembersComponent,
		GroupMembersElementComponent,
		RtccontainerComponent,
		RtcstripeComponent,
		RtcgridComponent,
		RtcuniComponent,
		FixedaspectDirective,
		TimerPipe,
		InvoiceComponent,
		CalllogComponent,
		VideocropperctlDirective,
		ToastComponent,
		CountryregionComponent
	],
	entryComponents: [ToastComponent],
	imports: [
		FormsModule,
		BrowserModule,
		HammerModule,
		routing,
		BrowserAnimationsModule,
		HttpClientModule,
		ServiceWorkerModule.register('/sw-master.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
		NgxSpinnerModule,
		NgxEmojModule
	],
	providers: [
		CognitoUtil,
		AwsUtil,
		NetworkService,
		PushService,
		MessageService,
		ContactsService,
		CordovaService,
		// RtcService,
		LivekitService,
		KeyService,
		TwilioService,
		AudioService,
		SystemBusService,
		UserRegistrationService,
		UserLoginService,
		EqcallapiService,
		{
			provide: APP_INITIALIZER,
			useFactory: load,
			deps: [
				HttpClient,
				ConfigService
			],
			multi: true
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: HammerConfig
		}],
	bootstrap: [AppComponent]
})
export class AppModule {
}
