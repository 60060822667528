<!-- In Call Presentation View Overlay -->
<div *ngIf="mainRtcConnection !== null">
  <!-- In Call Presentation/Screen Sharing Area -->
  <div
    class="presentation-area"
    [ngStyle]="{
      height: rtcButtons ? 'calc(100% - 198px)' : 'calc(100% - 0px)'
    }"
    style="
      position: absolute;
      width: 100%;
      border: 0px solid #fff;
      top: 0px;
      left: 0px;
      background: rgba(76, 119, 154, 0.74);
    "
  >
    <app-rtc-connection
      [connection]="mainRtcConnection"
      [showControls]="showControls"
      (fullscreenEvent)="fullscreenStatus($event)"
    ></app-rtc-connection>
  </div>
  <!-- In Call Video Film Strip -->
  <div
    class="film-strip-area"
    [ngStyle]="{
      bottom: rtcButtons ? '43px' : '0px',
      height: rtcButtons ? 'auto' : '0px'
    }"
    style="
      position: absolute;
      width: 100%;
      border: 1px solid #2c3e50;
      overflow-x: auto;
      background-color: #2c3e50;
    "
  >
    <div class="flex-container">
      <div
        class="flex-item"
        *ngFor="let rtcConnection of rtcConnections"
        [ngClass]="{ 'flex-item-4': rtcConnections.length == 4 }"
      >
        <div class="video-container">
          <div class="video">
            <div
              (click)="select(rtcConnection)"
              class="click-overlay"
              style="
                position: absolute;
                z-index: 1;
                height: calc(100% - 24px);
                width: 100%;
                bottom: 0px;
              "
            ></div>
            <app-rtc-connection
              [connection]="rtcConnection"
              [showControls]="showControls"
              [settingsOverlay]="settingsOverlay"
              (fullscreenEvent)="fullscreenStatus($event)"
            ></app-rtc-connection>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Change to Grid View Modal -->
<div
  class="modal fade"
  id="switchViewModal"
  data-bs-backdrop="false"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered eqmodal" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Switch to Grid View</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="No"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-footer d-flex justify-content-center">
        <button
          (click)="switchView()"
          type="button"
          class="btn btn-warning"
          data-bs-dismiss="modal"
          style="border-radius: 0rem"
        >
          Switch Views
        </button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
