import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { EqualizerValues } from '../../../../service/streamhandler.service';
import { LivekitService } from '../../../../service/livekit.service';
import { ResizeObserver } from 'resize-observer';
import { RtcConnection, RtcListener } from '../../../../service/rtc.service';
import { TranslationService } from '../../../../service/translation.service';

const screenfull = require('screenfull');
declare var Slider: any;

export class Stats {
  incomingBitRate = 0;
  outgoingBitRate = 0;
  roundTripTime = 0;
  concealedSamples = 0;
  framesDropped = 0;
  jitter = 0;
  audioPacketsLost = 0;
  videoPacketsLost = 0;
  pliCount = 0;
  timestamp = 0;

  public calc(stats: Stats): Stats {
    let stat = new Stats();
    stat.audioPacketsLost = this.audioPacketsLost - stats.audioPacketsLost;
    stat.concealedSamples = this.concealedSamples - stats.concealedSamples;
    stat.framesDropped = this.framesDropped - stats.framesDropped; // not a total....
    stat.jitter = this.jitter; // not a total
    stat.pliCount = this.pliCount - stats.pliCount;
    stat.timestamp = this.timestamp - stats.timestamp;
    stat.videoPacketsLost = this.videoPacketsLost - stats.videoPacketsLost;
    stat.incomingBitRate = this.incomingBitRate;
    stat.outgoingBitRate = this.outgoingBitRate;
    stat.roundTripTime = this.roundTripTime;
    return stat;
  }
  public toString(): string {
    return (
      'Stats: Audio: ConcealedSamples: ' +
      this.concealedSamples +
      ' Jtter: ' +
      this.jitter +
      ' packetsLost: ' +
      this.audioPacketsLost +
      '\n' +
      'Stats: Video: FramesDropped: ' +
      this.framesDropped +
      ' PliCount: ' +
      this.pliCount +
      ' packetsLost: ' +
      this.videoPacketsLost +
      '\n' +
      'Stats: Network: RTT: ' +
      this.roundTripTime +
      ' bandwidth Out: ' +
      this.outgoingBitRate / 1024 +
      ']\n' +
      'Stats: Rateing: ' +
      this.getRating() +
      '\n'
    );
  }

  public getRating(): number {
    return (
      this.concealedSamples +
      this.framesDropped +
      this.jitter * 10 +
      this.audioPacketsLost +
      this.videoPacketsLost +
      this.pliCount
    );
  }
}

@Component({
  selector: 'app-rtc-connection',
  templateUrl: './rtc-connection.component.html',
  styleUrls: ['../../../../../assets/css/custom.css']
})
export class RtcConnectionComponent
  implements OnInit, AfterViewInit, OnDestroy, RtcListener
{
  public static count = 0;

  @Output() fullscreenEvent = new EventEmitter();
  @Input() showControls: boolean;
  @Input() settingsOverlay: boolean;
  @Input() stripView: boolean;

  public rtcMessages: Array<string> = new Array();
  public videoOutput = '';
  public eqPresenceSlider = '';
  public noiseGateSlider = '';
  public volumeSlider = '';
  public visualizer = '';
  public videoPlaying = true;
  public showVideo = false;
  private videoShown = false;
  public videoOutputElement: HTMLVideoElement;
  private usElement: HTMLElement;
  private outVideoParentElement: HTMLElement;
  private ourvideoElement: HTMLElement;
  public showvisualizer = false;
  public showEQcontrols = false;
  public showEQBars = false;
  public showchat = false;
  public muteAudio = false;
  public muteVideo = false;
  public onHold = false;
  public showButtons = false;
  public fullscreen = false;
  public state = 'connecting';
  public rate = 0;
  private prefullscreenElement: HTMLElement;
  public id = '';
  public fid: string;
  public soundLevel = 0;
  public height = 0;
  rtcFullscreen = false;
  public pinned = false;
  public talking = false;
  public remoteAudioMuted = false;

  private runTimerUpdater: NodeJS.Timeout;
  private runTimerStart: number;
  public runTimer = 0;

  public colour: string;
  lastStat: any;
  statTimer: NodeJS.Timer;
  private BtnsClostTimer: NodeJS.Timer;
  _connection: RtcConnection;
  public rtcConnections: RtcConnection[] = [];
  resizeObserver: ResizeObserver;
  nickName = '';

  @Input()
  set connection(connection: RtcConnection) {
    this._connection = connection;
    if (connection) this.nickName = connection.getRemoteNickName();
  }

  get connection(): RtcConnection {
    return this._connection;
  }

  private fsfunction = () => {
    console.log('FS change');
    if (!this.fullscreen) {
      const el = <HTMLElement>screenfull.element;
      if (!el) {
        // no element? then just left full screen
        this.otherWentFullScreen(false);
      } else {
        const parent = el.parentElement;
        if (parent && parent.nodeName === 'APP-RTC-CONNECTION') {
          this.otherWentFullScreen(true);
        } else {
          // someone full screen but it was not one of us
          this.otherWentFullScreen(false);
        }
      }
    } else {
      if (!screenfull.isFullscreen) {
        this.toggleFullScreen();
      }
    }
  };

  constructor(
    private rtcSvc: LivekitService,
    public tr: TranslationService,
    private elRef: ElementRef
  ) {
    this.rtcConnections = rtcSvc.rtcConnections;
    RtcConnectionComponent.count++;
    console.log('create ' + RtcConnectionComponent.count);
  }

  ngOnInit() {
    try {
      let d = Date.now().toString(16);
      this.id = this.connection.getDestinationID() + d;
      this.fid = 'X' + this.id.replace(/:/gi, '-'); // the X is to avoid ids that start with a number keycodes...
      this.videoOutput = 'videoOutput-' + this.id;
      this.eqPresenceSlider = 'eqPresenceSlider-' + this.fid;
      this.noiseGateSlider = 'noiseGateSlider-' + this.fid;
      this.volumeSlider = 'volumeSlider-' + this.fid;
      this.visualizer = 'visualizer-' + this.id;
    } catch (error) {
      console.error(error);
    }
  }

  ngAfterViewInit() {
    this.videoOutputElement = <HTMLVideoElement>(
      document.getElementById(this.videoOutput)
    );

    this.usElement = <HTMLElement>document.getElementById(this.fid);
    this.ourvideoElement = <HTMLElement>document.getElementById('videoCanvas');
    if (!this.ourvideoElement) {
      this.ourvideoElement = <HTMLElement>document.getElementById('videoInput');
    }
    this.outVideoParentElement = this.ourvideoElement.parentElement;
    if (this.showvisualizer) {
      this.initEQcontrols();
    }
    this.init();
  }

  private enableRunTimer(start: boolean) {
    if (this.runTimerUpdater) {
      clearInterval(this.runTimerUpdater);
    }
    if (start) {
      this.runTimerStart = Date.now();
      this.runTimerUpdater = setInterval(() => {
        this.runTimer = Date.now() - this.runTimerStart;
      }, 1000);
    }
  }

  applysliderstyles(slider: any) {
    slider.trackLow.style.background = 'blue';
  }

  // called when mouse enters or leaves
  public showBtns(show: boolean) {
    this.showButtons = show;
    this.startBtnsClostTimer(show);
  }

  // called by click on video element
  public showBtnsToggle() {
    this.showButtons = !this.showButtons;
  }

  private startBtnsClostTimer(start: boolean) {
    if (!start) {
      if (this.BtnsClostTimer) {
        clearTimeout(this.BtnsClostTimer);
      }
      this.BtnsClostTimer = undefined;
    } else {
      this.BtnsClostTimer = setTimeout(() => {
        this.showBtns(false);
      }, 8000);
    }
  }

  public toggleMute() {
    this.muteAudio = !this.muteAudio;
    this.connection.muteRemoteAudio(this.muteAudio);
  }

  public toggleVideoMute() {
    this.muteVideo = !this.muteVideo;
    this.showVideo = !this.showVideo;
    this.connection.muteRemoteVideo(this.muteVideo);
  }

  public toggleOnHold() {
    this.onHold = !this.onHold;
    this.connection.hold(this.onHold);
  }

  public toggleFullScreen() {
    this.rtcSvc.setMasterHold(true);
    setTimeout(() => {
      this.rtcSvc.setMasterHold(false);
    }, 500);

    this.fullscreen = !this.fullscreen;
    this.rtcFullscreen = !this.rtcFullscreen;
    this.fullscreenEvent.emit(this.rtcFullscreen);
    if (screenfull.enabled) {
      if (this.fullscreen) {
        // enter full screen
        if (screenfull.isFullscreen) {
          // already full screen, save then exit
          this.prefullscreenElement = screenfull.element;
          //  console.log('RtcConnection: fullscreen: Was already fullscreen element=', this.prefullscreenElement);
        } else {
          this.prefullscreenElement = undefined;
        }
        // console.log('RtcConnection: fullscreen: we are going full screen');
        this.usElement.insertAdjacentElement(
          'afterbegin',
          this.ourvideoElement
        );
        screenfull.request(this.usElement);
        // console.log(this.usElement);
      } else {
        // exit full screen
        this.outVideoParentElement.insertAdjacentElement(
          'afterbegin',
          this.ourvideoElement
        );
        screenfull.exit();
        if (this.prefullscreenElement) {
          //   console.log('RtcConnection: fullscreen: resuming previous fullscreen');
          screenfull.request(this.prefullscreenElement);
          this.prefullscreenElement = undefined;
        }
      }
    } else {
      console.warn('No Fullscreen support');
    }
  }

  private otherWentFullScreen(enterfs: boolean) {
    console.log('Other went full screen');
    if (!this.connection) {
      console.warn('Connection is null');
      return;
    }
    if (enterfs) {
      if (!this.muteVideo) {
        console.log('Other went fs killing video');
        this.connection.muteRemoteVideo(true);
      }
      if (this.showvisualizer) {
        this.connection.startRemoteVisualizer(false);
      }
    } else {
      if (!this.muteVideo) {
        this.connection.muteRemoteVideo(false);
      }
      if (this.showvisualizer) {
        this.connection.startRemoteVisualizer(true);
      }
    }
  }

  public toggleShowEQcontrols() {
    this.showEQcontrols = !this.showEQcontrols;
    if (this.showEQcontrols) {
      setTimeout(() => {
        this.initEQcontrols();
      }, 250);
    }
  }

  public toggleEQBars() {
    this.showEQBars = !this.showEQBars;
    if (this.showEQBars) {
      this.showvisualizer = false;
      setTimeout(() => {
        const canvas = <HTMLCanvasElement>(
          document.getElementById(this.visualizer)
        );
        if (canvas) {
          this.connection.getVisualizer().setCanvas(canvas);
          this.connection.startRemoteVisualizer(true);
        } else {
          console.error('toggleEQBars: Canvas not found');
        }
      }, 250);
    }
  }

  public toggleVisualizer() {
    console.log('ToggleVisualizer called');
    this.showEQcontrols = !this.showEQcontrols;
    if (this.showEQcontrols) {
      setTimeout(() => {
        this.initEQcontrols();
      }, 250);
    }
    this.showvisualizer = !this.showvisualizer;
    if (this.showvisualizer) {
      this.showEQBars = false;
      setTimeout(() => {
        const canvas = <HTMLCanvasElement>(
          document.getElementById(this.visualizer)
        );
        if (canvas) {
          this.connection.getVisualizer().setCanvas(canvas);
          this.connection.startRemoteVisualizer(true);
        } else {
          console.error('toggleVisualizer: Canvas not found');
        }
      }, 250);
    } else {
      this.connection.startRemoteVisualizer(false);
    }
  }

  public toggleChat() {
    this.showchat = !this.showchat;
  }

  private initEQcontrols() {
    const that = this;
    const eqPresenceSlider = new Slider('#' + that.eqPresenceSlider, {});
    const noiseGateSlider = new Slider('#' + that.noiseGateSlider, {});
    const volumeSlider = new Slider('#' + that.volumeSlider, {});

    const eqValues: EqualizerValues = this.connection
      .getAnalyser()
      .getRemoteEqValues();

    noiseGateSlider.setValue(eqValues.noiseGate);
    volumeSlider.setValue(eqValues.volume);

    eqPresenceSlider.on('change', function (sliderValue: any) {
      that.connection.setRemoteEq('master', sliderValue.newValue);
    });
    eqPresenceSlider.on('slide', function (sliderValue: number) {
      that.connection.setRemoteEq('master', sliderValue);
    });
    this.applysliderstyles(eqPresenceSlider);

    noiseGateSlider.on('change', function (sliderValue: any) {
      that.connection.setRemoteNoiseGate(sliderValue.newValue);
    });
    noiseGateSlider.on('slide', function (sliderValue: number) {
      that.connection.setRemoteNoiseGate(sliderValue);
    });
    this.applysliderstyles(noiseGateSlider);

    volumeSlider.on('change', function (sliderValue: any) {
      that.connection.setRemoteVolume(sliderValue.newValue);
    });
    volumeSlider.on('slide', function (sliderValue: number) {
      that.connection.setRemoteVolume(sliderValue);
    });
    this.applysliderstyles(volumeSlider);
  }

  private async init() {
    if (screenfull.enabled) {
      screenfull.on('change', this.fsfunction);
    }

    this.connection.addListener(this);
    if (this.connection.getIsBeingCalled()) {
      this.connection.acceptConnection();
    }
    this.statTimer = setInterval(() => this.updateStats(), 3000);
    this.videoOutputElement.addEventListener(
      'resize',
      (_ev) => {
        let w = this.videoOutputElement.videoWidth;
        let h = this.videoOutputElement.videoHeight;
        let videoPortrate = h > w;

        let windowPortrate = window.innerHeight > window.innerWidth;
        if (videoPortrate !== windowPortrate) {
          console.log('changed', w, h);
        }
      },
      false
    );

    this.resizeObserver = new ResizeObserver((entries: any) => {
      console.log('Resized');
      this.height = entries[0].contentRect.height;
    });
    this.resizeObserver.observe(this.usElement);

    let src = this.connection.getOutputStream();
    if (src.getVideoTracks().length > 0) {
      this.showVideo = true;
    }
    this.videoOutputElement.srcObject = src;
    this.state = this.connection.getState();
    if (this.state === 'connected' || this.state === 'completed') {
      this.videoOutputElement.poster = '';
      this.state = '';
    }
    this.videoOutputElement.volume = this.rtcSvc.masterVolume;
  }

  ngOnDestroy() {
    this.resizeObserver.unobserve(this.usElement);
    this.enableRunTimer(false);
    if (this.fullscreen) {
      this.toggleFullScreen();
    }
    if (screenfull.enabled) {
      screenfull.off('change', this.fsfunction);
    }
    this.connection.removeListener(this);

    this.rtcConnections = null;
    clearTimeout(this.statTimer);
    clearTimeout(this.runTimerUpdater);
    RtcConnectionComponent.count--;
    console.log('destroy' + RtcConnectionComponent.count);
    this.usElement = null;
    this.connection = null;
  }

  private updateStats() {
    this.connection.getStats().then((stats) => {
      if (stats) {
        this.parseStats(stats);
      }
    });
  }

  private parseStats(stats: any) {
    const sts = new Stats();
    stats.forEach((stat: any) => {
      if (stat.type === 'track') {
        //   console.log(stat);
        if (stat.remoteSource === true) {
          if (stat.kind === 'audio') {
            sts.concealedSamples = stat.concealedSamples;
          } else {
            sts.framesDropped = stat.framesDropped;
          }
        }
      } else if (stat.type === 'inbound-rtp') {
        if (stat.mediaType === 'audio' || stat.kind === 'audio') {
          sts.jitter = stat.jitter;
          sts.audioPacketsLost = stat.packetsLost;
        } else {
          sts.pliCount = stat.pliCount;
          sts.videoPacketsLost = stat.packetsLost;
        }
      } else if (stat.type === 'candidate-pair') {
        if (stat.state === 'succeeded') {
          sts.roundTripTime = stat.currentRoundTripTime;
          sts.outgoingBitRate = stat.availableOutgoingBitrate;
          sts.incomingBitRate = stat.availableIncomingBitrate;
        }
      } else if (stat.type === 'peer-connection') {
        sts.timestamp = stat.timestamp;
      }
    });
    if (this.lastStat) {
      let s = sts.calc(this.lastStat);
      let r = s.getRating();
      this.rate = r > 30000 ? 0 : Math.round(30000 - s.getRating());
      this.setRateColour(30000 - this.rate);
    }
    this.lastStat = sts;
    // console.log(sts.toString());
  }

  private setRateColour(rate: number) {
    if (rate === 0) {
      this.colour = '#2c3e50';
    } else {
      let red,
        green,
        blue = 0;
      if (rate < 15000) {
        green = 255;
        red = ((255 / 15000) * rate) | 0;
      } else {
        let d = rate - 15000;
        red = 255;
        green = (255 - (255 / 15000) * d) | 0;
      }
      this.colour = 'rgb(' + red + ',' + green + ',' + blue + ')';
    }
  }

  rtcStateChange(state: string): void {
    console.log(state);
    if (!state.startsWith('track:')) {
      this.state = state;
    }

    if (state === 'connected' && !this.videoShown) {
      setTimeout(() => {
        this.toggleEQBars();
      }, 250);
    }

    if (state === 'connected' || state === 'completed') {
      this.videoOutputElement.poster = '';
      this.state = '';
      this.enableRunTimer(true);
    } else if (state.startsWith('track:video')) {
      if (state.endsWith('added')) {
        this.videoPlaying = true;
        if (!this.videoShown && this.rtcSvc.streamHandler.masterVideoMute) {
          this.startVideoRequest();
        }
        if (!this.muteVideo) {
          this.showVideo = true;
        }
        this.videoShown = true;

        if (this.showEQBars) {
          this.toggleEQBars();
        }
        try {
          // bounce stream for safari....
          let src = this.videoOutputElement.srcObject;
          this.videoOutputElement.srcObject = null;
          this.videoOutputElement.srcObject = src;
        } catch (err) {
          console.warn(err);
        }
        this.videoOutputElement.play().catch((err) => {
          this.rtcSvc.systemBus.emit(
            this.videoOutputElement,
            'rtc/videoplayRequest'
          );
        });
      } else {
        this.videoPlaying = false;
        if (!this.showEQBars) {
          this.toggleEQBars();
        }
      }
    } else if (state.startsWith('track:audio')) {
      this.remoteAudioMuted = state.endsWith('ended');
    }
  }

  public async startVideoRequest() {
    console.error('Implement start video logic');
    // ToDO fix
    // BootstrapDialog.confirm({
    //     title: this.connection.remoteNickName + ' started video',
    //     message: 'Do you want to start your video as well?',
    //     btnCancelLabel: 'No',
    //     btnOKLabel: 'Yes',
    //     btnOKClass: 'btn-primary',
    //     callback: (result: any) => {
    //         if (result) {
    //             this.rtcSvc.setMasterVideoMute(false);
    //         }
    //     }
    // });
    // this.rtcSvc.setMasterVideoMute(false);
  }

  public sendMessage(message: string) {
    this.connection.sendChatMessage(message);
    this.rtcMessages.push(message);
  }

  rtcTalking(talking: boolean): void {
    //  console.error('RTC-Connection: rtcTalking ' + this.talking);
    this.talking = talking;
  }

  rtcMessage(message: string): void {
    this.rtcMessages.push(message);
    this.showchat = true;
  }

  rtcConnectionRequest(address: string) {
    console.log('Got rtc connection request');
  }

  rtcClosed() {
    clearTimeout(this.statTimer);
    console.log('Rtc connection closed');
  }

  rtcSoundLevelChange(level: number) {
    this.soundLevel = level;
    //  console.log('RTC-Connection, sound level ', level);
  }

  rtcDeviceIDChange(deviceID: string): void {
    const element: any = this.videoOutputElement;
    if (element.setSinkId) {
      element
        .setSinkId('default')
        .then(() => {
          element.setSinkId(deviceID);
        })
        .catch((error: any) => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage =
              'You need to use HTTPS for selecting audio output ' +
              'device: ' +
              error;
          }
          console.error(errorMessage);
        });
    }
  }

  public stopRtc() {
    this.connection.close();
  }

  public pin() {
    this.pinned = !this.pinned;
    console.log('pinned ' + this.pinned);
    if (this.pinned) {
      (<Element>this.elRef.nativeElement).setAttribute('pinned', '');
    } else {
      (<Element>this.elRef.nativeElement).removeAttribute('pinned');
    }
  }
}
