/**
 * @author Michael Stapleton
 *
 * This is the entry into the routing. This is the first component that's called
 * when the app loads.
 *
 */
import { Component, OnInit } from '@angular/core';
import { UserLoginService, CognitoUtil, LoggedInCallback } from './service/cognito.service';
import { NetworkService } from './service/network.service';
import { KeyService, Key } from './service/key.service';
import { TwilioService } from './service/twilio.service';
import { Router } from '@angular/router';

// import 'prevent-pull-refresh';
import { EqcallapiService } from './service/eqcallapi.service';

@Component({
    selector: 'app-root',
    templateUrl: 'template/app.html',
    providers: [NetworkService],
})
export class AppComponent implements OnInit, LoggedInCallback {
    private static ran = false;
    private key: string;
    private keyCode: string;
    private anonNickName: string;
    private phoneNumber: string;
    private remoteToken: string;
    private autoConnect = false;
    private domainName: string;
    constructor(public router: Router, public userService: UserLoginService,
        public cognito: CognitoUtil, private keySvc: KeyService, private twilioSvc: TwilioService, private apiSvc: EqcallapiService) {
        let hostname = location.hostname;
        if (hostname === '127.0.0.1') {
        }

        if (hostname.endsWith('calling.services')) {
            this.domainName = hostname;
        }
        this.key = this.getURLParameter('key');
        this.keyCode = this.getURLParameter('key');
        this.anonNickName = this.getURLParameter('userName');
        this.phoneNumber = this.getURLParameter('phoneNumber');
        this.remoteToken = this.getURLParameter('token');
        this.autoConnect = this.getURLParameter('autoConnect') === 'true' ? true : false;
        if (location.pathname === '/cognito') {
            AppComponent.ran = true;
            this.userService.processResp();
        }
    }

    private async processKey(key: Key) {

        if (key) {
            this.keyCode = key.keyCode

            if (!this.anonNickName) {
                this.anonNickName = key.userName
            }
            this.autoConnect = key.autoConnect || this.autoConnect;
        }

        this.userService.anonNickName = this.anonNickName;
        this.twilioSvc.phoneNumber = this.phoneNumber;
        this.twilioSvc.remoteToken = this.remoteToken;
        this.userService.autoConnect = this.autoConnect;
        this.userService.setKeyCode(this.keyCode);
        console.log('autoconnect=' + this.userService.autoConnect);
        console.log('anonNickName=' + this.userService.anonNickName);
        console.log('phoneNume=' + this.twilioSvc.phoneNumber);

        if (this.twilioSvc.phoneNumber) {
            this.twilioSvc.autoConnect = this.userService.autoConnect;
            this.userService.autoConnectVideo = false;
            setTimeout(() => {
                this.twilioSvc.call(this.twilioSvc.phoneNumber);
            }, 1500);
        }
        this.userService.checkSavedAuthenticated(this);
    }

    getURLParameter(name: string) {
        if (location.search.length > 0) {
            return decodeURIComponent((new RegExp('[?|&|:]' + name + '=' + '([^&;:]+?)(&|#|;|$|:)').exec(location.search) ||
                [null, ''])[1].replace(/\+/g, '%20')) || null;
        } else {
            return null;
        }
    }

    ngOnInit() {
        //  console.log('AppComponent: ngOnInit: Checking if the user is already authenticated');
        if (this.domainName && !this.key) {
            console.warn('Domain Name = ' + this.domainName);
            // this.apiSvc.getKeyByDomainName(this.domainName).
            //     then((k: Key) => { console.error('key=', k); this.key = k.keyCode; this.processKey(k) }).
            //     catch((err) => { console.error(err); this.userService.checkSavedAuthenticated(this); });
            this.apiSvc.getKeyCodeByDomainName(this.domainName).
                then((k: string) => { console.warn('keyCode=', k); return k }).
                then((keyCode: string) => { this.key = keyCode; return this.keySvc.getKey(keyCode) }).
                then((k: Key) => { console.warn('key=', k); this.processKey(k) }).
                catch((err) => { console.error('DomIN Name key error', err); this.userService.checkSavedAuthenticated(this); });
        } else {
            if (this.key) {
                this.keySvc.getKey(this.key).then((k: Key) => { console.warn('key=', k); this.processKey(k) }).
                    catch((err) => { console.error(err); this.userService.checkSavedAuthenticated(this); });
                console.log('AppComponent: new: Key=' + this.key);
            } else {
                this.userService.checkSavedAuthenticated(this);
            }
        }
    }

    async isLoggedIn(_message: string, isLoggedIn: boolean) {
        if (AppComponent.ran) {
            return;
        }
        AppComponent.ran = true;

        if (location.pathname === '/home/register') {
            this.router.navigate(['/home/register']);
        } else {
            // console.log('AppComponent: isLoggedIn: the user is authenticated: ' + isLoggedIn);
            // console.log('AppComponent: isLoggedIn: message=' + message);
            if (this.key) {
                if (isLoggedIn === true) {
                    console.error('logout before using key');
                    // await this.userService.authenticated(null)
                    try {
                        await this.userService.logout(false);
                    } catch (error) {
                        console.error(error);
                    }
                    setTimeout(() => {

                        location.replace(window.location.origin + '/?key=' + this.key)
                    }, 5000);
                    return;
                }

                console.log('AppComponent: isLoggedIn: ckecking key' + this.key);
                this.keySvc.getKey(this.key).then((k: Key) => {
                    if (k != null) {
                        console.log('AppComponent: isLogin: key is valid');
                        // console.error(k);
                        this.router.navigate(['/home/anonentry'], { replaceUrl: true });
                    } else {
                        console.error('AppComponent: isLogin: key is not valid');
                        alert('Error retreiving key or invalid key');
                        this.router.navigate(['/home'], { replaceUrl: true });
                    }
                });
            } else if (isLoggedIn === true) {
                // console.log('AppComponent: isLoggedIn: redirecting to secure home');
                await this.userService.authenticated(null)
                this.router.navigate(['/eqCallHome'], { replaceUrl: true });
            } else {
                console.log('AppComponent: isLoggedIn: redirecting to anonentry');
                this.router.navigate(['/home'], { replaceUrl: true });
            }
        }
    }
}
